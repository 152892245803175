import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { useTranslation } from 'react-i18next';
import CsvDownload from 'react-json-to-csv';
import LoadingButton from '../../../commonComponents/LoadingButton';
import useEventEnter from '../../../hooks/useEventEnter';
import CustomSelectReact from '../../CustomSelectReact';
import SearchInput from '../../SearchInput';
import {
  SELECT_CREATED_BY_ROLES,
  SELECT_TRANSACTION_TYPES,
  SELECT_TRANSACTION_STATUSES,
  TRANSACTION_TYPES,
} from './constant';

const FilterComponent = ({
  dateRange,
  filters = {},
  handleDateFilter,
  handlerFilters,
  getTransactions,
  isLoading,
  selected,
}) => {
  const { t } = useTranslation();

  useEventEnter(getTransactions);

  const FilterButtons = () => {
    return (
      <div className="w-full flex items-center mb-4 mr-8">
        <LoadingButton
          onClick={() => getTransactions()}
          label={t('Apply Filter')}
          loading={isLoading}
        />

        {selected.length > 0 && (
          <CsvDownload
            className="h-[35px] relative justify-center items-center flex focus:outline-none text-white bg-blue-600 hover:bg-blue-700 focus:ring-1 focus:ring-blue-300 font-medium rounded-lg text-xl px-12 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-600 dark:focus:ring-blue-700 opacity-100"
            filename="transactions.csv"
            data={selected}
          >
            {t('loading_data')}
          </CsvDownload>
        )}
      </div>
    );
  };

  return (
    <div className="search-order mb-10 rtl">
      <div className="flex w-full gap-x-4 mr-8 mb-8">
        <SearchInput
          className="input-select-input-wrapper !border-0 h-full max-w-max w-full min-w-[200px] gap-x-4"
          disabled={isLoading}
          placeholder={t('order_number')}
          handleSearch={(value) => handlerFilters(value, 'order_number')}
          makeRerender={filters}
          value={filters?.order_number}
        />

        <CustomSelectReact
          isClearable
          isDisabled={isLoading}
          placeholder={t('created_by')}
          className="input-select-input-wrapper !border-0 h-full max-w-max w-full min-w-[200px] gap-x-4"
          options={SELECT_CREATED_BY_ROLES}
          value={filters.created_by_role}
          onChange={(value) => handlerFilters(value, 'created_by_role')}
        />

        <CustomSelectReact
          isClearable
          isDisabled={isLoading}
          placeholder={t('transaction_type')}
          className="input-select-input-wrapper !border-0 h-full max-w-max w-full min-w-[200px] gap-x-4"
          options={SELECT_TRANSACTION_TYPES}
          value={filters.type}
          onChange={(value) => handlerFilters(value, 'type')}
        />

        {filters?.type?.value === TRANSACTION_TYPES.TOP_UP && (
          <CustomSelectReact
            isClearable
            isDisabled={isLoading}
            placeholder={t('transaction_status')}
            className="input-select-input-wrapper !border-0 h-full max-w-max w-full min-w-[200px] gap-x-4"
            options={SELECT_TRANSACTION_STATUSES}
            value={filters.status}
            onChange={(value) => handlerFilters(value, 'status')}
          />
        )}

        <div className="gap-x-4 min-w-[200px] ltr">
          <DateTimeRangePicker
            value={dateRange}
            onChange={handleDateFilter}
            disableClock
            format={'y-MM-dd'}
            disabled={isLoading}
          />
        </div>
      </div>

      <FilterButtons />
    </div>
  );
};

export default FilterComponent;
