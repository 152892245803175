import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { buildTransactionKey, guid, TransactionKey } from '../../helper/order/buildTransactionKey';
import { DataContext } from '../../context/DataContext';
import { toast } from 'react-toastify';
import { config } from '../../config/Config';
import LoadingButton from '../../commonComponents/LoadingButton/LoadingButton';
import useEventEnter from '../../hooks/useEventEnter';

toast.configure();

const DriversList = (props) => {
  const { order_number, apiVersion, loadingConfig } = props;
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingAssign, setLoadingAssign] = useState(false);
  const [filterText, setFilterText] = useState(false);
  const { profileAdmin } = useContext(DataContext);
  const number_request = useRef(guid());
  const [isRunningActionOrder, setIsRunningActionOrder] = useState(false);

  const getDrivers = async (filters = {}) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        config.API_URL.LOCATION.DRIVER_ASSIGN,
        {
          params: { ...filters },
        },
        {
          headers: config.headers,
        }
      );
      const { data = {} } = response;
      setData(data.drivers || []);
    } catch (e) {
      console.log('Axios error: ', e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetDataBySearch = () => {
    const filters = {
      filters: {
        keyword: filterText,
        token: true,
        name: true,
      },
      sort: -1,
      limit: 10,
      page: 1,
    };
    getDrivers(filters);
  };

  useEventEnter(handleGetDataBySearch);

  useEffect(() => {
    getDrivers();
  }, []);

  const assign = async (order_number, driver_id, name, token) => {
    if (isLoading || loadingConfig || loadingAssign) {
      return false;
    }
    if (isRunningActionOrder) return;
    setIsRunningActionOrder(true);
    setLoadingAssign(true);
    try {
      let apiConfig = null;
      if (apiVersion === 'v1') {
        apiConfig = config;
      } else {
        apiConfig = config;
      }
      await axios
        .patch(
          apiConfig.API_URL.ORDERS.ASSIGN_ORDER + order_number,
          { driver_id },
          {
            headers: {
              ...apiConfig.headers,
              ...buildTransactionKey(
                order_number,
                profileAdmin?._id,
                TransactionKey.execute,
                number_request.current
              ),
            },
          }
        )
        .then(async (response) => {
          if (response.data.status === 409) {
            toast.error(t('request_assigned_to_driver'), { position: toast.POSITION.BOTTOM_LEFT });
            props.closeModal();
          } else {
            if (props.refreshList) {
              props.refreshList();
            }
            toast(t('request_has_been_successfully_submitted'), {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            props.closeModal();
          }
        })
        .finally(() => {
          setIsRunningActionOrder(false);
          number_request.current = guid();
        });
    } catch (err) {
      if (err?.response) {
        Object.entries(err?.response?.data)?.map(([key, error]) => {
          if (error?.message) {
            toast.error(error?.message, { position: toast.POSITION.BOTTOM_LEFT });
          }
        });
      }
    } finally {
      setLoadingAssign(false);
    }
  };

  return (
    <div className="DriversListContainer">
      {isLoading ? '' : ''}

      <div className="driversList rtl">
        <div className=" mb-8 flex flex-row h-14">
          <input
            type="text"
            name="search"
            className="w-5/6 bg-[#961d39]  pr-8 text-[#fff] mx-5 "
            autoComplete="off"
            placeholder={t('search_for_a_driver')}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <LoadingButton onClick={handleGetDataBySearch} label={'Search'} loading={isLoading} />
        </div>
        <ul>
          {data.map((res, index) => {
            return (
              <li key={index}>
                {res.driver_name}
                <div
                  className="btnLeft flex space-x-2"
                  onClick={() => assign(order_number, res.driver_id, res.driver_name, res.token)}
                >
                  {loadingAssign && (
                    <svg
                      class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  <span className="px-3"> {t('driver_list')}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default DriversList;
