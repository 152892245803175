import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FORMAT_DATE, parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { useDate } from '../../hooks/useDate';

export default function useColumns({ logs }) {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();

  const columns = useMemo(
    () => [
      {
        name: t('id'),
        selector: '_id',
        sortable: true,
        minWidth: '200px',
      },
      {
        name: t('type'),
        selector: 'type',
        sortable: true,
        minWidth: '260px',
      },
      {
        name: t('url'),
        selector: 'url',
        sortable: true,
        minWidth: '500px',
      },
      {
        name: t('method'),
        selector: 'method',
        sortable: true,
      },
      {
        name: t('date'),
        selector: (row) =>
          parseDateWithGmt({
            date: row.created_at,
            timezone: currentTimezone?.timezone,
          }),
        sortable: true,
        wrap: true,
        minWidth: '120px',
      },
      {
        name: t('date_time'),
        selector: (row) =>
          parseDateWithGmt({
            date: row.created_at,
            timezone: currentTimezone?.timezone,
            format: FORMAT_DATE.hms,
          }),

        sortable: true,
        wrap: true,
        minWidth: '140px',
      },
    ],
    [logs]
  );
  return { columns };
}
