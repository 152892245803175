import axios from 'axios';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { config } from '../../../config/Config';
import { DataContext } from '../../../context/DataContext';
import {
  buildTransactionKey,
  guid,
  TransactionKey,
} from '../../../helper/order/buildTransactionKey';

toast.configure();

export default function OrderActions(props) {
  const { data, reasonsList } = props;
  const [reason, setReason] = useState(false);
  const [reasonType, setReasonType] = useState('');
  const [reasonTxt, setReasonTxt] = useState('');
  let history = useHistory();
  const { t } = useTranslation();
  const { profileAdmin } = useContext(DataContext);
  const number_request = useRef(guid());
  const [isRunningActionOrder, setIsRunningActionOrder] = useState(false);

  const handleReasonTxt = (e) => {
    setReasonTxt(e.target.value);
  };
  const endTrip = async () => {
    const driver_id = data.driver_id;
    const order_number = data.order_number;
    if (isRunningActionOrder) return;
    setIsRunningActionOrder(true);
    await axios
      .patch(
        config.API_URL.ORDERS.EXECUTE_ORDER + order_number,
        { driver_id },
        {
          headers: {
            ...config.headers,
            ...buildTransactionKey(
              order_number,
              profileAdmin?._id,
              TransactionKey.execute,
              number_request.current
            ),
          },
        }
      )
      .then((response) => {
        history.go(0);
      })
      .catch(() => {
        setIsRunningActionOrder(false);
        number_request.current = guid();
      });
  };
  const cancelTrip = async () => {
    const order_number = data.order_number;

    if (isRunningActionOrder) return;
    setIsRunningActionOrder(true);
    const delivery_cost = data.delivery_cost;
    if (!reasonType) {
      toast.error(t('required_reason'), { position: toast.POSITION.BOTTOM_LEFT });
      return;
    }
    await axios
      .patch(
        config.API_URL.ORDERS.CANCEL_ORDER + order_number,
        { cancel_reason: reasonType, delivery_cost: delivery_cost, cancel_reason_txt: reasonTxt },
        // { headers: config.headers }
        {
          headers: {
            ...config.headers,
            ...buildTransactionKey(order_number, profileAdmin?._id, TransactionKey.cancel),
          },
        }
      )
      .then((response) => {
        history.go(0);
      })
      .catch(() => {
        setIsRunningActionOrder(false);
        number_request.current = guid();
      });
  };
  return (
    <div style={styles.container}>
      <Link to={{ pathname: `/orders/${data.order_number}`, data: data }}>
        <button style={styles.edit}>{t('view_invoice')} </button>
      </Link>
      {!data.isDelivered && (
        <button
          style={styles.endTrip}
          onClick={endTrip}
          disabled={isRunningActionOrder}
          className={`${isRunningActionOrder ? 'opacity-50' : 'opacity-100'}`}
        >
          {t('execute_order')}
        </button>
      )}

      {!data.isCanceled && (
        <button style={styles.cancelOrder} onClick={() => setReason(!reason)}>
          {t('reason_for_cancellation')}
        </button>
      )}
      {reason && (
        <span>
          <span style={styles.reason}>{t('reason_for_cancellation')}: </span>
          {reasonsList.map((reasonItem) => {
            return (
              <button
                key={reasonItem.id}
                style={{
                  ...styles.reasonBtn,
                  ...{
                    border: reasonType === reasonItem.id ? '2px solid #C32E52' : '2px solid #fff',
                  },
                }}
                onClick={() => setReasonType(reasonItem.id)}
              >
                {reasonItem.nameAr}
              </button>
            );
          })}

          <input
            type="text"
            placeholder={t('notes')}
            value={reasonTxt}
            onChange={handleReasonTxt}
            style={styles.reasonInput}
          />
          <button
            style={styles.confirmBtn}
            onClick={() => cancelTrip()}
            disabled={isRunningActionOrder}
            className={`${isRunningActionOrder ? 'opacity-50' : 'opacity-100'}`}
          >
            {t('confirm_the_operation')}
          </button>
        </span>
      )}
    </div>
  );
}

const styles = {
  container: {
    padding: 20,
    backgroundColor: '#FCF5F2',
  },
  activate: {
    backgroundColor: '#3BCE63',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#1C6F32',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
  },
  deactivate: {
    backgroundColor: '#342B40',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#f2f2f2',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
  },
  edit: {
    backgroundColor: '#E0EFF7',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#342B40',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
    border: '1px solid #E0EFF7',
  },
  reasonBtn: {
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 10,
    color: '#342B40',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
    marginTop: 2,
    marginBottom: 2,
  },
  confirmBtn: {
    backgroundColor: '#31C961',
    border: '1px solid #31C961',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 10,
    color: '#146530',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
  },
  cancelOrder: {
    backgroundColor: '#C32E52',
    border: '1px solid #C32E52',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#fff',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
  },
  endTrip: {
    backgroundColor: '#9AF7C0',
    border: '1px solid #9AF7C0',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#342B40',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
  },
  reason: {
    marginRight: 50,
    marginLeft: 10,
  },
  reasonInput: {
    border: 0,
    padding: 5,
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 10,
  },
};
