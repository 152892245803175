import { Chart, registerables } from 'chart.js';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
Chart.register(...registerables);
export const transformDataChart = (data = []) => {
  return data.reduce(
    (accumulator, currentValue) => {
      const checkLabels = currentValue?.day
        ? moment(currentValue?.end).tz('Asia/Riyadh').locale('en').format('YYYY-MM-DD')
        : `${moment(currentValue?.start)
            .tz('Asia/Riyadh')
            .locale('en')
            .format('YYYY-MM-DD')} - ${moment(currentValue?.end)
            .tz('Asia/Riyadh')
            .locale('en')
            .format('YYYY-MM-DD')}
          `;
      accumulator['time'].push(checkLabels);
      accumulator['values'].push(currentValue?.totalHours.toFixed(2));
      accumulator['title'].push(
        currentValue?.position
          ? currentValue?.position
          : currentValue?.day
          ? currentValue?.day
          : currentValue?.month
      );
      return accumulator;
    },
    { time: [], values: [], title: [] }
  );
};

export default function useBarChart({ elementId, data }) {
  useEffect(() => {
    if (isEmpty(data)) return;
    const dataChart = transformDataChart(data);

    var ctx = document.getElementById(elementId).getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dataChart.title,

        datasets: [
          {
            label: 'Hours',
            tension: 0.4,
            borderWidth: 0,
            borderRadius: 4,
            borderSkipped: false,
            backgroundColor: 'white',
            data: dataChart.values,
            maxBarThickness: 6,
            time: dataChart.time,
          },
        ],
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                const valueChartY = 'Hours: ' + tooltipItem?.parsed?.y;
                return valueChartY;
              },
              title: function (tooltipItem) {
                return tooltipItem[0].dataset?.time[tooltipItem[0].dataIndex];
              },
            },
          },
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 600,
              beginAtZero: true,
              padding: 15,
              font: {
                size: 11,
                family: 'Open Sans',
                style: 'normal',
                lineHeight: 2,
              },
              color: '#fff',
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 600,
              beginAtZero: true,
              padding: 6,
              font: {
                size: 11,
                family: 'Open Sans',
                style: 'normal',
                lineHeight: 2,
              },
              color: '#fff',
            },
          },
        },
      },
    });
    return () => {
      myChart.destroy();
    };
  }, [data, elementId]);
  return {};
}
