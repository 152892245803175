import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FORMAT_DATE, parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { useDate } from '../../hooks/useDate';
const renderFields = (text) => {
  if (!text) return '-';
  return text;
};
const useColumnsOtp = () => {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();

  const columns = useMemo(
    () => [
      {
        name: t('driver_name'),
        minWidth: '200px',
        cell: (row) => renderFields(row?.drivers?.name),
      },
      {
        name: t('otp_code'),
        minWidth: '200px',
        cell: (row) => renderFields(row?.passKey),
      },
      {
        name: t('phone'),
        minWidth: '200px',
        cell: (row) => renderFields(row?.phone),
      },
      {
        name: t('time_create'),
        selector: (row) =>
          parseDateWithGmt({ date: row.created_at, timezone: currentTimezone?.timezone }),
        sortable: true,
        width: '300px',
        wrap: true,
        style: {
          padding: 0,
        },
      },
      {
        name: t('expired'),
        selector: (row) =>
          parseDateWithGmt({
            date: row.created_at,
            timezone: currentTimezone?.timezone,
            format: FORMAT_DATE.hms,
          }),
        sortable: true,
        width: '300px',
        wrap: true,
        style: {
          padding: 0,
        },
      },
    ],
    []
  );
  return columns;
};
export default useColumnsOtp;
