import { isEmpty } from 'lodash';
import { daysInMonth } from '../../components/Dashboard/hooks/useLogicDashBoard';

export const generateLabelsChart = (selectedOption, dayInMonth, data) => {
  if (selectedOption === 'month') {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  }

  if (selectedOption === 'day') {
    let daysInMonthLabel = [];
    for (let i = 1; i <= dayInMonth; i++) {
      daysInMonthLabel.push(i);
    }
    return daysInMonthLabel;
  }
  if (selectedOption === 'year') {
    const labelByYear = getLabelByYear(data);

    return labelByYear;
  }
};
export const borderColorLineChart = ['#cb0c9f', '#3A416F'];
export const bgColorLineChart = [
  {
    stop0: 'rgba(203,12,159,0.2)',
    stop1: 'rgba(72,72,176,0.0)',
    stop2: 'rgba(203,12,159,0)',
  },
  {
    stop0: 'rgba(20,23,39,0.3)',
    stop1: 'rgba(72,72,176,0.0)',
    stop2: 'rgba(20,23,39,0)',
  },
];
export const parseDataLineChartByDay = (listData, date) => {
  const dayInMonth = daysInMonth(date.month, date.year);

  let dataParse = {};
  for (let i = 0; i <= dayInMonth; i++) {
    Object.entries(listData)?.map(([key, value]) => {
      if (isEmpty(dataParse[key])) {
        dataParse[key] = [];
      }
      if (value[i]) {
        dataParse[key][value[i].day] = value[i];
      }
      if (!dataParse[key][i]) {
        dataParse[key][i] = { total: 0, day: i, month: 11, _id: `${date.year}-${date.month}-${i}` };
      }
    });
  }
  return dataParse;
};

export const parseDataLineChartByMonth = (listData, date) => {
  let dataParse = {};
  for (let i = 0; i <= 12; i++) {
    Object.entries(listData)?.map(([key, value]) => {
      if (isEmpty(dataParse[key])) {
        dataParse[key] = [];
      }
      if (value[i]) {
        dataParse[key][value[i].month] = value[i];
      }
      if (!dataParse[key][i]) {
        dataParse[key][i] = { total: 0, month: i, _id: `${date.year}-${date.month}` };
      }
    });
  }
  return dataParse;
};

export const handleParseDataLineChart = (selectedOption, data, date) => {
  if (selectedOption === 'day') {
    return parseDataLineChartByDay(data, date);
  }
  if (selectedOption === 'month') {
    return parseDataLineChartByMonth(data, date);
  }
};

export const getLabelByYear = (data = []) => {
  const labelByYear = [];
  Object.entries(data)?.map(([key, value]) => {
    value?.map((item) => {
      labelByYear.push(item._id);
    });
  });
  let uniqueLabelByYear = [...new Set(labelByYear)];

  return uniqueLabelByYear.sort();
};
