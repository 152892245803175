import { useTranslation } from 'react-i18next';
import { FORMAT_DATE, parseDateWithGmt } from '../../../helper/date/parseDateGmt';
import { useDate } from '../../../hooks/useDate';
import { BALANCE_TYPES, ROLES, TRANSACTION_TYPES } from './constant';
import ToolTip from './Tooltip';

export const valueCreatedByTransaction = (data) => {
  return data?.created_by?.name
    ? data?.created_by?.name
    : data?.created_by?.firstname + ' ' + data?.created_by?.lastname;
};

const calculateBalanceValues = (
  transactionBalanceMain,
  transactionBalanceSub,
  adjustAmountMain,
  adjustAmountSub,
  balanceType = ''
) => {
  const balanceValues = {
    beforeValue: 0,
    afterValue: 0,
  };

  switch (balanceType) {
    case BALANCE_TYPES.PREVIOUS:
      balanceValues.beforeValue = transactionBalanceMain?.previous_balance?.toFixed(2) || '--';
      balanceValues.afterValue = transactionBalanceSub?.previous_balance?.toFixed(2) || '--';
      break;

    case BALANCE_TYPES.REMAINING:
      balanceValues.beforeValue = transactionBalanceMain?.remaining_balance?.toFixed(2) || '--';
      balanceValues.afterValue = transactionBalanceSub?.remaining_balance?.toFixed(2) || '--';
      break;

    default:
      balanceValues.beforeValue = adjustAmountMain?.toFixed(2) || '--';
      balanceValues.afterValue = adjustAmountSub?.toFixed(2) || '--';
      break;
  }

  return { balanceValues };
};

const colBalance = (rowData, balanceType = '') => {
  const { balanceValues } = calculateBalanceValues(
    rowData?.transactionBalanceMain,
    rowData?.transactionBalanceSub,
    rowData?.adjust_amount_main,
    rowData?.adjust_amount_sub,
    balanceType
  );

  return (
    <div className="flex flex-row-reverse">
      <div className="text-red-500 balance-value">{balanceValues.beforeValue}</div>
      <div className="px-2">/</div>
      <div className="text-green-500 balance-value">{balanceValues.afterValue}</div>
    </div>
  );
};

const TransactionTypeStyled = (type) => {
  const { t } = useTranslation();

  switch (type) {
    case TRANSACTION_TYPES.FEE:
      return (
        <span data-tag="allowRowEvents" className="text-red-600">
          {t('FEE')}
        </span>
      );

    case TRANSACTION_TYPES.CASH_BACK:
      return (
        <span data-tag="allowRowEvents" className="text-blue-600">
          {t('CASH BACK')}
        </span>
      );

    case TRANSACTION_TYPES.HOLDING:
      return (
        <span data-tag="allowRowEvents" className="text-yellow-600">
          {t('HOLDING')}
        </span>
      );

    case TRANSACTION_TYPES.TOP_UP:
      return (
        <span data-tag="allowRowEvents" className="text-green-600">
          {t('TOP UP')}
        </span>
      );

    case TRANSACTION_TYPES.TOP_UP_PAYFORT_REFUND:
      return (
        <span data-tag="allowRowEvents" className="text-green-600">
          {t('TOP UP PAYFORT REFUND')}
        </span>
      );

    default:
      return '-';
  }
};

const useSchema = () => {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();

  const schema = [
    {
      name: t('order_number'),
      selector: (row) => (row?.order_number ? row?.order_number : '-'),
      sortable: true,
      style: {
        padding: 0,
      },
    },
    {
      name: t('by'),
      selector: (row) =>
        `${row?.created_by?.name ? `${row?.created_by?.name} - ` : ''}${
          row?.created_by?.role === ROLES.SUPER_ADMIN ? ROLES.ADMIN : row?.created_by?.role
        }`,
      sortable: true,
      width: '200px',
      style: {
        padding: 0,
      },
    },
    // {
    //   name: t('value'),
    //   selector: 'amount',
    //   sortable: true,
    //   style: {
    //     padding: 0,
    //   },
    // },
    {
      name: t('transaction.previous_balance'),
      minWidth: '200px',
      cell: (row) => (
        <ToolTip text="previous main balance / previous gift balance">
          {colBalance(row, BALANCE_TYPES.PREVIOUS)}
        </ToolTip>
      ),
    },
    {
      name: t('transaction.remaining_balance'),
      minWidth: '200px',
      cell: (row) => (
        <ToolTip
          text={`previous main balance ${row?.adjust_amount_main >= 0 ? '+' : '-'} ${
            row?.adjust_amount_main
          }  /  previous gift balance ${row?.adjust_amount_sub >= 0 ? '+' : '-'} ${
            row?.adjust_amount_sub
          }`}
        >
          {colBalance(row, BALANCE_TYPES.REMAINING)}
        </ToolTip>
      ),
    },
    {
      name: `${t('value')} / ${t('gift')}`,
      minWidth: '200px',
      cell: (row) => (
        <ToolTip text="main remaining balance - main previous balance / gift remaining balance - gift previous balance">
          {colBalance(row)}
        </ToolTip>
      ),
    },
    {
      name: t('locate_fee'),
      selector: 'fee',
      // sortable: true,
      width: '140px',
      cell: (row) => <span className="ltr">{row?.fee ?? '0.00'}</span>,
    },
    // {
    //   name: t('gift'),
    //   selector: 'gift',
    //   sortable: true,
    //   style: {
    //     padding: 0,
    //   },
    // },
    {
      name: t('type'),
      selector: (row) => (
        <p className="font-bold break-words whitespace-pre-wrap">
          {TransactionTypeStyled(row?.type)}
        </p>
      ),
      sortable: true,
      minWidth: '150px',
      style: {
        paddingLeft: 20,
      },
    },
    {
      name: t('status'),
      selector: (row) => row?.status ?? '-',
      sortable: true,
      style: {
        padding: 0,
      },
    },
    {
      name: t('date'),
      selector: (row) =>
        parseDateWithGmt({
          date: row.created_at,
          timezone: currentTimezone?.timezone,
        }),

      sortable: true,
      wrap: true,
      width: '150px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('date_time'),
      selector: (row) =>
        parseDateWithGmt({
          date: row.created_at,
          timezone: currentTimezone?.timezone,
          format: FORMAT_DATE.hms,
        }),
      sortable: true,
      wrap: true,
      width: '150px',
      style: {
        padding: 0,
      },
    },
  ];
  return schema;
};

export default useSchema;
