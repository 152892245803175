import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { TRANSACTION_VER } from './constants';
import NewTransactions from './TransactionVer/New';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Transactions = () => {
  const [transactionVer, setTransactionVer] = useState(null);

  const query = useQuery();

  useEffect(() => {
    const version = query.get('version');
    if (version) {
      setTransactionVer(version);
    } else {
      setTransactionVer(TRANSACTION_VER.NEW);
    }
  }, []);

  if (!transactionVer) return null;

  return (
    <div className="rtl">
      <div className="">
        <NewTransactions transactionVer={transactionVer} />
      </div>
    </div>
  );
};

export default Transactions;
