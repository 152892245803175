import React from 'react';
import useBarChart from '../../../../hooks/useBarChart';
import useLogicDashBoard from '../../hooks/useLogicDashBoard';
import SelectDate from '../filter/SelectDate';
import ItemInfo from './ItemInfo';

export default function DriverAnalysis() {
  const { date, selectedOption, onChangeDate, setSelectedOption, data } =
    useLogicDashBoard('driver');

  useBarChart({ elementId: 'chart-bars', data: data?.dataSynthesis });

  return (
    <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-5/12 lg:flex-none z-0">
      <div className="p-6 h-full border-black/12.5 shadow-soft-xl relative z-20 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
        <div className="w-full  flex flex-row justify-between px-4">
          <h6 className="text-4xl font-bold ">User overview</h6>
          <SelectDate
            date={date}
            onChangeDate={onChangeDate}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        <div className="flex-auto pt-4 mt-4">
          <div className="py-4 pr-1 mb-4  bg-[#192541]  rounded-xl">
            <div>
              <canvas id="chart-bars" height="270"></canvas>
            </div>
          </div>
          {/* <h6 className="mt-8 mb-0 ml-2 font-semibold text-2xl">Active Users</h6>
          <p className="ml-2 leading-normal text-md mt-1 text-gray-500">
            (<span className="font-bold">+23%</span>) than last week
          </p> */}
          <div className="w-full px-6 mx-auto max-w-screen-2xl rounded-xl mt-8">
            <div className="flex flex-wrap mt-4 -mx-3">
              <ItemInfo label="Total user" value={data?.totalUser} />
              <ItemInfo label="Total user Active" value={data?.totalActiveUser} />
              <ItemInfo />
              <ItemInfo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
