import React, { Fragment, memo, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { config } from '../../../config/Config';

const LIMIT = 20;

const SyncOdoo = memo(() => {
  const { t } = useTranslation();

  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [totalPage, setTotalPage] = useState(0);

  const getTotalPage = async () => {
    try {
      const response = await axios.get(
        `${config.API_URL.DRIVERS.TOTAL_PAGE}?limit=${LIMIT}&page=${page}`
      );
      const { data } = response;

      if (data) {
        const { total } = data;
        setTotal(total);
        setTotalPage(Math.round(total / LIMIT));
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!isOpen) return;
    getTotalPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const getDriverSync = async (syncPage = 1) => {
    try {
      const response = await axios.patch(
        `${config.API_URL.DRIVERS.SYNC_ODOO}?limit=${LIMIT}&page=${syncPage}`,
        {},
        {
          headers: config.headers,
        }
      );
      const { data } = response;
      if (data) {
        if (syncPage <= totalPage) {
          await setPage(syncPage + 1);
          await getDriverSync(syncPage + 1);
        }
      }
    } catch (error) {}
  };

  const recursiveDriverSync = async () => {
    await getDriverSync();
  };

  const syncOdoo = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await recursiveDriverSync();
    setIsLoading(false);
  };

  const showModal = async () => {
    setIsOpen(true);
  };

  const closeModal = async () => {
    setIsOpen(false);
  };

  return (
    <div>
      <button className="refresh-button flex" type="button" onClick={showModal}>
        {t('odoo.sync_button')}
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-7xl h-96 overflow-x-hidden overflow-y-auto px-6 py-10 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h1"
                  className="font-bold text-2xl leading-6 text-gray-900 text-right"
                >
                  SYNC DRIVER WITH ODOO
                </Dialog.Title>
                <div className="mt-2">
                  <div>
                    <div class="ltr shadow w-full bg-gray-200 mt-10 relative">
                      <div
                        class="bg-yellow-500 rounded-md text-xs leading-none py-1 text-center text-white"
                        style={{
                          width: `${totalPage > page ? ((page - 1) / totalPage) * 100 : 100}%`,
                        }}
                      ></div>
                      <div className="absolute top-0 left-0 text-right w-full mt-5">
                        <span>{totalPage > page ? (page - 1) * LIMIT : total} / </span>
                        <span>Total: {total} </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full justify-end">
                    <button className="refresh-button flex mt-20" type="button" onClick={syncOdoo}>
                      {isLoading && (
                        <svg
                          class="animate-spin -ml-1 mr-3 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          width={16}
                          height={16}
                          viewBox="0 0 24 24"
                        >
                          <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      {t('odoo.sync_button')}
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
});

export default SyncOdoo;
