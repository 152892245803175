import { RadioGroup } from '@headlessui/react';
import React from 'react';
const dates = [
  // { label: 'Year', value: 'year' },
  { label: 'Month', value: 'month' },
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
];

export default function SelectOption({ value, setSelected }) {
  return (
    <RadioGroup value={value} onChange={setSelected}>
      <div className="flex flex-row  gap-x-3">
        {dates.map((date) => (
          <RadioGroup.Option
            key={date.value}
            value={date.value}
            className={({ active, checked }) =>
              `w-[30px] h-[30px] justify-center items-center z-20 ${
                active ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300' : ''
              }
                  ${checked ? 'bg-sky-900 bg-opacity-75 text-white' : 'bg-white'}
                    relative flex cursor-pointer rounded-lg   shadow-md focus:outline-none`
            }
          >
            {({ active, checked }) => (
              <>
                <div className="flex items-center justify-center ">
                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label
                        as="p"
                        className={`font-medium flex justify-center items-center  ${
                          checked ? 'text-black font-extrabold' : 'text-gray-500 '
                        }`}
                      >
                        <div className="flex justify-center items-center text-[10px]">
                          {date.label}
                        </div>
                      </RadioGroup.Label>
                    </div>
                  </div>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
