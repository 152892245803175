import { useTranslation } from 'react-i18next';
import { OPEN } from '../../constants/constants';

export default function ActionButtons(props) {
  const { handleStatusRegistration, data: selectedRowData } = props;

  const { t } = useTranslation();
  const styleButton =
    selectedRowData?.registration_status === OPEN ? styles.rejectBtn : styles.showDetailBtn;
  const textButton = selectedRowData?.registration_status === OPEN ? t('close') : t('open');

  return (
    <div style={styles.container}>
      <button style={styleButton} onClick={() => [handleStatusRegistration(selectedRowData)]}>
        {textButton}
      </button>
    </div>
  );
}
const styles = {
  container: {
    padding: 20,
    backgroundColor: '#FCF5F2',
  },

  rejectBtn: {
    backgroundColor: '#C32E52',
    border: '1px solid #C32E52',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#fff',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
  },

  showDetailBtn: {
    backgroundColor: '#9AF7C0',
    border: '1px solid #9AF7C0',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#342B40',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
  },
};
