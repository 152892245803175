import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { CONST_STATUS } from '../../../../constants/status';
import { IconLoadingMap } from '../../../../constants/Icon';

const DriverStatus = memo(
  ({
    currentStatus,
    setCurrentStatus,
    numberDriver,
    loadingDrivers,
    setDriverNotOrder,
    driverNotOrder,
  }) => {
    const { t } = useTranslation();

    const statusDriver = [
      { key: CONST_STATUS.ONLINE, name: t('online') },
      {
        key: CONST_STATUS.OFFLINE,
        name: t('offline'),
      },
    ];

    const statusDriverNotOrder = [
      { key: CONST_STATUS.HAVE_ORDER, name: t('has_an_order') },
      { key: CONST_STATUS.NOT_ORDER, name: t('have_not_an_order') },
    ];

    return (
      <div className="p-5">
        <div className="flex justify-end items-center gap-x-4 mb-4">
          {statusDriver.map((status) => {
            const handleSelect = () => {
              setCurrentStatus(status.key);
            };

            return (
              <button
                onClick={handleSelect}
                key={status.key}
                className={`tab-btn flex flex-row-reverse gap-3 ${
                  currentStatus === status.key ? 'active-tab' : ''
                }`}
              >
                <p>{status.name}</p>
                {currentStatus === status.key &&
                  (loadingDrivers ? <IconLoadingMap fill="red" /> : numberDriver)}
              </button>
            );
          })}
        </div>
        {currentStatus === 'online' && (
          <div className="flex justify-end items-center gap-x-4">
            {statusDriverNotOrder.map((hasOrder) => {
              return (
                <button
                  onClick={() => setDriverNotOrder(hasOrder.key)}
                  key={hasOrder.key}
                  className={`tab-btn flex flex-row-reverse gap-3 ${
                    driverNotOrder === hasOrder.key ? 'active-tab' : ''
                  }`}
                >
                  <p>{hasOrder.name}</p>
                  {driverNotOrder === hasOrder.key &&
                    (loadingDrivers ? <IconLoadingMap fill="red" /> : numberDriver)}
                </button>
              );
            })}
          </div>
        )}
      </div>
    );
  }
);

export default DriverStatus;
