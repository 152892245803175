import { Chart, registerables } from 'chart.js';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
Chart.register(...registerables);
export const transformDataChart = (data = []) => {
  return data.reduce(
    (accumulator, currentValue) => {
      accumulator['labels'].push(currentValue?._id);
      accumulator['values'].push(currentValue?.total);
      return accumulator;
    },
    { labels: [], values: [] }
  );
};
export default function useBarChart({ elementId, data }) {
  useEffect(() => {
    if (isEmpty(data)) return;
    const dataChart = transformDataChart(data);

    var ctx = document.getElementById(elementId).getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dataChart.labels,
        datasets: [
          {
            label: 'New drivers',
            tension: 0.4,
            borderWidth: 0,
            borderRadius: 4,
            borderSkipped: false,
            backgroundColor: 'white',
            data: dataChart.values,
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 600,
              beginAtZero: true,
              padding: 15,
              font: {
                size: 11,
                family: 'Open Sans',
                style: 'normal',
                lineHeight: 2,
              },
              color: '#fff',
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          },
        },
      },
    });
    return () => {
      myChart.destroy();
    };
  }, [data, elementId]);
  return {};
}
