import { useTranslation } from 'react-i18next';
import { REGISTRATION_DRIVER_STATUS } from '../../api/detailDriverRegistrationOrder/detailDriverRegistrationOrder.api';

export const DETAIL_TABS = {
  DRIVER: 'driver',
  ORDER: 'order',
  PARTNER: 'partner',
  DRIVER_REGISTRATION: 'driver_registration',
};

export const FilterTypesData = () => {
  const { t } = useTranslation();

  return [
    {
      value: REGISTRATION_DRIVER_STATUS.ACCEPTED,
      label: t('ACCEPTED'),
    },
    {
      value: REGISTRATION_DRIVER_STATUS.REJECTED,
      label: t('REJECTED'),
    },
    {
      value: REGISTRATION_DRIVER_STATUS.PENDING,
      label: t('PENDING'),
    },
    {
      value: REGISTRATION_DRIVER_STATUS.UNAVAILABLE,
      label: t('UNAVAILABLE'),
    },
  ];
};

export const conditionalRowStyles = [
  {
    when: (row) => row?.status === REGISTRATION_DRIVER_STATUS.REJECTED,
    style: {
      backgroundColor: '#F7DDDE',
    },
  },
  {
    when: (row) => row?.status === REGISTRATION_DRIVER_STATUS.ACCEPTED,
    style: {
      backgroundColor: '#D4E9D8',
    },
  },
  {
    when: (row) => row?.status === REGISTRATION_DRIVER_STATUS.PENDING,
    style: {
      backgroundColor: '#FFFBDB',
    },
  },
  {
    when: (row) => row?.status === REGISTRATION_DRIVER_STATUS.UNAVAILABLE,
    style: {
      backgroundColor: '#ffffff',
    },
  },
];
