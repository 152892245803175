import { useTranslation } from 'react-i18next';
import { FORMAT_DATE, parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { useDate } from '../../hooks/useDate';
const restaurant_name = (row) => <span title={row.restaurant_city}>{row.restaurant_name}</span>;
const customer_name = (row) => <span title={row.customer_city}>{row.customer_name}</span>;

//old_logic
// selector: (row) =>
//         `${
//           row.isDelivered
//             ? status.success
//             : row.isCanceled
//             ? status.canceled
//             : !isEmpty(row?.driver_id)
//             ? status.ongoing
//             : status.waiting
//         }`

const selectStatus = (row, status) => {
  // case: success
  if (row?.isDelivered) {
    return status.success;
  }

  // case: cancel
  if (row?.isCanceled) {
    return status.canceled;
  }

  // case: assigned
  /***
   * After assigned order_status is null
   * When driver update order_status, backend adds 3 new order_status:
   * arrived_store, take_order, wait_customer
   */

  if (row?.driver_id) {
    if (row?.order_status) {
      return status[row?.order_status];
    }
    return status.ongoing;
  }

  //case: waiting
  return status.waiting;
};

const useSchema = () => {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();
  const payments = [
    {
      cash: {
        title: t('cash'),
      },
      card: {
        title: t('card'),
      },
      point: {
        title: t('table_point'),
      },
    },
  ];

  const status = {
    success: t('successful'),
    canceled: t('canceled_orders'),
    waiting: t('waiting'),
    ongoing: t('ongoing'),
    arrived_store: t('arrived_store'),
    take_order: t('take_order'),
    wait_customer: t('wait_customer'),
  };

  const schema = [
    {
      name: t('order'),
      selector: (row) => {
        return !row?.parent_order_id ? (
          row?.order_number
        ) : (
          <div className="flex flex-row">
            <p className="text-xl">{row?.order_number}</p>
            <p className="text-red-700 font-bold mx-3 ">R</p>
          </div>
        );
      },
      sortable: true,
      style: {
        padding: 0,
      },
    },

    {
      name: t('delegate'),
      selector: (row) => `${row?.driver ? row?.driver?.name || '' : t('waiting_for_acceptance')}`,
      sortable: true,
      width: '200px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('company'),
      width: '130px',
      selector: 'from_company',
      sortable: true,
      wrap: true,
      style: {
        direction: 'ltr',
        justifyContent: 'flex-end',
        padding: 0,
      },
    },
    {
      name: t('restaurant'),
      selector: 'restaurant_name',
      sortable: true,
      wrap: true,
      width: '200px',
      style: {
        padding: 0,
      },
      cell: restaurant_name,
    },
    {
      name: t('customer'),
      selector: 'customer_name',
      sortable: true,
      wrap: true,
      width: '200px',
      style: {
        padding: 0,
      },
      cell: customer_name,
    },
    {
      name: t('phone_number'),
      selector: 'customer_phone',
      sortable: true,
      wrap: true,
      width: '130px',
      style: {
        padding: 0,
        direction: 'ltr',
        justifyContent: 'flex-end',
      },
    },
    {
      name: t('ordered_at'),
      selector: (row) =>
        parseDateWithGmt({ date: row.created_at, timezone: currentTimezone?.timezone }),

      sortable: true,
      wrap: true,
      minWidth: '200px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('isCasper'),
      selector: (row) => `${row?.driver?.isCasper ? t('true') : t('false')}`,
      sortable: true,
      width: '100px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('isPreview'),
      selector: (row) => `${row?.is_preview_order ? t('true') : t('false')}`,
      sortable: true,
      width: '100px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('order_time'),
      selector: (row) =>
        parseDateWithGmt({
          date: row.created_at,
          timezone: currentTimezone?.timezone,
          format: FORMAT_DATE.hms,
        }),
      sortable: true,
      wrap: true,
      minWidth: '150px',
      style: {
        direction: 'ltr',
        justifyContent: 'flex-end',
        padding: 0,
      },
    },

    {
      name: t('acceptance_time'),
      minWidth: '100px',
      selector: (row) =>
        parseDateWithGmt({
          date: row.assigned_at,
          timezone: currentTimezone?.timezone,
          format: FORMAT_DATE.hms,
        }),
      sortable: true,
      wrap: true,
      style: {
        direction: 'ltr',
        justifyContent: 'flex-end',
        padding: 0,
      },
    },

    {
      name: t('finish_time'),
      minWidth: '150px',
      selector: (row) =>
        parseDateWithGmt({
          date: row.delivery_time,
          timezone: currentTimezone?.timezone,
          format: FORMAT_DATE.hms,
        }),
      sortable: true,
      wrap: true,
      style: {
        direction: 'ltr',
        justifyContent: 'flex-end',
        padding: 0,
      },
    },
    {
      name: t('payment_method'),
      width: '130px',
      selector: (row) => {
        return `${row.point_type === 'total' ? 'Point' : row.payment_method}`;
      },
      // selector: (row) => {
      //   return `${
      //     row.payment_method === 'Cash'
      //       ? payments[0].cash.title
      //       : row.payment_method === 'Card' || row.payment_method === 'online'
      //       ? payments[0].card.title
      //       : row.payment_method === 'Point' && payments[0].point.title
      //   }`;
      // },
      sortable: true,
      style: {
        padding: 0,
      },
    },
    {
      name: t('delivery_value'),
      selector: 'delivery_cost',
      sortable: true,
      width: '130px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('discount_value'),
      selector: 'discount_amount',
      cell: (row) =>
        row?.discount_amount?.length > 0 ? Number(row?.discount_amount)?.toFixed(2) : '0.00',

      sortable: true,
      width: '130px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('free'),
      selector: (row) => `${row.isFreeExpress === true ? t('yes') : t('no')}`,
      sortable: true,
      width: '50px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('status'),
      selector: (row) => selectStatus(row, status),
      sortable: true,
      width: '180px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('geidea'),
      selector: (row) => `${row.use_geidea === true ? t('yes') : t('no')}`,
      sortable: true,
      width: '100px',
      style: {
        padding: 0,
      },
    },
  ];
  return schema;
};

export default useSchema;
