import React, { createContext, useMemo, useRef, useState } from 'react';
import { getShapeCoords } from '../helper/utils';

export const MapTrackingContext = createContext(null);
const centerDefault = { lat: 26.4910430908203, lng: 50.0308636515505 };
const initialStateFilterOrder = {
  status: {},
  keyword: '',
  orderAssigned: false,
};
const MapTrackingContextProvider = (props) => {
  const refKeywordSearch = useRef('');
  const refSearchDriver = useRef('');
  const keyNamePolygon = 'valuePolygonLocalStorage';
  const valuePolygon = localStorage.getItem(keyNamePolygon);
  const defaultValuePolygon = valuePolygon ? JSON.parse(valuePolygon) : [];

  const [map, setMap] = useState(null);
  const [polygon, setPolygon] = useState(defaultValuePolygon);
  const [orders, setOrders] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [boundaries, setBoundaries] = useState({});
  const [orderFocus, setOrderFocus] = useState();
  const [driverFocus, setDriverFocus] = useState(null);
  const [loadingDrivers, setLoadingDrivers] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(false);
  // const [isOpenFetchDataWhenZoom, setOpenFetchDataWhenZoom] = useState(false);

  const [filtersOrder, setFiltersOrder] = useState(initialStateFilterOrder);
  const onChangeFieldFilterOrder = (field, value) => {
    setFiltersOrder({ ...filtersOrder, [field]: value });
  };

  const onResetFilterOrder = () => {
    setFiltersOrder(initialStateFilterOrder);
  };

  const orderId = orderFocus?._id;
  const driverId = driverFocus?.driver?.id;

  const onChange = ({ bounds }, isOpenFetchDataWhenZoom) => {
    if (isOpenFetchDataWhenZoom) {
      setBoundaries(bounds);
    }
  };

  const polygonCompleted = async (event) => {
    if (!event) {
      localStorage.setItem(keyNamePolygon, []);
      return setPolygon([]);
    }
    const { overlay } = event;
    const newPolygon = getShapeCoords(overlay);
    localStorage.setItem(keyNamePolygon, JSON.stringify(newPolygon));
    const valuePolygon = localStorage.getItem(keyNamePolygon);

    setPolygon(JSON.parse(valuePolygon));
  };

  const handlerDriverId = (driver) => {
    if (driverId) {
      setDriverFocus(null);
      setOrderFocus(null);
      refSearchDriver.current.value = '';
    } else {
      setDriverFocus(driver);
    }
  };
  const onResetDriverFocus = () => {
    setDriverFocus(null);
  };
  const handlerFocusOrder = (order, driver) => {
    setOrderFocus(order);
    if (driver) {
      setDriverFocus(driver);
    }
  };

  const handlerSelectedDriver = (driver) => {
    setDriverFocus(driver);
  };

  const center = driverFocus
    ? {
        lat: driverFocus?.latitude,
        lng: driverFocus?.longitude,
      }
    : centerDefault;

  const fitBoundsCenter = useMemo(() => {
    const listLocation = [];
    const orderByDriverFocus = driverId
      ? orders.filter((item) => driverId === item?.driver_id)
      : orders;
    const listLocationRestaurantAndCustomer = orderByDriverFocus.reduce(
      (previousValue, currentValue) => {
        previousValue.push({
          lat: currentValue.restaurant_location.coordinates[1],
          lng: currentValue.restaurant_location.coordinates[0],
        });
        previousValue.push({
          lat: currentValue.customer_location.coordinates[1],
          lng: currentValue.customer_location.coordinates[0],
        });
        return previousValue;
      },
      []
    );

    drivers?.map((item) => {
      const { latitude, longitude } = item;
      return listLocation.push({ lat: latitude, lng: longitude });
    });
    // listLocation.push({ lat: center.lat, lng: center.lng });

    // cho lay lay tu driver selected
    listLocation.push(...listLocationRestaurantAndCustomer);

    return listLocation;
  }, [orders, center, driverId, drivers]);

  const isRenderNewMaps = useMemo(
    () => !loadingDrivers && !loadingOrders,
    [loadingDrivers, loadingOrders]
  );
  const getBounds = (curMap) => {
    const _bounds = curMap?.getBounds();
    const ne = _bounds?.getNorthEast();
    const sw = _bounds?.getSouthWest();
    const bounds = {
      northLatitude: ne?.lat(),
      eastLongitude: ne?.lng(),
      southLatitude: sw?.lat(),
      westLongitude: sw?.lng(),
    };
    return bounds;
  };

  const onGetCurrentBoundary = () => {
    return getBounds(map);
  };

  return (
    <MapTrackingContext.Provider
      value={{
        handlerDriverId,
        onResetDriverFocus,
        handlerSelectedDriver,
        drivers,
        setDrivers,
        center,
        polygon,
        polygonCompleted,
        boundaries,
        onChange,
        orders,
        setOrders,
        handlerFocusOrder,
        orderId,
        orderFocus,
        setOrderFocus,
        driverId,
        driverFocus,
        fitBoundsCenter,
        refKeywordSearch,
        refSearchDriver,
        isRenderNewMaps,
        // loading
        setLoadingDrivers,
        setLoadingOrders,
        loadingDrivers,
        loadingOrders,
        // loading
        onChangeFieldFilterOrder,
        filtersOrder,
        onResetFilterOrder,
        map,
        setMap,
        getBounds,
        onGetCurrentBoundary,
        setBoundaries,
      }}
    >
      {props.children}
    </MapTrackingContext.Provider>
  );
};

export default MapTrackingContextProvider;
