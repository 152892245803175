import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { config } from '../../config/Config';
import { Slide, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import moment from 'moment';

import DropdownInput from '../DropdownInput';
import CustomSelectReact from '../CustomSelectReact';
import { useHistory } from 'react-router-dom';

toast.configure();
const EditOperator = () => {
  const history = useHistory();

  const { t } = useTranslation();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);

  const getRegionList = async () => {
    const response = await axios.get(config.API_URL.CITC_LOOKUP.REGIONS);
    if (response?.status === 200) {
      const regionList = response?.data;
      const formattedRegionList = [];
      regionList.forEach((region) => {
        formattedRegionList.push({
          value: region.id,
          label: region.nameAr,
        });
      });
      setRegions(formattedRegionList);
    }
    setLoading(false);
  };

  useEffect(() => {
    getRegionList();
  }, []);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('ops_management.validate.required.firstName')),
    lastName: Yup.string().required(t('ops_management.validate.required.lastName')),
    nationalID: Yup.string().required(t('ops_management.validate.required.nationalID')),
    phone: Yup.string().required(t('ops_management.validate.required.phone')),
    opsId: Yup.string().required(t('ops_management.validate.required.opsId')),
    region_id: Yup.string().required(t('ops_management.validate.required.region_id')),
    cities: Yup.array().min(1, t('ops_management.validate.required.cities')),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      nationalID: '',
      address: '',
      phone: '',
      dobDay: '',
      dobMonth: '',
      dobYear: '',
      opsId: '',
      region_id: '',
      cities: [],
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validateOnChange: false,
  });

  useEffect(() => {
    if (formik.values?.region_id && formik.values?.region_id?.length > 0) {
      getCities();
    }
  }, [formik.values.region_id]);

  const getCities = async () => {
    const response = await axios.get(
      `${config.API_URL.CITC_LOOKUP.CITIES}?regionId=${formik.values?.region_id}`
    );
    if (response?.status === 200) {
      const citiesList = response?.data;
      const formattedCitiesList = [];
      citiesList.forEach((region) => {
        formattedCitiesList.push({
          value: region.id,
          label: region.nameAr,
        });
      });
      setCities(formattedCitiesList);
      if (selectedCities && selectedCities.length > 0) {
        let formattedSelectedCity = [];

        for (let i = 0; i < selectedCities.length; i++) {
          formattedSelectedCity.push(
            formattedCitiesList.filter((selectCity) => selectedCities[i] === selectCity.value)?.[0]
          );
        }
        formik.setValues({
          ...formik.values,
          cities: formattedSelectedCity,
        });
      } else {
        formik.setValues({
          ...formik.values,
          cities: [],
        });
      }
    }
    setLoading(false);
  };

  const handleSelectRegion = (select) => {
    formik.setValues({ ...formik.values, region_id: select.value });
  };

  const handleSelectCities = (select) => {
    formik.setValues({ ...formik.values, cities: select });
  };

  const borderInputError = {
    border: '1px solid #E95F67',
  };

  const handleSubmit = async () => {
    setLoading(true);
    const finalizeData = {
      firstname: formik.values.firstName,
      lastname: formik.values.lastName,
      code: formik.values.opsId,
      phone: formik.values.phone,
      nationality_id: formik.values.nationalID,
      address: formik.values.address,
      region_id: formik.values.region_id,
      city_id: formik.values.cities.map((city) => city.value),
    };
    if (formik.values.dobYear && formik.values.dobDay && formik.values.dobMonth) {
      finalizeData.date_of_birth = `${formik.values.dobYear}/${formik.values.dobMonth}/${formik.values.dobDay}`;
    }
    try {
      const { data } = await axios.put(
        `${config.API_URL.AUTH.LIST_OPERATORS}/${id}/update`,
        finalizeData,
        {
          headers: config.headers,
        }
      );
      if (data?.status === 200) {
        handleMessage('success');
        history.goBack();
      } else {
        handleMessage('error', t('error'));
      }
      setLoading(false);
    } catch (error) {
      const errorList = error?.response?.data?.errors;
      if (errorList) {
        errorList.forEach((error) => handleMessage('error', error.msg));
      }
      setLoading(false);
    }
  };

  const handleMessage = (type = 'success', message = '') => {
    if (type === 'success') {
      return toast.success(t('ops_management.action.edit.success'), {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
      });
    }
    return toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Slide,
    });
  };

  const getAccountDetail = async () => {
    const { data } = await axios.get(`${config.API_URL.AUTH.LIST_OPERATORS}/${id}/edit`, {
      headers: config.headers,
    });
    if (data) {
      setEmail(data?.email);
      setSelectedCities(data?.city_id);

      await formik.setValues({
        ...formik.values,
        email: data?.email || '',
        firstName: data?.firstname || '',
        lastName: data?.lastname || '',
        nationalID: data?.nationality_id || '',
        address: data?.address || '',
        phone: data?.phone || '',
        opsId: data?.code || '',
        region_id: data?.region_id || '',
      });

      regions.forEach((region) => {
        if (region.value === data?.region_id) {
          setSelectedRegion(region);
          formik.setValues({
            ...formik.values,
            region_id: data?.region_id || '',
          });
        }
      });

      if (data?.date_of_birth) {
        const day = await moment(data?.date_of_birth).date();
        let formattedDay = '';
        if (day < 10) {
          formattedDay = `0${day}`;
        } else {
          formattedDay = `${day}`;
        }
        const month = await moment(data?.date_of_birth).month();
        let formattedMonth = '';
        if (month + 1 < 10) {
          formattedMonth = `0${month + 1}`;
        } else {
          formattedMonth = `${month + 1}`;
        }
        const year = await moment(data?.date_of_birth).year().toString();
        formik.setValues({
          ...formik.values,
          dobDay: formattedDay || '',
          dobMonth: formattedMonth || '',
          dobYear: year || '',
        });
      }
    }
  };

  useEffect(() => {
    if (regions.length > 0) {
      getAccountDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regions]);

  return (
    <div className="add-ops-board">
      <h3 className="title">{t('ops_management.register.title')}</h3>
      <div className="ops-form">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <DropdownInput
                id="region_id"
                name="region_id"
                textAlign="center"
                placeholder={t('region')}
                options={regions}
                handleSelect={handleSelectRegion}
                hasDefault={false}
                className={`!w-[200px] !min-w-[200px] ${
                  formik.errors.region_id ? '!border-red-500' : ''
                }`}
                wrapperClassName="rtl"
                defaultSelectedValue={selectedRegion}
              />
              {formik.errors.region_id && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.region_id}</span>
              )}
            </div>
            <span>{t('region')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <CustomSelectReact
                id="cities"
                name="cities"
                placeholder={t('city')}
                onChange={handleSelectCities}
                className="filter-select w-[200px]"
                options={cities}
                value={formik.values.cities}
                isMulti
              />
              {formik.errors.region_id && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.region_id}</span>
              )}
            </div>
            <span>{t('region')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="opsId"
                name="opsId"
                type="text"
                style={formik.errors.opsId && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.opsId}
              />
              {formik.errors.opsId && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.opsId}</span>
              )}
            </div>
            <span>{t('ops_management.register.opsId')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="email"
                name="email"
                type="text"
                className="center rounded-md"
                onChange={formik.handleChange}
                value={email}
                disabled
              />
            </div>
            <span>{t('ops_management.register.email')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="firstName"
                name="firstName"
                type="text"
                style={formik.errors.firstName && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />
              {formik.errors.firstName && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.firstName}</span>
              )}
            </div>
            <span>{t('ops_management.register.firstName')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="lastName"
                name="lastName"
                type="text"
                style={formik.errors.lastName && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />
              {formik.errors.lastName && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.lastName}</span>
              )}
            </div>
            <span>{t('ops_management.register.lastName')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="nationalID"
                name="nationalID"
                type="text"
                style={formik.errors.nationalID && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.nationalID}
              />
              {formik.errors.nationalID && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.nationalID}</span>
              )}
            </div>
            <span>{t('ops_management.register.nationalID')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="address"
                name="address"
                type="text"
                style={formik.errors.address && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.address}
              />
            </div>
            <span>{t('ops_management.register.address')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <div>
                <input
                  id="dobYear"
                  name="dobYear"
                  type="text"
                  style={formik.errors.dobYear && borderInputError}
                  className="input80"
                  onChange={formik.handleChange}
                  placeholder="YYYY"
                  value={formik.values.dobYear}
                />
                <input
                  id="dobMonth"
                  name="dobMonth"
                  type="text"
                  style={formik.errors.dobMonth && borderInputError}
                  className="input60"
                  onChange={formik.handleChange}
                  placeholder="MM"
                  value={formik.values.dobMonth}
                />
                <input
                  id="dobDay"
                  name="dobDay"
                  type="text"
                  style={formik.errors.dobDay && borderInputError}
                  className="input60"
                  onChange={formik.handleChange}
                  placeholder="DD"
                  value={formik.values.dobDay}
                />
              </div>
            </div>
            <span>{t('ops_management.register.dob')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="phone"
                name="phone"
                type="text"
                style={formik.errors.phone && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
              {formik.errors.phone && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.phone}</span>
              )}
            </div>
            <span>{t('ops_management.register.phone')}</span>
          </div>
          <div className="">
            <button
              type="submit"
              className={`add-btn ${loading ? 'disabled' : ''}`}
              disabled={loading}
            >
              {t('ops_management.register.submit')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditOperator;
