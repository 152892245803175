import { Chart, registerables } from 'chart.js';
import { cloneDeep, isEmpty } from 'lodash';
import { useEffect } from 'react';
import { daysInMonth } from '../components/Dashboard/hooks/useLogicDashBoard';
import {
  bgColorLineChart,
  borderColorLineChart,
  generateLabelsChart,
  handleParseDataLineChart,
} from '../helper/chart/lineChart';
Chart.register(...registerables);

export default function useLineChart({ elementId, date, data, selectedOption }) {
  const dayInMonth = daysInMonth(date.month, date.year);

  useEffect(() => {
    if (isEmpty(data)) return;
    const dataLineChart = handleParseDataLineChart(selectedOption, data, date);
    var ctx2 = document.getElementById(elementId).getContext('2d');
    const generateColor = (index) => {
      var gradientStroke = ctx2.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, bgColorLineChart[index]?.stop0 || bgColorLineChart[0]?.stop0);
      gradientStroke.addColorStop(
        0.2,
        bgColorLineChart[index]?.stop1 || bgColorLineChart[0]?.stop1
      );
      gradientStroke.addColorStop(0, bgColorLineChart[index]?.stop2 || bgColorLineChart[0]?.stop2); //purple colors
      return {
        gradientStroke,
        borderColor: borderColorLineChart[index] || '#3A416F',
      };
    };
    const generateLine = (labels, data, index) => {
      const getValue = () => {
        return data?.map((item) => {
          return item?.total;
        });
      };
      const dataLine = getValue();
      return {
        label: labels,
        tension: 0.4,
        pointRadius: 0,
        borderColor: generateColor(index).borderColor,
        borderWidth: 2,
        backgroundColor: generateColor(index).gradientStroke,
        fill: true,
        data: dataLine,
        maxBarThickness: 6,
      };
    };
    const res = Object.entries(dataLineChart).map(([key, value], index) => {
      const newArDataChartByDay = cloneDeep(value);
      newArDataChartByDay.splice(0, 1);
      return generateLine(key, newArDataChartByDay, index);
    });
    const myChart = new Chart(ctx2, {
      type: 'line',
      data: {
        labels: generateLabelsChart(selectedOption, dayInMonth, data),
        datasets: res,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: '#b2b9bf',
              font: {
                size: 11,
                family: 'Open Sans',
                style: 'normal',
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: '#b2b9bf',
              padding: 20,
              font: {
                size: 11,
                family: 'Open Sans',
                style: 'normal',
                lineHeight: 2,
              },
            },
          },
        },
      },
    });
    return () => {
      myChart.destroy();
    };
  }, [data]);
  return {};
}
