/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { memo, useEffect, useState } from 'react';
import { config } from '../../../config/Config';
import SearchDrivers from './SearchDrivers';

import { IconMapLoading } from '../../../constants/Icon';
import { CONST_STATUS } from '../../../constants/status';
import useMapTracking from '../../../hooks/useMapTracking';
import ButtonRefresh from '../../Atoms/ButtonRefresh';
import DriverCount from '../../Atoms/DriverCount';
import DriverCards from './DriverCards';
import DriverStatus from './DriverStatus';
import { useTranslation } from 'react-i18next';
import { Switch } from '@headlessui/react';
import NotificationModalMap from './NotificationModal';

const MapDrivers = ({
  markerDrivers,
  setMarkerDrivers,
  markerCustomers,
  setMarkerCustomers,
  markerRestaurants,
  setMarkerRestaurants,
}) => {
  const { t } = useTranslation();

  const [currentStatus, setCurrentStatus] = useState(CONST_STATUS.ONLINE);
  const [keywordSearch, setKeywordSearch] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [switchUrl, setSwitchUrl] = useState('DRIVER_2');
  const [driverNotOrder, setDriverNotOrder] = useState(undefined);
  const {
    handlerDriverId,
    driverId,
    onResetDriverFocus,
    drivers,
    polygon,
    orderId,
    setDrivers,
    boundaries,
    refSearchDriver,
    loadingDrivers,
    setLoadingDrivers,
  } = useMapTracking();

  const handleSwitchUrl = () => {
    if (switchUrl === 'DRIVER') {
      return setSwitchUrl('DRIVER_2');
    }
    setSwitchUrl('DRIVER');
  };

  const toggleModal = (visible) => {
    setModalVisible(visible);
  };
  const handleToggleModal = () => {
    toggleModal(true);
  };

  const getDriver = async ({ findPolygon = [], bounds, filter = {}, keyword = '' }) => {
    if (loadingDrivers) return;
    try {
      setLoadingDrivers(true);
      const resp = await axios.post(
        config.API_URL.LOCATION[switchUrl],
        { filter, polygon: findPolygon, boundaries: bounds, keyword },
        {
          headers: config.headers,
        }
      );
      setDrivers(resp.data?.drivers || []);
    } catch (errors) {
    } finally {
      setLoadingDrivers(false);
    }
  };

  const handlerRefresh = async () => {
    setKeywordSearch('');
    setDriverNotOrder(undefined);
    if (driverId) {
      onResetDriverFocus();
    }
    refSearchDriver.current.value = '';
    await getDriver({
      findPolygon: polygon,
      filter: {
        status: currentStatus,
      },
    });
  };

  useEffect(() => {
    getDriver({
      bounds: driverId?._id ? {} : boundaries,
      findPolygon: polygon,
      keyword: keywordSearch,
      filter: {
        driver_id: driverId || null,
        status: currentStatus || CONST_STATUS.ONLINE,
        isHasOrder: driverNotOrder,
        orderId,
      },
    });
  }, [
    driverId,
    currentStatus,
    orderId,
    polygon,
    boundaries,
    keywordSearch,
    switchUrl,
    driverNotOrder,
  ]);

  const handlerStatus = (status) => {
    setKeywordSearch('');
    setDriverNotOrder(undefined);
    handlerDriverId(null);
    refSearchDriver.current.value = '';
    setCurrentStatus(status);
  };

  const toggleBackground = (marker) =>
    `${
      marker ? 'bg-blue-600' : 'bg-gray-200'
    } relative inline-flex h-6 w-11 items-center rounded-full`;
  const toggleTranslate = (marker) =>
    `${
      marker ? 'translate-x-6' : 'translate-x-1'
    } inline-block h-4 w-4 transform rounded-full bg-white transition`;

  return (
    <>
      <div className="card-drivers cards">
        <div className="w-full justify-center items-center h-22 flex bg-white pt-4 pb-6">
          <div className="h-12 w-full">{loadingDrivers && <IconMapLoading fill="red" />}</div>
        </div>
        <div className="flex justify-center">
          <button
            className={`p-4 rounded-lg border border-blue-400 mt-2 text-black ${
              switchUrl === 'DRIVER_2' && 'bg-red-300'
            }`}
            onClick={handleSwitchUrl}
          >
            Switch Url
          </button>
        </div>

        <div className="p-4 bg-white">
          <p className="mb-3 text-center text-2xl font-bold">{t('toggle_marker')}</p>
          <div className="flex justify-between ">
            <div className="text-center">
              <Switch
                checked={markerDrivers}
                onChange={setMarkerDrivers}
                className={toggleBackground(markerDrivers)}
              >
                <span className={toggleTranslate(markerDrivers)} />
              </Switch>
              <p>{t('drivers')}</p>
            </div>

            <div className="text-center">
              <Switch
                checked={markerCustomers}
                onChange={setMarkerCustomers}
                className={toggleBackground(markerCustomers)}
              >
                <span className={toggleTranslate(markerCustomers)} />
              </Switch>
              <p>{t('customers')}</p>
            </div>

            <div className="text-center">
              <Switch
                checked={markerRestaurants}
                onChange={setMarkerRestaurants}
                className={toggleBackground(markerRestaurants)}
              >
                <span className={toggleTranslate(markerRestaurants)} />
              </Switch>
              <p>{t('restaurants')}</p>
            </div>
          </div>
        </div>

        <DriverCount />
        <SearchDrivers currentStatus={currentStatus} setKeywordSearch={setKeywordSearch} />
        <div className="flex gap-2 justify-between">
          <button onClick={() => handleToggleModal()} className="refresh-button mt-2">
            {t('send_noti.send_notification')}
          </button>
          <ButtonRefresh handlerRefresh={handlerRefresh} />
        </div>
        <DriverStatus
          numberDriver={drivers?.length}
          currentStatus={currentStatus}
          setCurrentStatus={handlerStatus}
          loadingDrivers={loadingDrivers}
          setDriverNotOrder={setDriverNotOrder}
          driverNotOrder={driverNotOrder}
        />

        <div className="h-[calc(100vh-30vh)]">
          <DriverCards selectedTab={currentStatus} />
        </div>
        <NotificationModalMap visible={modalVisible} toggleModal={toggleModal} drivers={drivers} />
      </div>
    </>
  );
};

export default memo(MapDrivers);
