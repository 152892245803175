import axios from 'axios';
import { config } from '../../config/Config';

export const createOrderOdm = async (currentMarker, setIsCreatingOrderOdm, callBack) => {
  function generateRandomNumber() {
    const prefix = '_ODM';
    const randomNumber = Math.floor(100000 + Math.random() * 900000); // Generates a random number between 1000 and 9999
    return `${prefix}${randomNumber}`;
  }
  const restaurant_coordinates = currentMarker?.lat + ',' + currentMarker?.lng;
  const data = {
    order_number: generateRandomNumber(),
    delivery_cost: '1.00',
    point_type: 'order',
    sub_total: '1.00',
    area_name: 'huhu',
    discount_amount: '0',
    fee: '2',
    tax: '2',
    from_company: 'Just',
    restaurant_name: 'JUST Restaurant',
    restaurant_phone: '299-758-8248',
    restaurant_code: '567',
    restaurant_city: 'Res_City',
    restaurant_logo:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxRmYX4OqLGoOrbQXe2XFcDAbNphmu7dgkdQ&s',
    restaurant_coordinates: restaurant_coordinates,
    customer_name: 'Terry Strosin',
    customer_phone: '+966541797780',
    customer_city: 'Cus_City',
    customer_address: 'Customer Address',
    customer_coordinates: '26.46, 50.02857802',
    payment_method: 'Cash',
    isFreeExpress: true,
    priority: '1',
    assigned_by: 'self',
    region_id: 'NV25GlPuOnQ=',
    city_id: 'NV25GlPuOnQ=',
    items: [
      {
        order_number: '001',
        item_name: 'Ball',
        item_quantity: '1',
        item_price: '14.00',
      },
      {
        order_number: '001',
        item_name: 'Pizza',
        item_quantity: '3',
        item_price: '9.50',
      },
    ],
  };
  try {
    setIsCreatingOrderOdm(true);
    const res = await axios.post(config.API_URL.LOCATION.CREATE_ORDER_ODM, data, {
      headers: {
        ...config.headers,
      },
    });
    console.log('🚀 ~ handleCreateOrder ~ res:', res.data);
    if (typeof callBack === 'function') callBack();
  } catch (error) {
    console.log('🚀 ~ handleCreateOrder ~ error:', error);
  } finally {
    setIsCreatingOrderOdm(false);
  }
};
