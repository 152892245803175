import axios from 'axios';
import produce from 'immer';
import { delay } from 'lodash';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Slide, toast } from 'react-toastify';
import { config } from '../../config/Config';
import { SpinLoading } from '../../constants/Icon';
import { useColumn } from './useColumn';

const UpFileAction = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadFile = async () => {
    try {
      setIsLoading(true);
      const uuidOfCitc = data?.uuid_citc;

      if (uuidOfCitc) {
        window.open(config.API_URL.SYNC_FILE.RECOVERY_REPORT_FILE(data?.uuid_citc), '_self');

        toast.success('Download file success', {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Slide,
        });

        delay(() => setIsLoading(false), 2000);
      }
    } catch (error) {
      toast.error('Download file error', {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
      });

      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center py-2 px-[16px]">
      <button
        disabled={isLoading}
        onClick={handleDownloadFile}
        type="button"
        className={`ml-12 h-[35px] relative justify-center items-center flex focus:outline-none text-white bg-green-600 hover:bg-green-700 focus:ring-1 focus:ring-green-300 font-medium rounded-lg text-xl px-12 py-2.5 mr-2 dark:bg-green-600 dark:hover:bg-green-600 dark:focus:ring-green-700 ${
          isLoading ? 'opacity-70' : 'opacity-100'
        }`}
      >
        <div className="absolute px-2 left-2 h-[35px] ">
          {isLoading && (
            <div className="flex justify-center items-center h-full">
              <SpinLoading />
            </div>
          )}
        </div>

        <div className="leading-normal flex justify-center items-center">Download File</div>
      </button>
    </div>
  );
};

export default function SyncFile() {
  const { t } = useTranslation();
  const { columns } = useColumn();
  const [meta, setMeta] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const tableHeight = window.innerHeight - 390;

  const onUpdateStatusFile = (id_number) => {
    const index = data.findIndex((i) => i.id === id_number);
    const newData = produce(data, (draft) => {
      draft[index].is_recovery = true;
    });
    setData(newData);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const onGetAllFile = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const resp = await axios.get(config.API_URL.SYNC_FILE.STORAGE_EXCEL, {
        params: {
          limit: limit,
          page: currentPage,
        },
        headers: config.headers,
      });
      const {
        data: { meta, data },
      } = resp;

      setMeta(meta);
      setData(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onGetAllFile();
  }, [limit, currentPage]);

  const conditionalRowStyles = [
    {
      when: (row) => row?.is_recovery === true,
      style: {
        backgroundColor: '#D4E9D8',
        color: 'rgba(0,0,0,0.87)',
      },
    },
    {
      when: (row) => row?.is_recovery === false,
      style: {
        backgroundColor: '#F8EECC',
        color: 'rgba(0,0,0,0.87)',
      },
    },
  ];

  const noRecordOfData = () => {
    return <div style={{ padding: '24px' }}>{t('orders_no_records')}</div>;
  };

  return (
    <div className="">
      <DataTable
        noDataComponent={noRecordOfData}
        columns={columns}
        data={data}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(+currentRowsPerPage);
          setCurrentPage(1);
        }}
        progressPending={loading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationTotalRows={meta?.totalItems}
        paginationServer
        onChangePage={handlePageChange}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        conditionalRowStyles={conditionalRowStyles}
        expandableRows
        expandableRowsHideExpander
        expandOnRowClicked
        expandableRowsComponent={
          <UpFileAction data={(row) => row} onUpdateStatusFile={onUpdateStatusFile} />
        }
        selectableRowSelected={(row) => row.isSelected}
        paginationDefaultPage={currentPage}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              justifyContent: 'flex-start',
              background: '#F9F9F9',
            },
          },
          rows: {
            style: {
              cursor: 'pointer',
              fontSize: 12,
            },
          },
        }}
      />
    </div>
  );
}
