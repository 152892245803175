import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import axios from 'axios';
import { uniqueId, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import LoadingButton from '../../commonComponents/LoadingButton';
import { config } from '../../config/Config';
import { buildQueryString, parseQueryString } from '../../helper/utils';
import useEventEnter from '../../hooks/useEventEnter';
import CustomSelectReact from '../CustomSelectReact';

import SearchInput from '../SearchInput';
import { conditionalRowStyles, FilterStatuses } from './constants';
import useColumn from './useColumns';

import ActionButtons from '../../commonComponents/DriverRegistration/ActionButtons';
import DriverRegistrationDetailsModal from '../../commonComponents/DriverRegistration/DriverRegistrationDetailsModal';

import {
  REGISTRATION_DRIVER_STATUS,
  X_ACTION_DRIVER_REGISTRATION_ORDER_STATUS,
  patchUpdateDriverRegistrationOrderStatusAPI,
} from '../../api/detailDriverRegistrationOrder/detailDriverRegistrationOrder.api';

import { handleMessage } from '../../helper/utils';

const DriverRegistration = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { columns } = useColumn();
  const queryString = parseQueryString(search);
  const filterStatuses = FilterStatuses();
  const tableHeight = window.innerHeight - 330;

  const [driverRegistration, setDriverRegistration] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedId, setSelectedId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [parsingQuery, setParsingQuery] = useState(true);
  const [newRender, setNewRender] = useState(uniqueId());

  const handleChangePage = (nextPage) => {
    if (Number(nextPage) === Number(currentPage) || isLoading) return false;

    setCurrentPage(nextPage);
    setNewRender(uniqueId());
  };

  const handleSetFilters = (newFilter) => {
    setCurrentPage(1);
    setFilters(newFilter);
  };

  const handleChangeDateOnFilter = (value) => {
    if (!value) {
      setDateRange([null, null]);
    }

    handleSetFilters({
      ...filters,
      date: value ? [value[0], value[1]] : [null, null],
    });
  };

  const handleChangeFilter = (optionValues, type) => {
    const newFilters = {
      ...filters,
      [type]: optionValues,
    };

    handleSetFilters(newFilters);
  };

  const handleOpenModal = (selectedRowData) => {
    setIsModalVisible(true);
    setSelectedLog(selectedRowData);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedLog(null);
  };

  const changeStatusToAccept = async (selectedRowDataToAccept) => {
    try {
      const responseAccept = await patchUpdateDriverRegistrationOrderStatusAPI(
        selectedRowDataToAccept?._id,
        X_ACTION_DRIVER_REGISTRATION_ORDER_STATUS.ACCEPT
      );
      if (responseAccept?.data?.status === REGISTRATION_DRIVER_STATUS.ACCEPTED) {
        const rowIndexAccept = driverRegistration.findIndex(
          (item) => item._id === selectedRowDataToAccept?._id
        );
        driverRegistration[rowIndexAccept].status = REGISTRATION_DRIVER_STATUS.ACCEPTED;
        const newArrDriverRegistrationAccept = [...driverRegistration];
        setDriverRegistration(newArrDriverRegistrationAccept);
        handleMessage('success', 'Successfully');
        getDriverRegistrationOrders(null, false);
      }
    } catch (error) {
      const message = error?.response?.data?.message;
      handleMessage('[changeStatusToAccept] error', message);
    }
  };

  const changeStatusToReject = async (selectedRowDataReject) => {
    try {
      const responseReject = await patchUpdateDriverRegistrationOrderStatusAPI(
        selectedRowDataReject?._id,
        X_ACTION_DRIVER_REGISTRATION_ORDER_STATUS.REJECT
      );
      if (responseReject?.data?.status === REGISTRATION_DRIVER_STATUS.REJECTED) {
        const rowIndexReject = driverRegistration.findIndex(
          (item) => item._id === selectedRowDataReject?._id
        );
        driverRegistration[rowIndexReject].status = REGISTRATION_DRIVER_STATUS.REJECTED;
        const newArrDriverRegistrationReject = [...driverRegistration];
        setDriverRegistration(newArrDriverRegistrationReject);

        getDriverRegistrationOrders(null, false);
      }
    } catch (error) {
      const message = error?.response?.data?.message;
      handleMessage('error', message);
    }
  };

  const getDriverRegistrationOrders = async (_filter = {}, enableLoading = true) => {
    const { status, name, phone, id_number, company_id, date } = filters;

    const newFilters = {
      filters: {
        name: name ?? '',
        phone: phone ?? '',
        id_number: id_number ?? '',
        company_id: company_id?.id ?? company_id ?? '',
        status: status?.value ?? '',
        date: [date?.[0] ?? null, date?.[1] ?? null],
      },
      limit: limit,
      page: currentPage,
    };

    const finalizeFilter = isEmpty(_filter) ? newFilters : _filter;

    if (isLoading) return;

    setIsLoading(enableLoading);

    const newQueryUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${buildQueryString({
        ...finalizeFilter,
      })}`;

    window.history.pushState({ path: newQueryUrl }, '', newQueryUrl);

    try {
      const {
        data: { total, results },
      } = await axios.get(`${config.API_URL.DRIVER_REGISTRATION.GET_LIST}`, {
        params: {
          ...finalizeFilter,
        },
        headers: config.headers,
      });

      setDriverRegistration(results);
      setTotalRecords(total);
    } catch (error) {
      console.log('Error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetDataByFilter = async () => {
    setCurrentPage(1);
    const { name, status, phone, date, company_id, id_number } = filters;

    const newFilterOptions = {
      filters: {
        name: name ?? '',
        phone: phone ?? '',
        id_number: id_number ?? '',
        company_id: company_id?.id ?? '',
        status: status?.value ?? '',
        date: [date?.[0] ?? null, date?.[1] ?? null],
      },
      limit: limit,
      page: currentPage,
    };

    getDriverRegistrationOrders(newFilterOptions);
  };

  const getAllCompanies = async () => {
    try {
      const { data } = await axios.get(`${config.API_URL.DELIVERY_COMPANY.LIST}`, {
        headers: config.headers,
      });

      if (data && data?.success) {
        const { data: response } = data;

        const terraformData = response?.map((company) => {
          return {
            label: company.name,
            value: company.name,
            id: company._id,
          };
        });

        setCompanies(terraformData);
      }
    } catch (error) {
      console.log('Error', error);
    }
  };

  useEventEnter(handleGetDataByFilter);

  useEffect(() => {
    if (!parsingQuery) {
      getDriverRegistrationOrders();
    }

    getAllCompanies();
  }, [limit, newRender, parsingQuery]);

  useEffect(() => {
    const { date } = filters;

    if (date) {
      const dateValue = [date[0], date[1]];

      setDateRange(dateValue);
    } else {
      setDateRange([null, null]);
    }
  }, [filters]);

  useEffect(() => {
    if (queryString?.filters?.company_id) {
      const customizeCompany = companies.find(
        (company) => company.id === queryString?.filters?.company_id
      );

      setFilters({ ...filters, company_id: customizeCompany });
    }
  }, [companies, queryString?.filters?.company_id]);

  useEffect(() => {
    if (queryString.page) {
      setCurrentPage(+queryString.page);
    }

    if (queryString.filters) {
      let filter = {
        ...queryString.filters,
        name: queryString.filters.name,
        phone: queryString.filters.phone,
        id_number: queryString.filters.id_number,
        status:
          queryString.filters.status.length > 0
            ? {
                label: queryString.filters.status,
                value: queryString.filters.status,
              }
            : null,
      };

      if (filter.date) {
        filter.date = [
          filter.date?.[0].length > 0 ? filter.date?.[0] : null,
          filter.date?.[1].length > 0 ? filter.date?.[1] : null,
        ];
      }

      setFilters(filter);
    }

    if (queryString.limit > 0) {
      setLimit(+queryString.limit);
    }

    setParsingQuery(false);
  }, []);

  return (
    <div className="rtl">
      <div className="px-8 py-5 flex items-center gap-4 mb-[35px] flex-wrap">
        <div>
          <SearchInput
            disabled={isLoading}
            placeholder={t('name')}
            handleSearch={(value) => handleChangeFilter(value, 'name')}
            makeRerender={filters}
            value={filters?.name}
          />
        </div>

        <div>
          <SearchInput
            disabled={isLoading}
            placeholder={t('phone')}
            handleSearch={(value) => handleChangeFilter(value, 'phone')}
            makeRerender={filters}
            value={filters?.phone}
          />
        </div>
        <div>
          <SearchInput
            disabled={isLoading}
            placeholder={t('id_number')}
            handleSearch={(value) => handleChangeFilter(value, 'id_number')}
            makeRerender={filters}
            value={filters?.id_number}
          />
        </div>

        <div className="flex">
          <CustomSelectReact
            isClearable
            isDisabled={isLoading}
            placeholder={t('company')}
            onChange={(value) => handleChangeFilter(value, 'company_id')}
            className="filter-select min-w-[200px]"
            options={companies}
            value={filters.company_id}
          />
        </div>

        <div>
          <CustomSelectReact
            isClearable
            isDisabled={isLoading}
            placeholder={t('status')}
            className="checkbox-order w-[200px] filter-select"
            options={filterStatuses}
            value={filters?.status}
            onChange={(value) => handleChangeFilter(value, 'status')}
          />
        </div>

        <div className="input-select-wrapper" style={{ direction: 'ltr' }}>
          <DateTimeRangePicker
            disabled={isLoading}
            className="input-select-input-wrapper !border-0 w-[200px]"
            value={dateRange}
            onChange={handleChangeDateOnFilter}
            disableClock
            format={'y-MM-dd'}
          />
        </div>

        <div className="relative w-full mb-4">
          <div className="absolute right-0 ">
            <LoadingButton
              onClick={handleGetDataByFilter}
              label={t('Apply Filter')}
              loading={isLoading}
            />
          </div>
        </div>
      </div>

      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        highlightOnHover
        columns={columns}
        data={driverRegistration}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationPerPage={+limit}
        paginationRowsPerPageOptions={[10, 20, 40, 60]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(+currentRowsPerPage);
          setCurrentPage(1);
        }}
        progressPending={isLoading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationTotalRows={totalRecords}
        paginationServer
        onChangePage={handleChangePage}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        selectableRows
        onRowExpandToggled={(toggleState, row) => [
          toggleState
            ? setSelectedId((p) => [...p, row.id])
            : setSelectedId(selectedId.filter((i) => i !== row.id)),
        ]}
        conditionalRowStyles={conditionalRowStyles}
        // onRowClicked={(row) => {
        //   setSelectedLog(row);
        // }}
        paginationDefaultPage={currentPage}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              background: '#F9F9F9',
            },
          },
          rows: {
            style: {
              cursor: 'pointer',
              fontSize: 12,
            },
          },
          header: {
            style: {
              minHeight: 0,
            },
          },
        }}
        expandableRows={true}
        expandableRowsComponent={
          <ActionButtons
            handleOpenModal={handleOpenModal}
            changeStatusToAccept={changeStatusToAccept}
            changeStatusToReject={changeStatusToReject}
          />
        }
        expandOnRowClicked
      />

      <DriverRegistrationDetailsModal
        logId={selectedLog?._id}
        isVisible={isModalVisible}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default DriverRegistration;
