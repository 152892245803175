import axios from 'axios';
import { config } from '../../../config/Config';

const { useState, useEffect } = require('react');
export function daysInMonth(month, year) {
  return new Date(+year, +month, 0).getDate();
}
const useLogicDashBoard = (typeData) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const [date, setDate] = useState({
    month: currentMonth + 1,
    year: currentYear,
  });
  const [selectedOption, setSelectedOption] = useState('day');
  const [data, setData] = useState({});
  const onChangeDate = (key, value) => {
    setDate({ ...date, [key]: value });
  };

  const getData = async () => {
    const response = await axios.get(
      typeData === 'driver' ? config.DASHBOARD.DRIVER_ANALYSIS : config.DASHBOARD.ORDER_ANALYSIS,
      {
        params: {
          type: selectedOption,
          ...date,
        },
        headers: config.headers,
      }
    );

    setData(response.data);
  };
  useEffect(() => {
    getData();
  }, [selectedOption, date]);

  return { date, onChangeDate, selectedOption, setSelectedOption, data };
};
export default useLogicDashBoard;
