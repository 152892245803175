import { useTranslation } from 'react-i18next';
import { Start, StartOutLine } from '../../constants/Icon';
import { FORMAT_DATE, parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { useDate } from '../../hooks/useDate';
import { renderFields, renderRatingColor } from '../../constants/constants';
import { buildQueryString } from '../../helper/utils';
const { useMemo } = require('react');

const useColumn = () => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        name: t('id_number'),
        selector: 'id_number',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.id_number),
      },
      {
        name: t('company'),
        selector: 'company',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.company),
      },
      {
        name: t('driver_name'),
        selector: 'driver_name',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.driver_name),
      },

      {
        name: t('phone'),
        selector: 'phone',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.phone),
      },

      {
        name: t('rating'),
        selector: 'rating_star',
        sortable: true,
        minWidth: '140px',
        cell: (row) => (
          <div className="flex flex-row items-center justify-center">
            <span className="w-6 h-6 mx-1">
              {row?.rating_star ? <Start color={renderRatingColor(row.rating_star)} /> : '...'}
            </span>

            {row?.rating_star !== 0 ? (
              <span className="ltr">{renderFields(row?.rating_star?.toFixed(1))}</span>
            ) : (
              ''
            )}
          </div>
        ),
      },
    ],
    []
  );
  return { columns };
};
export const renderStar = (star) => {
  return (
    <div className="flex flex-row items-center justify-center">
      <span className="w-6 h-6 mx-1">
        {star ? <Start color={renderRatingColor(star)} /> : <StartOutLine color="#D4E9D8" />}
      </span>

      {star && <span className="ltr">{renderFields(star?.toFixed(1))}</span>}
    </div>
  );
};

const useColumnRatingOrder = () => {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();

  const columns = useMemo(
    () => [
      {
        name: t('order_number'),
        selector: 'order_number',
        minWidth: '200px',
        sortable: true,
        cell: (row) => renderFields(row?.order_number || ''),
      },
      {
        name: t('id_number'),
        minWidth: '200px',
        sortable: true,
        cell: (row) => renderFields(row?.id_number || ''),
      },
      {
        name: t('merchant_id'),
        selector: 'merchant_id',
        minWidth: '200px',
        sortable: true,
        cell: (row) => renderFields(row?.merchant_id || ''),
      },
      {
        name: t('shukah.customer.name'),
        selector: 'customer_name',
        minWidth: '200px',
        cell: (row) => renderFields(row?.customer_name || ''),
      },
      {
        name: t('customer_number'),
        selector: 'contact_phone',
        minWidth: '200px',
        cell: (row) => renderFields(row?.contact_phone || ''),
      },
      {
        name: t('Store Feedback'),
        selector: 'store_feedback',
        minWidth: '200px',
        cell: (row) => renderFields(row?.store_feedback || ''),
      },
      {
        name: t('store_star'),
        selector: 'store_star',
        minWidth: '200px',
        sortable: true,
        cell: (row) => renderStar(row?.store_star),
      },
      {
        name: t('restaurant_name'),
        selector: 'storeName',
        minWidth: '200px',
        cell: (row) => renderFields(row?.store?.store_name || ''),
      },
      {
        name: t('restaurant_number'),
        selector: 'storePhone',
        minWidth: '200px',
        cell: (row) => renderFields(row?.store?.phone || ''),
      },
      {
        name: t('Driver Feedback'),
        selector: 'driver_feedback',
        minWidth: '200px',
        cell: (row) => renderFields(row?.driver_feedback || ''),
      },
      {
        name: t('driver_star'),
        selector: 'driver_star',
        minWidth: '200px',
        sortable: true,
        cell: (row) => renderStar(row?.driver_star),
      },
      {
        name: t('driver_name'),
        selector: 'driver_name',
        minWidth: '200px',
        cell: (row) => renderFields(row?.driver?.driver_name || ''),
      },

      {
        name: t('shukah.driver.phone'),
        selector: 'phone',
        minWidth: '200px',
        cell: (row) => renderFields(row?.driver?.phone || ''),
      },

      {
        name: t('date'),
        selector: (row) =>
          parseDateWithGmt({
            date: row.created_at,
            timezone: currentTimezone?.timezone,
          }),
        sortable: true,
        wrap: true,
        minWidth: '120px',
      },
      {
        name: t('date_time'),
        selector: (row) =>
          parseDateWithGmt({
            date: row.created_at,
            timezone: currentTimezone?.timezone,
            format: FORMAT_DATE.hms,
          }),
        sortable: true,
        wrap: true,
        minWidth: '140px',
      },
    ],
    []
  );
  return { columns };
};

const useColumnRatingStore = () => {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();

  const columns = useMemo(
    () => [
      {
        name: t('restaurant_name'),
        selector: 'store_name',
        minWidth: '200px',
        cell: (row) => renderFields(row?.store_name || ''),
      },

      {
        name: t('city'),
        selector: 'city',
        minWidth: '200px',
        cell: (row) => renderFields(row?.city || ''),
      },

      {
        name: t('phone'),
        selector: 'phone',
        minWidth: '200px',
        cell: (row) => renderFields(row?.phone || ''),
      },

      {
        name: t('merchant_id'),
        selector: 'merchant_id',
        minWidth: '200px',
        cell: (row) => renderFields(row?.merchant_id || ''),
      },

      {
        name: t('rating'),
        selector: 'rating_star',
        minWidth: '200px',
        sortable: true,
        cell: (row) => renderStar(row?.rating_star),
      },
    ],
    []
  );
  return { columns };
};

const newUrl = (newFilters, star, type) =>
  window.location.protocol +
  '//' +
  window.location.host +
  window.location.pathname +
  `?${buildQueryString({ ...newFilters, star, type: type })}`;

const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: 12,
      background: '#F9F9F9',
    },
  },
  rows: {
    style: {
      cursor: 'pointer',
      fontSize: 12,
    },
  },
  header: {
    style: {
      minHeight: 0,
    },
  },
  subHeader: {
    style: {
      minHeight: 0,
    },
  },
};

export { useColumn, useColumnRatingOrder, useColumnRatingStore, newUrl, customStyles };
