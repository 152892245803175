import axios from 'axios';
import { uniqueId } from 'lodash';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { config, SETTING_KEY } from '../../../config/Config';
import { DataContext } from '../../../context/DataContext';
import { createDate } from '../../../helper/date/createDateWithTimezone';
import {
  CompareGmtAndCalcTimestamp,
  GetTimezone,
} from '../../../helper/date/getLocalGmtWithTimezone';
import { buildQueryString, parseQueryString } from '../../../helper/utils';

import { TRANSACTION_TYPE } from '../constants';
import useSchema from '../NewSchema';
import FIlterComponents from './FilterComponentNewTransaction/FIlterComponents';
import TableTransaction from './TableTransaction';

const Transactions = ({ transactionVer }) => {
  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(null);
  const [limit, setLimit] = useState(100);
  const [total, setTotal] = useState(0);
  const [transactionLog, setTransactionLog] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [parsingQuery, setParsingQuery] = useState(true);
  const [setting, setSetting] = useState(null);
  const { currentTimezone } = useContext(DataContext);
  const [newRender, setNewRender] = useState(uniqueId());
  const [newRenderColumns, setNewRenderColumns] = useState(uniqueId());

  const onSetFilters = (newFilter) => {
    setFilters(newFilter);
  };
  const getSetting = async () => {
    const resp = await axios.get(config.API_URL.SETTING.GET, {
      params: {
        key: SETTING_KEY.FEE_TAX,
      },
      headers: config.headers,
    });
    const { data } = resp;
    const { setting } = data;
    setSetting(setting);
  };
  useEffect(() => {
    getSetting();
  }, []);

  const [isExporting, setIsExporting] = useState(false);
  const toggleModal = (visible) => {
    setModalVisible(visible);
  };

  const handleToggleModal = (id) => {
    toggleModal(true);

    setSelectedTransaction(id);
  };

  const columns = useSchema(transactionVer, filters?.type, setting?.tax, newRenderColumns);

  useEffect(() => {
    if (!parsingQuery) {
      getTransactions();
    }
  }, [limit, transactionVer, parsingQuery, newRender]);

  useEffect(() => {
    if (queryString.page) {
      setCurrentPage(+queryString.page);
    }
    if (queryString.filters) {
      const filter = { ...queryString.filters };
      if (filter.fromDate) {
        filter.fromDate = +filter.fromDate;
      }
      if (filter.toDate) {
        filter.toDate = +filter.toDate;
      }
      setFilters(filter);
    }
    if (queryString.limit > 0) {
      setLimit(+queryString.limit);
    }
    setParsingQuery(false);
  }, []);

  const getTransactions = async (_filter = {}) => {
    const newFilters = {
      filters: {
        ...filters,
      },
      sort: -1,
      limit: limit,
      page: currentPage,
      version: transactionVer,
    };
    const filter = isEmpty(_filter) ? newFilters : _filter;
    try {
      if (modalVisible) {
        setModalVisible(false);
      }
      if (loading) return;
      setLoading(true);

      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?${buildQueryString({ ...filter })}`;

      window.history.pushState({ path: newurl }, '', newurl);
      const response = await axios.get(`${config.API_URL.TRANSACTIONS.GET_ALL_TRANSACTIONS}list`, {
        params: {
          ...filter,
        },
        headers: config.headers,
      });

      const { data = {}, status, total } = response?.data;

      if (status === 200) {
        setTotal(total);
        await setTransactionLog(data);
      }
      setNewRenderColumns(uniqueId());
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handlerGetTransactions = useCallback(
    debounce((filters) => getTransactions(filters), 500),
    [filters]
  );

  const onGetTransactions = async () => {
    await setCurrentPage(1);
    const { settingGmt, localGmt } = await GetTimezone();

    const newFilters = {
      filters: {
        ...filters,
        fromDate: filters.fromDate
          ? CompareGmtAndCalcTimestamp(
              settingGmt,
              localGmt,
              new Date(createDate(+filters?.fromDate, currentTimezone)).getTime()
            )
          : null,
        toDate: filters.toDate
          ? CompareGmtAndCalcTimestamp(
              settingGmt,
              localGmt,
              new Date(createDate(+filters?.toDate, currentTimezone)).getTime()
            )
          : null,
      },
      sort: -1,
      limit: limit,
      page: 1,
      version: transactionVer,
    };
    handlerGetTransactions(newFilters);
  };

  const handlePageChange = async (page) => {
    await setCurrentPage(page);
    setNewRender(uniqueId());
  };

  const handleFilters = (value, key, type) => {
    if (type === 'DropdownInput') {
      onSetFilters((prevState) => {
        if (key !== TRANSACTION_TYPE.TOP_UP && prevState?.statusTransaction) {
          delete prevState.statusTransaction;
        }
        return {
          ...prevState,
          [key]: key === 'status' ? [value] : value.value,
        };
      });
    } else {
      onSetFilters((prevState) => {
        return {
          ...prevState,
          [key]: key === 'status' ? [key] : value,
        };
      });
    }
  };

  const handleDateFilter = (value) => {
    if (value) {
      const newFilter = {
        ...filters,
        fromDate: new Date(value[0]).getTime(),
        toDate: new Date(value[1]).getTime(),
      };
      onSetFilters(newFilter);
    } else {
      const newFilter = {
        ...filters,
        fromDate: null,
        toDate: null,
      };
      onSetFilters(newFilter);
      setDateRange([null, null]);
    }
  };

  useEffect(() => {
    if (filters?.fromDate && filters?.toDate) {
      const dateValue = [new Date(+filters?.fromDate), new Date(+filters?.toDate)];
      setDateRange(dateValue);
    } else {
      setDateRange([null, null]);
    }
  }, [filters?.fromDate, filters?.toDate]);

  const exportExcel = () => {
    setIsExporting(true);
    const newFilters = {
      filters: {
        ...filters,
        fromDate: (filters.fromDate = new Date(
          createDate(+filters?.fromDate, currentTimezone)
        ).getTime()),
        toDate: (filters.toDate = new Date(
          createDate(+filters?.toDate, currentTimezone)
        ).getTime()),
      },
      sort: -1,
      limit: limit,
      page: currentPage,
      version: transactionVer,
    };

    try {
      axios
        .post(`${config.API_URL.TRANSACTIONS.EXPORT_EXCEL}`, null, {
          params: newFilters,
          headers: {
            ...config.headers,
            'Content-Disposition': 'attachment; filename=template.xlsx',
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `order-${moment().locale('en').format('MM-DD-YYYY')}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (errors) {
    } finally {
      setIsExporting(false);
    }
  };

  const conditionalRowStylesTable = useMemo(() => {
    return filters?.type === TRANSACTION_TYPE.TOP_UP ? conditionalRowStyles : [];
  }, [newRenderColumns]);

  return (
    <div>
      <button onClick={exportExcel} className="refresh-button direction-r mx-2 mt-4">
        {isExporting ? '... ' : ''}
        {t('export_excel_all_by_filter')}
      </button>
      <FIlterComponents
        dateRange={dateRange}
        filters={filters}
        handleFilters={handleFilters}
        handleDateFilter={handleDateFilter}
        loading={loading}
        onGetTransactions={onGetTransactions}
      />
      <TableTransaction
        columns={columns}
        conditionalRowStylesTable={conditionalRowStylesTable}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handleToggleModal={handleToggleModal}
        limit={limit}
        loading={loading}
        modalVisible={modalVisible}
        selectedId={selectedId}
        selectedTransaction={selectedTransaction}
        setCurrentPage={setCurrentPage}
        setLimit={setLimit}
        setSelectedId={setSelectedId}
        setting={setting}
        toggleModal={toggleModal}
        total={total}
        transactionLog={transactionLog}
      />
      {/* <div className="flex flex-row overflow-hidden  min-h-[calc(100vh-320px)] ">
        <div
          className={`${
            modalVisible ? 'translate-x-[500px] pr-[500px]' : 'translate-x-[0px]'
          } flex-1 duration-300	 ease-in-out w-full overflow-scroll`}
        >
          <DataTable
            noHeader
            noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
            highlightOnHover
            columns={columns}
            data={transactionLog}
            fixedHeader
            pagination
            contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
            fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
            paginationPerPage={limit}
            paginationRowsPerPageOptions={[100, 200, 400, 600]}
            onChangeRowsPerPage={(currentRowsPerPage) => {
              setLimit(+currentRowsPerPage);
              setCurrentPage(1);
            }}
            progressPending={loading}
            progressComponent={<div className="wait">{t('please_wait')}</div>}
            paginationTotalRows={total}
            paginationServer
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: t('total_record'),
              rangeSeparatorText: t('from'),
              noRowsPerPage: false,
              selectAllRowsItem: false,
            }}
            selectableRows
            onRowExpandToggled={(toggleState, row) => [
              toggleState
                ? setSelectedId((p) => [...p, row._id])
                : setSelectedId(selectedId.filter((i) => i !== row._id)),
            ]}
            onRowClicked={(row) => handleToggleModal(row?._id)}
            paginationDefaultPage={currentPage}
            selectableRowsVisibleOnly
            persistTableHead
            selectableRowsHighlight
            direction={'rtl'}
            conditionalRowStyles={
              filters?.type === TRANSACTION_TYPE.TOP_UP ? conditionalRowStyles : {}
            }
            customStyles={{
              headCells: {
                style: {
                  fontWeight: 'bold',
                  fontSize: 12,
                  background: '#F9F9F9',
                  textAlign: 'right',
                },
              },
              rows: {
                style: {
                  cursor: 'pointer',
                  fontSize: 12,
                },
              },
            }}
          />
        </div>
        <div className="relative">
          <TransactionDetail
            id={selectedTransaction}
            visible={modalVisible}
            toggleModal={toggleModal}
            tax={setting?.tax}
          />
        </div>
      </div> */}
    </div>
  );
};

export default Transactions;

const conditionalRowStyles = [
  {
    when: (row) => !row?.status || row?.status === 'SUCCESS',
    style: {
      backgroundColor: '#D4E9D8',
    },
  },
  {
    when: (row) => row?.status === 'PENDING',
    style: {
      backgroundColor: '#f3e4acab',
    },
  },
  {
    when: (row) => row?.status === 'FAILURE',
    style: {
      backgroundColor: '#F7D7DE',
    },
  },
];
