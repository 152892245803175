/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../css/navbar.css';
import { useTranslation } from 'react-i18next';
import BurgerButton from './BurgerButton';
import { useWindowSize } from '../constants/customHooks';
import {
  AnalyticIcon,
  DeliveryCompanyIcon,
  DriverIcon,
  IconStorage,
  IconSubscription,
  LogIcon,
  OpsIcon,
  OrderIcon,
  OtpIcon,
  PricingIcon,
  RattingIcon,
  RecoveryIcon,
  SettingIcon,
} from '../constants/Icon';
import { DataContext } from '../context/DataContext';

const driverList = [
  {
    id: 21,
    name: 'drivers',
    path: '/drivers',
    state: 'drivers',
    superAdminOnly: false,
  },
  {
    id: 22,
    name: 'driver_registration_order.driver_registration_order',
    path: '/driver-registration',
    state: 'driver-registration',
    superAdminOnly: false,
  },
  {
    id: 23,
    name: 'new_driver',
    path: '/drivers/new',
    state: 'new_driver',
    superAdminOnly: false,
  },
  {
    id: 24,
    name: 'transactions',
    path: '/transactions',
    state: 'transactions',
    superAdminOnly: false,
  },
  {
    id: 25,
    name: 'location_drivers',
    path: '/map-tracking',
    state: 'map-tracking',
    superAdminOnly: false,
  },
  {
    id: 26,
    name: 'Stores registration',
    path: '/stores-registration',
    state: 'stores-registration',
    superAdminOnly: false,
  },
];

const logMenu = [
  {
    id: 41,
    name: 'shukah.title',
    path: '/shukah-logs',
    state: 'shukah-logs',
    superAdminOnly: false,
  },
  {
    id: 42,
    name: 'odoo.title',
    path: '/odoo-logs',
    state: 'odoo',
    superAdminOnly: false,
  },
  {
    id: 43,
    name: 'hyperlog.title',
    path: '/hyperpay-logs',
    state: 'hyperpay',
    superAdminOnly: false,
  },
  {
    id: 44,
    name: 'citc_logs',
    path: '/citc-logs',
    state: 'citc',
    superAdminOnly: false,
  },
  {
    id: 45,
    name: 'http_logs',
    path: '/http-logs',
    state: 'http-logs',
    superAdminOnly: false,
  },
  {
    id: 46,
    name: 'payfort_transaction_logs',
    path: '/payfort-transaction-logs',
    state: 'payfort-transaction-logs',
    superAdminOnly: false,
  },
  {
    id: 47,
    name: 'recovery_file',
    path: '/syncFile',
    state: 'syncFile',
    superAdminOnly: false,
    icon: <RecoveryIcon />,
  },
  {
    id: 47,
    name: 'log_export',
    path: '/log-export',
    state: 'log-export',
    superAdminOnly: false,
  },
];

const settingList = [
  {
    id: 111,
    name: 'General settings',
    path: '/settings',
    state: 'settings',
    superAdminOnly: false,
  },
  {
    id: 112,
    name: 'Orders distribution',
    path: '/orders_distribution',
    state: 'orders_distribution',
    superAdminOnly: false,
  },
];

const menuList = [
  {
    id: 1,
    name: 'orders',
    path: '/orders',
    state: 'orders',
    superAdminOnly: false,
    icon: <OrderIcon />,
  },
  {
    name: 'driver_management',
    data: driverList,
    allowExpand: true,
    icon: <DriverIcon />,
  },
  {
    id: 3,
    name: 'delivery_company.title',
    path: '/delivery-company',
    state: 'delivery-company',
    superAdminOnly: false,
    icon: <DeliveryCompanyIcon />,
  },
  {
    name: 'developer_tools',
    data: logMenu,
    allowExpand: true,
    icon: <LogIcon />,
  },
  {
    id: 5,
    name: 'ops_management.title',
    path: '/ops-management',
    state: 'ops-management',
    superAdminOnly: true,
    icon: <OpsIcon />,
  },
  {
    id: 6,
    name: 'pricing.title',
    path: '/pricing',
    state: 'pricing',
    superAdminOnly: false,
    icon: <PricingIcon />,
  },
  {
    id: 7,
    name: 'otp_code',
    path: '/otp',
    state: 'otp',
    superAdminOnly: false,
    icon: <OtpIcon />,
  },
  {
    id: 8,
    name: 'rating',
    path: '/rating',
    state: 'rating',
    superAdminOnly: false,
    icon: <RattingIcon />,
  },
  {
    id: 9,
    name: 'dashboard',
    path: '/dashboard',
    state: 'dashboard',
    superAdminOnly: false,
    icon: <AnalyticIcon />,
  },
  {
    id: 10,
    name: 'export_orders',
    path: '/export-orders',
    state: 'export-orders',
    superAdminOnly: false,
    icon: <IconStorage />,
  },
  {
    name: 'settings',
    data: settingList,
    allowExpand: true,
    icon: <SettingIcon />,
  },
  {
    id: 12,
    name: 'subscriptions',
    path: '/subscriptions',
    state: 'subscriptions',
    superAdminOnly: false,
    icon: <IconSubscription />,
  },
];

export default function Navbar({ isSuperAdmin }) {
  const location = useLocation();
  const { t } = useTranslation();
  const path = location.pathname;
  const [active, setActive] = useState(null);
  const [showOpsManagement, setShowOpsManagement] = useState(false);
  const { isCurrentDeliveryStatus, handleChangeCurrentDeliveryStatus } = useContext(DataContext);

  const logout = () => {
    localStorage.removeItem('accessToken');
    window.location.href = '/login';
  };

  useEffect(() => {
    setShowOpsManagement(isSuperAdmin);
  }, [isSuperAdmin]);

  const { width } = useWindowSize();

  const renderMenuList = () => {
    return menuList.map((menu) => {
      if (!menu.superAdminOnly) {
        return (
          <li className="min-w-max" onClick={() => setActive(menu.state)} key={menu.id}>
            <Link
              className={`list ${active === menu.state || path === menu.path ? 'active' : ''}`}
              to={{
                pathname: menu.path,
                state: {
                  load: true,
                },
              }}
            >
              <span>{t(menu.name)}</span>
            </Link>
          </li>
        );
      }
      if (showOpsManagement)
        return (
          <li className="min-w-max" onClick={() => setActive(menu.state)} key={menu.id}>
            <Link
              className={`list ${active === menu.state || path === menu.path ? 'active' : ''}`}
              to={{
                pathname: menu.path,
                state: {
                  load: true,
                },
              }}
            >
              <span>{t(menu.name)}</span>
            </Link>
          </li>
        );
      return null;
    });
  };

  return (
    <div>
      <nav className="navbar w-full bg-red">
        {width > 1536 && menuList.length < 10 ? (
          <ul className="menu-list hidden 2xl:!flex">
            {renderMenuList()}
            <li className="min-w-max" onClick={logout}>
              <span className="list">{t('logout')}</span>
            </li>
          </ul>
        ) : (
          <BurgerButton
            active={active}
            path={path}
            setActive={setActive}
            t={t}
            showOpsManagement={showOpsManagement}
            logout={logout}
            menuList={menuList}
          />
        )}
        {showOpsManagement && (
          <div
            className={`activation ${!isCurrentDeliveryStatus && 'disableExpress'}`}
            onClick={handleChangeCurrentDeliveryStatus}
          >
            {isCurrentDeliveryStatus ? t('active') : t('inactive')}
          </div>
        )}
      </nav>
    </div>
  );
}
