import { TRANSACTION_TYPE } from '../../components/Transactions/constants';

export const renderAddedValue = (transactionType, transactionValue, tax) => {
  switch (transactionType) {
    case TRANSACTION_TYPE.FEE:
      return ((transactionValue?.delivery_cost / (+tax + 1)) * +tax)?.toFixed(2) ?? '0.00';
    case TRANSACTION_TYPE.CASH_BACK:
      return ((transactionValue?.amount / (+tax + 1)) * +tax)?.toFixed(2) ?? '0.00';
    default:
      return transactionValue?.tax?.toFixed(2);
  }
};

export const renderBalanceValue = (
  transactionBalanceMain,
  transactionBalanceSub,
  type = 'previous' | 'remaining'
) => {
  let previousBalance = 0;
  let remainingBalance = 0;
  if (type === 'previous') {
    previousBalance = transactionBalanceMain?.previous_balance.toFixed(2) || '--';
    remainingBalance = transactionBalanceSub?.previous_balance.toFixed(2) || '--';
  } else {
    previousBalance = transactionBalanceMain?.remaining_balance.toFixed(2) || '--';
    remainingBalance = transactionBalanceSub?.remaining_balance.toFixed(2) || '--';
  }
  return { previousBalance, remainingBalance };
};

export const renderGiftAmountValue = (transactionBalanceSub) => {
  if (transactionBalanceSub?.remaining_balance) {
    return (
      transactionBalanceSub?.remaining_balance - transactionBalanceSub?.previous_balance
    )?.toFixed(2);
  } else {
    return '0.00';
  }
};

export const prepareDataCreateBy = (row) => {
  const id = row?.created_by?._id ?? '';
  const name = row?.created_by?.name ?? '';
  const role = row?.created_by?.role ?? '';
  return {
    id,
    name,
    role,
  };
};
export const renderCreateBy = ({ role, name, id }) => {
  if (role && name) {
    return `${role} - ${name}`;
  }
  if (!id) {
    return role ?? '';
  }
};
