import React from 'react';
import { CarOutLine, Gift } from '../../../../constants/Icon';

export default function Card({ label, value }) {
  return (
    <div className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
      <div className="relative flex flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border px-2 py-2 ">
        <div className="flex-auto p-4">
          <div className="flex flex-row -mx-3  justify-between">
            <div className="flex-none w-2/3 max-w-full px-3">
              <div>
                <p className="mb-0 font-sans font-semibold leading-normal text-gray-500 text-xl pb-2">
                  {/* Today's Money */}
                  {label}
                </p>
                <h5 className="mb-0 font-bold text-3xl h-[24px]">
                  {/* $53,000 */}
                  {'$ '}
                  {value?.toFixed(2) || '-/-'}
                  {/* <span className="leading-normal font-weight-bolder text-lime-500 text-lg px-2">
                    +55%
                  </span> */}
                </h5>
              </div>
            </div>
            <div className="px-3 text-right basis-1/3 flex justify-center items-center">
              <div className="flex justify-center items-center w-16 h-16 text-center rounded-lg bg-gradient-to-tl from-purple-700 to-pink-500">
                <div className="w-10 h-10 ">
                  <Gift />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
