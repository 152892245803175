import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LiveOrders from './components/Orders/LiveOrders';
// import Orders from './components/Orders/Orders';
import Orders from './components/Orders/NewOrder';

import Receipt from './components/Receipt/Receipt';
// import Drivers from './components/Drivers/Drivers';
import Drivers from './components/DriversNew/DriversNew';

import newDriver from './components/Drivers/NewDriver';
import editDriver from './components/Drivers/EditDriver';
import DataContextProvider from './context/DataContext';
import Transactions from './components/Transactions';
import Offers from './components/Offers/Offers';
import Login from './components/Login';
import RouteLayout from './components/Route';
import LayoutPrivate from './components/LayoutPrivate';
// import LogsCitc from './components/LogsCitc';
import LogsCitc from './components/LogsCitcNew';

import DetailLogCitc from './components/LogsCitc/DetailLogCitc';
import LocationDrivers from './components/LocationDrivers';
import TabSetting from './components/Setting';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './Languages/english.json';
import arabic from './Languages/arabic.json';
import Policy from './components/Policy';
import CitcDriver from './components/Drivers/citcDriver';
// import HttpLogs from './components/HttpLogs';
import HttpLogs from './components/HttpLogsNew';

import DetailLog from './components/HttpLogs/DetailLog';
import DriverTransactions from './components/Drivers/Transaction/Transactions';
import DriverOrders from './components/Drivers/Orders/Orders';
import OpsManagement from './components/OpsManagement';
import NewOperator from './components/OpsManagement/NewOperator';
import EditOperator from './components/OpsManagement/EditOperator';
import Pricing from './components/Pricing';
import NewPlan from './components/Pricing/NewPlan';
import EditPlan from './components/Pricing/EditPlan';
// import HyperpayLog from './components/HyperpayLog';
import HyperpayLog from './components/HyperpayLogNew';

// import OdooLog from './components/OdooLog';
import OdooLog from './components/OdooLogNew';

// import ShukahLog from './components/ShukahLog';
import ShukahLog from './components/ShukahLogNew';

import DeliveryCompanyManagement from './components/DeliveryCompanyManagement';
import NewCompany from './components/DeliveryCompanyManagement/NewCompany';
import EditCompany from './components/DeliveryCompanyManagement/EditCompany';
import CompanyDetail from './components/DeliveryCompanyManagement/CompanyDetail';
import NewPartner from './components/DeliveryCompanyManagement/Partner/NewPartner';
import EditPartner from './components/DeliveryCompanyManagement/Partner/EditPartner';
import MapTracking from './components/MapTracking';
import DriversFakerOrder from './components/Drivers/DriversFakerOrder';
// import OrdersDiscount from './components/OrdersDiscount/OrdersDiscount';
import OrdersDiscount from './components/OrdersDiscount/NewOrderDiscount/NewOrderDiscount';

import LocatePolicy from './components/Policy/locate';
import Otp from './components/Otp';
import Rating from './components/Rating';
import RatingDriverDetail from './components/RatingDriverDetail';
import RatingStoreDetail from './components/RatingStoreDetail';
import Dashboard from './components/Dashboard';
import SyncFile from './components/UpFile/SyncFile';
import TransactionLogs from './components/TransactionLogsByPayfort/TransactionLogs';
import DriverRegistration from './components/DriverRegistration';
import StoresRegistration from './components/StoresRegistration';
import LogExport from './components/LogExport';
import ExportOrders from './components/ExportOrders';
import Subscriptions from './components/Subscriptions';
import SubscriptionDrivers from './components/SubscriptionDrivers';
import OrdersDistributionMechanism from './components/OrdersDistributionMechanism';
import CreateNote from './components/Drivers/CreateNote';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations    // (tip move them in a JSON file and import them,    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      ab: arabic,
      en: english,
    },
    lng: 'ab',
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'ab',
    interpolation: {
      escapeValue: false, // react already safes from  xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

function App() {
  return (
    <Router>
      <DataContextProvider>
        <Switch>
          <RouteLayout path="/" exact component={Orders} layout={LayoutPrivate} isPrivate={true} />
          <RouteLayout
            path="/orders"
            exact
            component={Orders}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/list-discount-order"
            exact
            component={OrdersDiscount}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/orders/live"
            exact
            component={LiveOrders}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/orders/:id"
            component={Receipt}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/drivers"
            exact
            component={Drivers}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/drivers/:id/transactions"
            exact
            component={DriverTransactions}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/drivers/:id/orders"
            exact
            component={DriverOrders}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/drivers/new"
            component={newDriver}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/drivers/edit/:id"
            component={editDriver}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/drivers/create-note/:id"
            component={CreateNote}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/drivers/citc/:id"
            component={CitcDriver}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/transactions"
            exact
            component={Transactions}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/offers"
            exact
            component={Offers}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/location-drivers"
            exact
            component={LocationDrivers}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/hyperpay-logs"
            exact
            component={HyperpayLog}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/citc-logs"
            exact
            component={LogsCitc}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/http-logs/:id"
            exact
            component={DetailLog}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/http-logs"
            exact
            component={HttpLogs}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/settings"
            exact
            component={TabSetting}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/citc-logs/:id"
            exact
            component={DetailLogCitc}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/ops-management"
            exact
            component={OpsManagement}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/ops-management/new"
            exact
            component={NewOperator}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/ops-management/edit/:id"
            exact
            component={EditOperator}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/delivery-company"
            exact
            component={DeliveryCompanyManagement}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/delivery-company/detail/:id"
            exact
            component={CompanyDetail}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/delivery-company/detail/:id/new"
            exact
            component={NewPartner}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/delivery-company/detail/:id/edit/:pid"
            exact
            component={EditPartner}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/delivery-company/new"
            exact
            component={NewCompany}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/delivery-company/edit/:id"
            exact
            component={EditCompany}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/pricing"
            exact
            component={Pricing}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/pricing/new"
            exact
            component={NewPlan}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/pricing/edit/:id"
            exact
            component={EditPlan}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/odoo-logs"
            exact
            component={OdooLog}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/shukah-logs"
            exact
            component={ShukahLog}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/map-tracking"
            exact
            component={MapTracking}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/stores-registration"
            exact
            component={StoresRegistration}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/faker-order/drivers"
            exact
            component={DriversFakerOrder}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout path="/otp" exact component={Otp} layout={LayoutPrivate} isPrivate={true} />
          <Route path="/login" exact component={Login} />
          <Route path="/policy" exact component={Policy} />
          <Route path="/locate-policy" exact component={LocatePolicy} />
          <RouteLayout
            path="/rating"
            exact
            component={Rating}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/rating-drivers-detail"
            exact
            component={RatingDriverDetail}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/rating-stores-detail"
            exact
            component={RatingStoreDetail}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/dashboard"
            exact
            component={Dashboard}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/syncFile"
            exact
            component={SyncFile}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/payfort-transaction-logs"
            exact
            component={TransactionLogs}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/driver-registration"
            exact
            component={DriverRegistration}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/log-export"
            exact
            component={LogExport}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/export-orders"
            exact
            component={ExportOrders}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/subscriptions"
            exact
            component={Subscriptions}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/subscriptions-assign/company-name/:companyName/company-id/:companyId/subscription-id/:subscriptionId/drivers"
            exact
            component={SubscriptionDrivers}
            layout={LayoutPrivate}
            isPrivate={true}
          />
          <RouteLayout
            path="/orders_distribution"
            exact
            component={OrdersDistributionMechanism}
            layout={LayoutPrivate}
            isPrivate={true}
          />
        </Switch>
      </DataContextProvider>
    </Router>
  );
}
export default App;
