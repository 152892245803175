import { useTranslation } from 'react-i18next';
import { STATUS_MAPPING } from '../../helper/regexHelper';

export const FilterStatuses = () => {
  const { t } = useTranslation();

  return [
    {
      value: 'SUCCESS',
      label: t('SUCCESS'),
    },
    {
      value: 'FAILURE',
      label: t('FAILURE'),
    },
    {
      value: 'PENDING',
      label: t('PENDING'),
    },
  ];
};

export const FromApplication = () => {
  const { t } = useTranslation();

  return [
    {
      value: 'EXPRESS',
      label: t('EXPRESS'),
    },
    {
      value: 'RESTAURANT',
      label: t('RESTAURANT'),
    },
    {
      value: 'SHUKAH_CLIENT',
      label: t('SHUKAH_CLIENT'),
    },
    {
      value: 'LOCATE_CLIENT',
      label: t('LOCATE_CLIENT'),
    },
    {
      value: 'CLIENT',
      label: t('CLIENT'),
    },
    {
      value: 'MAEDA_CLIENT',
      label: t('MAEDA_CLIENT'),
    },
  ];
};

export const DigitalWallet = () => {
  const { t } = useTranslation();

  return [
    {
      value: 'APPLE_PAY',
      label: t('APPLE_PAY'),
    },
    {
      value: 'CARD_PAY',
      label: t('CARD_PAY'),
    },
    {
      value: 'STC_PAY',
      label: t('STC_PAY'),
    },
  ];
};

export const conditionalRowStyles = [
  {
    when: (row) => row?.transaction_status === STATUS_MAPPING.FAILURE,
    style: {
      backgroundColor: '#F7DDDE',
    },
  },
  {
    when: (row) => row?.transaction_status === STATUS_MAPPING.SUCCESS,
    style: {
      backgroundColor: '#D4E9D8',
    },
  },
  {
    when: (row) => row?.transaction_status === STATUS_MAPPING.PENDING,
    style: {
      backgroundColor: '#FFFBDB',
    },
  },
];
