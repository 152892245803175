import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { config } from '../../../config/Config';
import CustomSelectReact from '../../CustomSelectReact';

import { useTranslation } from 'react-i18next';

const CompanyInput = ({ value, ...props }) => {
  const { t } = useTranslation();

  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getCompanyList = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(config.API_URL.DELIVERY_COMPANY.LIST);
      if (data && data?.success) {
        const terraformData = await data?.data?.map((company) => {
          return {
            label: company.name,
            value: company.name,
            id: company._id,
          };
        });
        setCompanies(terraformData);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(async () => {
    await getCompanyList();
  }, []);

  return (
    <>
      <div>
        <label>{t('company')} </label>
        <CustomSelectReact
          isDisabled={isLoading}
          placeholder={t('company')}
          className="filter-select min-w-[200px]"
          options={companies}
          value={value}
          {...props}
        />
      </div>
    </>
  );
};

export default CompanyInput;
