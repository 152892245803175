import { useTranslation } from 'react-i18next';
import { parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { useDate } from '../../hooks/useDate';

const useSchema = () => {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();

  const schemaStoresRegistration = [
    {
      name: t('registration_status'),
      selector: 'registration_status',
      sortable: true,
    },
    {
      name: t('store_name'),
      selector: 'store_name',
      sortable: true,
    },
    {
      name: t('number_of_branches'),
      selector: 'number_of_branches',
    },
    {
      name: t('category'),
      selector: 'category',
    },
    {
      name: t('contact_number'),
      selector: 'contact_number',
      sortable: true,
    },
    {
      name: t('city'),
      selector: 'city',
      sortable: true,
    },
    {
      name: t('Closed At'),
      selector: (row) =>
        parseDateWithGmt({
          date: row.closed_at,
          timezone: currentTimezone?.timezone,
        }),

      sortable: true,
      wrap: true,
      style: {
        padding: 0,
      },
    },
    {
      name: t('created_at'),
      selector: (row) =>
        parseDateWithGmt({
          date: row.created_at,
          timezone: currentTimezone?.timezone,
        }),

      sortable: true,
      wrap: true,
      style: {
        padding: 0,
      },
    },
  ];

  return schemaStoresRegistration;
};
export default useSchema;
