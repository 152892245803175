import React from 'react';

function Policy() {
  return (
    <div>
      <div className="translations-content-container" style={{ fontFamily: 'Roboto' }}>
        <div style={{ padding: '20px 20px' }}>
          <div className="tab-content translations-content-item en visible" id="en">
            <h1>Privacy Policy</h1>
            <p>Last updated: January 10, 2022</p>
            <p>
              We built the Locate go app as a Free app. This SERVICE is provided by Maeda at no cost
              and is intended for use as is.
            </p>
            <p>
              This page is used to inform visitors regarding my policies with the collection, use,
              and disclosure of Personal Information if anyone decided to use my Service.
            </p>
            <p>
              If you choose to use my Service, then you agree to the collection and use of
              information in relation to this policy. The Personal Information that I collect is
              used for providing and improving the Service. I will not use or share your information
              with anyone except as described in this Privacy Policy.
            </p>
            <p>
              The terms used in this Privacy Policy have the same meanings as in our Terms and
              Conditions, which is accessible at Maeda unless otherwise defined in this Privacy
              Policy.
            </p>
            <h2>Definitions</h2>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
              <li>
                <p>
                  <strong>Account</strong> means a unique account created for You to access our
                  Service or parts of our Service.
                </p>
              </li>
              <li>
                <p>
                  <strong>Affiliate</strong> means an entity that controls, is controlled by or is
                  under common control with a party, where &quot;control&quot; means ownership of
                  50% or more of the shares, equity interest or other securities entitled to vote
                  for election of directors or other managing authority.
                </p>
              </li>
              <li>
                <p>
                  <strong>Application</strong> means the software program provided by the Company
                  downloaded by You on any electronic device, named Locate Go
                </p>
              </li>
              <li>
                <p>
                  <strong>Country</strong> refers to: Saudi Arabia
                </p>
              </li>
              <li>
                <p>
                  <strong>Device</strong> a cellphone or a digital tablet.
                </p>
              </li>
              <li>
                <p>
                  <strong>Personal Data</strong> is any information that relates to an identified or
                  identifiable individual.
                </p>
              </li>
              <li>
                <p>
                  <strong>Service</strong> refers to the Application.
                </p>
              </li>
              <li>
                <p>
                  <strong>Service Provider</strong> means any natural or legal person who processes
                  the data on behalf of the Company. It refers to third-party companies or
                  individuals employed by the Company to facilitate the Service, to provide the
                  Service on behalf of the Company, to perform services related to the Service or to
                  assist the Company in analyzing how the Service is used.
                </p>
              </li>
              <li>
                <p>
                  <strong>Usage Data</strong> refers to data collected automatically, either
                  generated by the use of the Service or from the Service infrastructure itself (for
                  example, the duration of a page visit).
                </p>
              </li>
              <li>
                <p>
                  <strong>You</strong> means the individual accessing or using the Service, or the
                  company, or other legal entity on behalf of which such individual is accessing or
                  using the Service, as applicable.
                </p>
              </li>
            </ul>
            <h1>Collecting and Using Your Personal Data</h1>
            <h2>Types of Data Collected</h2>
            <h3>Personal Data</h3>
            <p>
              While using Our Service, We may ask You to provide Us with certain personally
              identifiable information that can be used to contact or identify You. Personally
              identifiable information may include, but is not limited to:
            </p>
            <ul>
              <li>
                <p>Email address</p>
              </li>
              <li>
                <p>First name and last name</p>
              </li>
              <li>
                <p>Phone number</p>
              </li>
              <li>
                <p>Address, State, Province, ZIP/Postal code, City</p>
              </li>
            </ul>
            <h3>Usage Data</h3>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>
              When You access the Service by or through a mobile device, We may collect certain
              information automatically, including, but not limited to, the type of mobile device
              You use, Your mobile device unique ID, the IP address of Your mobile device, Your
              mobile operating system, the type of mobile Internet browser You use, unique device
              identifiers and other diagnostic data.
            </p>
            <h3>Information Collected while Using the Application</h3>
            <p>
              While using Our Application, in order to provide features of Our Application, We may
              collect, with Your prior permission:
            </p>
            <ul>
              <li>
                <p>
                  <strong>Location Information:</strong> To participate as a Maeda Exxpress driver,
                  you must permit us to access location services through the permission system used
                  by your mobile. We may collect the precise location of your device when the Maeda
                  Exxpress driver app is running in the foreground or background of your device. We
                  may also derive your approximate location from your GPS. We use your location
                  information to verify that you are present within a radius of 5km around nearby
                  restaurants, to connect you with delivery opportunities in your zone, and track
                  the progress and completion of your Deliveries. You can enable the location
                  tracking feature through the settings on your device or Platform or when prompted
                  by the Locate Go mobile app. If you choose to disable the location feature through
                  the settings on your device or Platform, Locate Go will not receive precise
                  location information from your device, which will prevent you from being able to
                  receiving delivery opportunities in your area.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pictures and photo library</strong>
                </p>
              </li>
            </ul>
            <p>
              We use this information to provide features of Our Service, to improve and customize
              Our Service. The information may be uploaded to the Company's servers and/or a Service
              Provider's server or it may be simply stored on Your device.
            </p>
            <p>
              You can enable or disable access to this information at any time, through Your Device
              settings.
            </p>
            <h3>Cookies</h3>
            <p>
              Cookies are files with a small amount of data that are commonly used as anonymous
              unique identifiers. These are sent to your browser from the websites that you visit
              and are stored on your device's internal memory.
            </p>
            <p>
              This Service does not use these “cookies” explicitly. However, the app may use third
              party code and libraries that use “cookies” to collect information and improve their
              services. You have the option to either accept or refuse these cookies and know when a
              cookie is being sent to your device. If you choose to refuse our cookies, you may not
              be able to use some portions of this Service.
            </p>
            <p>
              <strong>Service Providers</strong>
            </p>
            <p>I may employ third-party companies and individuals due to the following reasons:</p>
            <ul>
              <li>To facilitate our Service;</li>
              <li>To provide the Service on our behalf;</li>
              <li>To perform Service-related services; or</li>
              <li>To assist us in analyzing how our Service is used.</li>
            </ul>
            <p>
              I want to inform users of this Service that these third parties have access to your
              Personal Information. The reason is to perform the tasks assigned to them on our
              behalf. However, they are obligated not to disclose or use the information for any
              other purpose.
            </p>
            <h2>Use of Your Personal Data</h2>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
              <li>
                <p>
                  <strong>To provide and maintain our Service</strong>, including to monitor the
                  usage of our Service.
                </p>
              </li>
              <li>
                <p>
                  <strong>To manage Your Account:</strong> to manage Your registration as a user of
                  the Service. The Personal Data You provide can give You access to different
                  functionalities of the Service that are available to You as a registered user.
                </p>
              </li>
              <li>
                <p>
                  <strong>For the performance of a contract:</strong> the development, compliance
                  and undertaking of the purchase contract for the products, items or services You
                  have purchased or of any other contract with Us through the Service.
                </p>
              </li>
              <li>
                <p>
                  <strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or
                  other equivalent forms of electronic communication, such as a mobile application's
                  push notifications regarding updates or informative communications related to the
                  functionalities, products or contracted services, including the security updates,
                  when necessary or reasonable for their implementation.
                </p>
              </li>
              <li>
                <p>
                  <strong>To provide You</strong> with news, special offers and general information
                  about other goods, services and events which we offer that are similar to those
                  that you have already purchased or enquired about unless You have opted not to
                  receive such information.
                </p>
              </li>
              <li>
                <p>
                  <strong>To manage Your requests:</strong> To attend and manage Your requests to
                  Us.
                </p>
              </li>
              <li>
                <p>
                  <strong>For other purposes</strong>: We may use Your information for other
                  purposes, such as data analysis, identifying usage trends, determining the
                  effectiveness of our promotional campaigns and to evaluate and improve our
                  Service, products, services, marketing and your experience.
                </p>
              </li>
            </ul>
            <p>We may share Your personal information in the following situations:</p>
            <ul>
              <li>
                <strong>With Service Providers:</strong> We may share Your personal information with
                Service Providers to monitor and analyze the use of our Service, to contact You.
              </li>
              <li>
                <strong>With Affiliates:</strong> We may share Your information with Our affiliates,
                in which case we will require those affiliates to honor this Privacy Policy.
                Affiliates include Our parent company and any other subsidiaries, joint venture
                partners or other companies that We control or that are under common control with
                Us.
              </li>
              <li>
                <strong>With business partners:</strong> We may share Your information with Our
                business partners to offer You certain products, services or promotions.
              </li>
              <li>
                <strong>With other users:</strong> when You share personal information or otherwise
                interact in the public areas with other users, such information may be viewed by all
                users and may be publicly distributed outside.
              </li>
            </ul>
            <h2>Retention of Your Personal Data</h2>
            <p>
              The Company will retain Your Personal Data only for as long as is necessary for the
              purposes set out in this Privacy Policy. We will retain and use Your Personal Data to
              the extent necessary to comply with our legal obligations (for example, if we are
              required to retain your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
            </p>
            <p>
              The Company will also retain Usage Data for internal analysis purposes. Usage Data is
              generally retained for a shorter period of time, except when this data is used to
              strengthen the security or to improve the functionality of Our Service, or We are
              legally obligated to retain this data for longer time periods.
            </p>
            <h2>Transfer of Your Personal Data</h2>
            <p>
              Your information, including Personal Data, is processed at the Company's operating
              offices and in any other places where the parties involved in the processing are
              located. It means that this information may be transferred to — and maintained on —
              computers located outside of Your state, province, country or other governmental
              jurisdiction where the data protection laws may differ than those from Your
              jurisdiction.
            </p>
            <p>
              Your consent to this Privacy Policy followed by Your submission of such information
              represents Your agreement to that transfer.
            </p>
            <p>
              The Company will take all steps reasonably necessary to ensure that Your data is
              treated securely and in accordance with this Privacy Policy and no transfer of Your
              Personal Data will take place to an organization or a country unless there are
              adequate controls in place including the security of Your data and other personal
              information.
            </p>
            <h2>Disclosure of Your Personal Data</h2>
            <h3>Business Transactions</h3>
            <p>
              If the Company is involved in a merger, acquisition or asset sale, Your Personal Data
              may be transferred. We will provide notice before Your Personal Data is transferred
              and becomes subject to a different Privacy Policy.
            </p>
            <h3>Law enforcement</h3>
            <p>
              Under certain circumstances, the Company may be required to disclose Your Personal
              Data if required to do so by law or in response to valid requests by public
              authorities (e.g. a court or a government agency).
            </p>
            <h2>Security of Your Personal Data</h2>
            <p>
              The security of Your Personal Data is important to Us, but remember that no method of
              transmission over the Internet, or method of electronic storage is 100% secure. While
              We strive to use commercially acceptable means to protect Your Personal Data, We
              cannot guarantee its absolute security.
            </p>
            <h1>Children's Privacy</h1>
            <p>
              Our Service does not address anyone under the age of 18. We do not knowingly collect
              personally identifiable information from anyone under the age of 18. If You are a
              parent or guardian and You are aware that Your child has provided Us with Personal
              Data, please contact Us. If We become aware that We have collected Personal Data from
              anyone under the age of 18 without verification of parental consent, We take steps to
              remove that information from Our servers.
            </p>
            <p>
              If We need to rely on consent as a legal basis for processing Your information and
              Your country requires consent from a parent, We may require Your parent's consent
              before We collect and use that information.
            </p>
            <h1>Contact Us</h1>
            <p>If you have any questions about this Privacy Policy, You can contact us:</p>
            <ul>
              <li>
                By email:
                <a href="mailto:info@maeda.com.sa">info@maeda.com.sa</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Policy;
