export const DRIVERS = 'Drivers';
export const CUSTOMERS = 'Customers';
export const RESTAURANTS = 'Restaurants';
export const OPEN = 'OPEN';
export const CLOSE = 'CLOSE';

export const ratingStar = (scoreRating) => {
  const checkRatingStar = (number) => `${(scoreRating >= number && '#FCC747') || '#C7C9CE'}`;

  return [
    {
      className: checkRatingStar(1),
      value: 1,
    },
    {
      className: checkRatingStar(2),
      value: 2,
    },
    {
      className: checkRatingStar(3),
      value: 3,
    },
    {
      className: checkRatingStar(4),
      value: 4,
    },
    {
      className: checkRatingStar(5),
      value: 5,
    },
  ];
};

export const renderFields = (text) => {
  if (!text) return '-';
  return <div className="_line-clamp_2">{text}</div>;
};

export const renderRatingColor = (rating) => {
  if (rating < 1) {
    return '#eb2228';
  } else if (rating < 2) {
    return '#fb6838';
  } else if (rating < 3) {
    return '#ffae37';
  } else if (rating < 4) {
    return '#88d54d';
  } else {
    return '#25b53c';
  }
};

export const VERSION_HISTORY = {
  NAME: 'name',
  FIRST_NAME: 'firstName',
  SECOND_NAME: 'secondName',
  THIRD_NAME: 'thirdName',
  LAST_NAME: 'Family',
  PHONE: 'phone',
  DATE_OF_BIRTH: 'date_of_birth',
  IDENTITY_TYPE: 'identity_type',
  ID_NUMBER: 'id_number',
  COMPANY_NAME: 'company_name',
  STC_PAY_NUMBER: 'stc_pay_number',
  CAR_TYPE: 'car_type',
  CAR_NUMBER: 'car_number',
  REGION: 'region',
  CITY: 'city',
  VEHICLE_SEQUENCE_NUMBER: 'vehicle_sequence_number',
  MEDICAL_INSURANCE_DATE: 'medical_Insurance_date',
  IS_CASPER: 'isCasper',
  ATTACHMENT: 'attachment',
  DRIVER_IMAGE: 'driver_image',
  CAR_TYPE_ID: 'car_type_id',
  IDENTITY_TYPE_ID: 'identity_type_id',
  REGION_ID: 'region_id',
  CITY_ID: 'city_id',
  FROM_COMPANY: 'from_company',
};

export const DRIVER_HISTORY_TYPE = {
  NOTE: 'NOTE',
  VERSION: 'VERSION',
};
