import React from 'react';
import useLogicDrivers from '../../hooks/useLogicDrivers';
import SelectDate from '../filter/SelectDate';
import ItemInfo from './ItemInfo';
import useBarChart from '../../hooks/useBarChart';

export default function DriverAnalysis({ id }) {
  const { date, selectedOption, onChangeDate, setSelectedOption, data } = useLogicDrivers(id);

  useBarChart({ elementId: 'chart-bars', data: data?.dataChart });

  return (
    <div className="w-full 2xl:w-3/4 max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:flex-none z-0">
      <div className="p-6 h-full border-black/12.5 shadow-soft-xl relative z-20 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
        <div className="w-full  flex flex-row justify-between px-4">
          <h6 className="text-4xl font-bold ">Driver overview</h6>
          <SelectDate
            date={date}
            onChangeDate={onChangeDate}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        <div className="flex-auto pt-4 mt-4">
          <div className="pt-4 pr-1 mb-4 bg-[#192541] rounded-xl">
            <div>
              <canvas id="chart-bars" height="400vh" width="1200vw"></canvas>
            </div>
          </div>
          {/* <h6 className="mt-8 mb-0 ml-2 font-semibold text-2xl">Active Users</h6>
          <p className="ml-2 leading-normal text-md mt-1 text-gray-500">
            (<span className="font-bold">+23%</span>) than last week
          </p> */}
          <div className="w-full px-6 mx-auto max-w-screen-2xl rounded-xl mt-8">
            <div className="flex flex-wrap mt-4 -mx-3">
              <ItemInfo label="Total hours" value={data?.total?.toFixed(2)} />
              <ItemInfo label="Average hours " value={data?.average?.toFixed(2)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
