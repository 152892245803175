import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';

export default function ImageDriver({ src }) {
  const [urlImageDriver, setUrlImageDriver] = useState();

  const getImageDriver = useCallback(
    async (controller) => {
      try {
        await axios.get(src, { timeout: 5000, signal: controller.signal });

        setUrlImageDriver(src);
      } catch (error) {
        console.log(error);
        controller.abort();
      }
    },
    [src]
  );

  useEffect(() => {
    const controller = new AbortController();

    getImageDriver(controller);

    return () => controller.abort();
  }, [getImageDriver]);

  if (!urlImageDriver) return null;

  return (
    <span className="upload-span">
      <a
        className="upload-list-item-thumbnail"
        href={urlImageDriver}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={urlImageDriver} alt="" className="upload-list-item-image" />
      </a>
    </span>
  );
}
