import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CsvDownload from 'react-json-to-csv';
import { useDataTable } from '../../../hooks/useDataTable';
import ActionOrder from './ActionOrder/ActionOrder';
import DataTableOrder from './DataTableOrder/DataTableOrder';
import Filters from './Filters/Filters';
import useNewOrder from './useNewOrder';
import ReOrderModal from '../../../commonComponents/ReOrderModal/ReOrderModal';
import HistoryModal from './common/HistoryModal';

export default function NewOrder() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const {
    data = [],
    setData,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    total,
    setTotal,
  } = useDataTable({ isLoading, setIsLoading });

  const {
    filtersRef,
    selectedTab,
    selectedItem,
    handlerGetOrder,

    setSelectedTab,
    setSelectedItem,
    onRowClicked,
    handlePageChange,
    buildFilterOrderWithRef,
    handleLimitChange,
    refresh,
    buildNewQueryStringWithFilter,
    unAssignOrder,

    cart,
    height,
    loading,
    refHeight,
    totalResult,
    missingReOrder,
    totalItemPrice,
    isModalVisible,
    orderDataDetail,
    totalQuantityPrice,
    quantityItemIncrease,
    newItemOrderDataDetail,
    totalReOrderIsMissing,
    closeModal,
    handlerModal,
    addItemToCart,
    onAddItemToCart,
    removeItemOnCart,
    onPressAddToping,
    onSubtractionToCart,
    addTopingItemToCart,

    onPressSubtractionToping,
    setIsModalVisible,
    removeToppingOnCart,
    setCart,
    openHistory,
    setOpenHistory,
    handlerHistoryModal,
    dataHistory,

    orderRowSelected,
    getOrders,
  } = useNewOrder({
    isLoading,
    setIsLoading,
    currentPage,
    setCurrentPage,
    setData,
    setTotal,
    limit,
    setLimit,
    data,
  });

  return (
    <div>
      <div className="px-[20px] relative" ref={refHeight}>
        {/* action buttons */}
        <div className="pt-4 pb-8">
          <ActionOrder
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            refresh={refresh}
            setCurrentPage={setCurrentPage}
            buildNewQueryStringWithFilter={buildNewQueryStringWithFilter}
            buildFilterOrderWithRef={buildFilterOrderWithRef}
          />
        </div>

        {/* filter */}
        {selectedItem.length > 0 && (
          <CsvDownload
            filename="orders.csv"
            data={selectedItem}
            style={{
              position: 'absolute',
              top: 90,
              zIndex: 2,
              right: 30,
              borderWidth: 0,
              backgroundColor: '#fff',
              padding: 10,
              borderRadius: 5,
              cursor: 'pointer',
            }}
          >
            {t('loading_data')}
          </CsvDownload>
        )}
        <Filters
          isLoading={isLoading}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          handlerGetOrder={handlerGetOrder}
          ref={filtersRef}
          buildFilterOrderWithRef={buildFilterOrderWithRef}
        />
      </div>

      {/* table */}
      <div className="relative">
        <DataTableOrder
          data={data}
          limit={limit}
          total={total}
          height={height}
          isLoading={isLoading}
          currentPage={currentPage}
          selectedTab={selectedTab}
          setSelectedItem={setSelectedItem}
          setIsLoading={setIsLoading}
          unAssignOrder={unAssignOrder}
          setCurrentPage={setCurrentPage}
          handlePageChange={handlePageChange}
          onRowClicked={onRowClicked}
          setLimit={handleLimitChange}
          handlerModal={handlerModal}
          isModalVisible={isModalVisible}
          handlerHistoryModal={handlerHistoryModal}
          expandableRowExpanded={(row) => row === orderRowSelected}
          getOrders={getOrders}
          buildFilterOrderWithRef={buildFilterOrderWithRef}
        />

        <ReOrderModal
          cart={cart}
          loading={loading}
          isOpen={isModalVisible}
          totalResult={totalResult}
          isModalVisible={isModalVisible}
          totalItemPrice={totalItemPrice}
          missingReOrder={missingReOrder}
          orderDataDetail={orderDataDetail}
          totalQuantityPrice={totalQuantityPrice}
          quantityItemIncrease={quantityItemIncrease}
          newItemOrderDataDetail={newItemOrderDataDetail}
          totalReOrderIsMissing={totalReOrderIsMissing}
          closeModal={closeModal}
          addItemToCart={addItemToCart}
          onAddItemToCart={onAddItemToCart}
          onPressAddToping={onPressAddToping}
          removeItemOnCart={removeItemOnCart}
          onSubtractionToCart={onSubtractionToCart}
          onPressSubtractionToping={onPressSubtractionToping}
          setIsModalVisible={setIsModalVisible}
          addTopingItemToCart={addTopingItemToCart}
          removeToppingOnCart={removeToppingOnCart}
          setCart={setCart}
          data={data}
          orderRowSelected={orderRowSelected}
        />
        <HistoryModal
          isOpen={openHistory}
          setIsOpen={setOpenHistory}
          dataHistory={dataHistory}
          loading={loading}
        />
      </div>
    </div>
  );
}
