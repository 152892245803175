import { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { config } from '../../config/Config';
import useEventEnter from '../../hooks/useEventEnter';

import { customStyles } from '../Rating/useColumn';
import { axiosGet } from '../../helper/axios/axiosGet';
import Filters from './Filters';
import { handleMessage } from '../../helper/utils';
import { isObject, isArray, cloneDeep } from 'lodash';
import { createRangeDate } from '../../hooks/useDate';
import { DataContext } from '../../context/DataContext';
import axios from 'axios';
import useSchema from '../Orders/Schema';
import { conditionalRowStyles } from '../Orders/NewOrder/DataTableOrder/DataTableOrder';

const ExportOrders = () => {
  const { t } = useTranslation();
  const tableHeight = window.innerHeight - 330;
  const { currentTimezone } = useContext(DataContext);
  const schema = useSchema();

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [limit, setLimit] = useState(100);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState('');
  const [exportOrders, setExportOrders] = useState();
  const [isExportExcel, setIsExportExcel] = useState(false);

  const handleChangePage = (nextPage) => {
    if (Number(nextPage) === Number(currentPage) || isLoading) return false;

    setCurrentPage(nextPage);
  };

  const handleSetFilters = (newFilter) => {
    setCurrentPage(1);
    setFilters(newFilter);
  };

  const handleChangeDateOnFilter = (value) => {
    if (!value) {
      setDateRange('');
    }

    handleSetFilters({
      ...filters,
      date: value ? [value[0], value[1]] : '',
    });
  };

  const handleChangeFilter = (optionValues, type) => {
    const newFilters = {
      ...filters,
      [type]: optionValues,
    };

    handleSetFilters(newFilters);
  };

  const buildNewQueryStringWithFilter = (filters) => {
    const cloneDeepFilters = cloneDeep(filters);

    let driver_company = [];
    if (isArray(cloneDeepFilters?.driver_company) && cloneDeepFilters?.driver_company.length > 0) {
      cloneDeepFilters?.driver_company.map((item) => driver_company.push(item.value));
      cloneDeepFilters.driver_company = driver_company;
    }
    if (isObject(cloneDeepFilters?.isDiscount)) {
      cloneDeepFilters.isDiscount = filters.isDiscount.value;
    }
    if (cloneDeepFilters?.date) {
      cloneDeepFilters.date = createRangeDate(filters?.date, currentTimezone);
    }
    if (isObject(cloneDeepFilters?.isFreeExpress)) {
      cloneDeepFilters.isFreeExpress = filters.isFreeExpress.value;
    }
    if (isObject(cloneDeepFilters?.use_geidea)) {
      cloneDeepFilters.use_geidea = filters.use_geidea.value;
    }
    if (isObject(cloneDeepFilters?.isCasper)) {
      cloneDeepFilters.isCasper = filters.isCasper.value;
    }
    if (isObject(cloneDeepFilters?.isMissingOrder)) {
      cloneDeepFilters.isMissingOrder = filters.isMissingOrder.value;
    }
    return cloneDeepFilters;
  };

  const getDataExportOrders = async () => {
    setIsLoading(true);

    const newFilters = {
      filters: {
        ...buildNewQueryStringWithFilter(filters),
      },
      limit: limit,
      page: currentPage,
    };
    try {
      const {
        data: { data },
      } = await axiosGet(`${config.API_URL.ORDERS.GET_ORDER_VIEW}`, {
        params: {
          ...newFilters,
        },
        headers: config.headers,
      });

      setExportOrders(data?.results);
      setTotalRecords(data?.total);
    } catch (error) {
      console.log('Error - ExportOrders', error);
    } finally {
      setIsLoading(false);
    }
  };

  const exportExcel = () => {
    const newFilters = {
      filters: {
        ...buildNewQueryStringWithFilter(filters),
      },
      limit: limit,
      page: currentPage,
    };
    setIsExportExcel(true);
    try {
      axios
        .post(config.API_URL.ORDERS.EXPORT_EXCEL, null, {
          params: {
            ...newFilters,
          },
          headers: {
            ...config.headers,
            'Content-Disposition': 'attachment; filename=template.xlsx',
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          if (response?.status === 200) {
            handleMessage('success', 'Export excel Successfully! Check your email.');
          }
          setIsExportExcel(false);
        });
    } catch (errors) {
      handleMessage('error', 'Export excel failed! Please export excel again.');
      setIsExportExcel(false);
    }
  };

  const handleGetDataByFilter = async () => {
    setCurrentPage(1);
    getDataExportOrders();
  };

  useEventEnter(handleGetDataByFilter);

  useEffect(() => {
    getDataExportOrders();
  }, [currentPage, limit]);

  useEffect(() => {
    const { date } = filters;

    if (date) {
      const dateValue = [date[0], date[1]];

      setDateRange(dateValue);
    } else {
      setDateRange('');
    }
  }, [filters]);

  return (
    <div className="rtl">
      <div className="pt-4 pb-8">
        <button
          disabled={isExportExcel}
          className={`refresh-button  ${
            isExportExcel ? 'opacity-40 border-red-200' : 'opacity-100 '
          } `}
          onClick={exportExcel}
        >
          {t('export_excel_all_by_filter')}
        </button>
      </div>
      <Filters
        isLoading={isLoading}
        handleChangeFilter={handleChangeFilter}
        filters={filters}
        dateRange={dateRange}
        handleChangeDateOnFilter={handleChangeDateOnFilter}
        handleGetDataByFilter={handleGetDataByFilter}
      />

      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        highlightOnHover
        columns={schema}
        data={exportOrders}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationPerPage={+limit}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(+currentRowsPerPage);
          setCurrentPage(1);
        }}
        progressPending={isLoading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationTotalRows={totalRecords}
        paginationServer
        onChangePage={handleChangePage}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        selectableRows
        conditionalRowStyles={conditionalRowStyles}
        paginationDefaultPage={currentPage}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        customStyles={customStyles}
      />
    </div>
  );
};

export default ExportOrders;
