import axios from 'axios';
import { isArray, isDate } from 'lodash';
import { useContext } from 'react';
import { config, SETTING_KEY } from '../config/Config';
import { DataContext } from '../context/DataContext';
import { createDate } from '../helper/date/createDateWithTimezone';

export const getSettingTimezone = async () => {
  const resp = await axios.get(config.API_URL.SETTING.GET, {
    params: {
      key: SETTING_KEY.TIME_ZONE,
    },
    headers: config.headers,
  });
  const { data } = resp;
  const { setting } = data;
  return setting;
};

export const createRangeDate = (date, timeZone) => {
  if (isArray(date)) {
    return [createDateWithTimezone(date[0], timeZone), createDateWithTimezone(date[1], timeZone)];
  }
};

export const createDateWithTimezone = (date, timeZone) => {
  if (isDate(date)) {
    return createDate(date, timeZone);
  } else {
    try {
      return createDate(new Date(date), timeZone);
    } catch (error) {}
  }
};

export const newCreateRangeDate = (date) => {
  if (isArray(date)) {
    return [newCreateDate(date[0]), newCreateDate(date[1])];
  }
};

export const newCreateSingleDate = (date) => {
  return newCreateDate(date);
};

export const newCreateDate = (date) => {
  return new Date(date);
};

export const useDate = () => {
  const { currentTimezone } = useContext(DataContext);
  return { currentTimezone };
};
