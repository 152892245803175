import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownInput from '../DropdownInput';
import { config, SETTING_KEY } from '../../config/Config';

const ConfigAPIPaymentType = ({ apiPaymentType, setApiPaymentType }) => {
  const { t } = useTranslation();
  const [defaultPayment, setDefaultPayment] = useState(null);

  const paymentType = [
    { value: 'AUTHORIZATION', label: t('AUTHORIZATION') },
    { value: 'PURCHASE', label: t('PURCHASE') },
  ];

  const handleSelectPayment = (value) => {
    const newFilters = {
      ...apiPaymentType,
      type: value.value,
    };
    setApiPaymentType(newFilters);
  };

  const getSetting = async () => {
    try {
      const resp = await axios.get(config.API_URL.SETTING.GET, {
        params: {
          key: SETTING_KEY.PAYMENT_MODE,
        },
        headers: config.headers,
      });
      const { data } = resp;
      const { setting } = data;
      if (setting) {
        const configPayment = {
          type: setting.type,
        };
        const filtered = paymentType.filter((ver) => ver.value === setting.type)?.[0];
        setDefaultPayment(filtered);
        handleSelectPayment(configPayment);
      }
    } catch (e) {
      console.log('getSetting ~ e:', e);
    }
  };

  useEffect(() => {
    getSetting();
  }, []);

  return (
    <div className="setting">
      <div className="row flex items-center gap-x-4">
        <label>{t('payment_type')}: </label>
        <DropdownInput
          textAlign="right"
          placeholder={t('payment_platform')}
          options={paymentType}
          handleSelect={handleSelectPayment}
          defaultSelectedValue={defaultPayment}
          hasDefault={false}
        />
      </div>
    </div>
  );
};

export default ConfigAPIPaymentType;
