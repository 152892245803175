import { useRef } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { delay } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../config/Config';
import { IconClose, IconSpin } from '../../constants/Icon';
import { FORMAT_DATE, parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { useDate } from '../../hooks/useDate';

export default function OdooLogDetail({ visible, id, toggleModal }) {
  const [isOpen, setIsOpen] = useState(false);
  const [log, setLog] = useState(null);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [responseHeight, setResponseHeight] = useState(0);
  const [headersHeight, setHeadersHeight] = useState(0);

  const responseTextAreaRef = useRef(null);
  const headersTextAreaRef = useRef(null);
  const { currentTimezone } = useDate();

  const getDetailLog = async () => {
    setIsLoading(true);
    try {
      const resp = await axios.get(`${config.API_URL.ODOO_LOGS.LIST}${id}`, {
        headers: config.headers,
      });
      setLog(resp.data.data);
    } catch (e) {
      console.log('error', e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setResponseHeight(responseTextAreaRef?.current?.scrollHeight);
      setHeadersHeight(headersTextAreaRef?.current?.scrollHeight);
    }
  }, [isLoading]);

  useEffect(() => {
    if (id) {
      getDetailLog();
    }
  }, [id]);

  function closeModal() {
    setIsOpen(false);
    toggleModal(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (visible) {
      openModal();
    } else {
      delay(() => {
        setResponseHeight(0);
        setHeadersHeight(0);
      }, 200);
    }
  }, [visible]);

  const renderFields = (field) => {
    if (field) return field;
    return '-';
  };

  const renderHyperpayLogDetail = () => {
    if (isLoading) {
      return (
        <div className="inline-block w-full max-w-7xl h-[calc(100vh-200px)] overflow-x-hidden overflow-y-auto px-10 py-12 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="flex items-center justify-center h-full w-full gap-x-4">
            <IconSpin />
            <p>{t('loading')}</p>
          </div>
        </div>
      );
    }
    return (
      <div className="inline-block w-full max-w-7xl h-full overflow-x-hidden overflow-y-hidden px-10 py-12 my-8 text-left align-middle transition-all duration-300 transform bg-white shadow-xl rounded-2xl">
        <div className="flex items-center justify-between gap-x-4">
          <div className="flex items-center gap-x-4">
            <span className="text-3xl font-bold">Log ID</span>
            <span className="text-3xl leading-6">{id}</span>
          </div>
          <button type="button" onClick={closeModal}>
            <IconClose />
          </button>
        </div>
        <div className="my-10 pl-6 text-right flex flex-col gap-y-10 overflow-y-auto h-[calc(100vh-260px)] transition-all">
          <div>
            <p className="col-span-1 font-bold text-2xl mb-2">{t('delegate')}</p>
            <div className="grid grid-cols-3 gap-x-4">
              <p className="font-bold col-span-1">{t('odoolog.method')}</p>
              <p className="col-span-2">{renderFields(log?.method)}</p>
              <p className="font-bold col-span-1">{t('odoolog.type')}</p>
              <p className="col-span-2">{renderFields(log?.type)}</p>
              <p className="font-bold col-span-1">{t('odoolog.url')}</p>
              <p className="col-span-2">{renderFields(log?.url)}</p>
            </div>
          </div>
          <div>
            <p className="font-bold text-2xl mb-2">{t('body')}</p>
            <div className="text-left">
              <textarea
                className="prettyprint w-full resize-none bg-white"
                disabled
                ref={headersTextAreaRef}
                value={JSON.stringify(log?.body, undefined, 2)}
                style={{ height: headersHeight }}
              />
            </div>
          </div>
          <div>
            <p className="font-bold text-2xl mb-2">{t('response')}</p>
            <div className="text-left">
              <textarea
                className="prettyprint w-full resize-none bg-white"
                disabled
                ref={responseTextAreaRef}
                value={JSON.stringify(log?.response, undefined, 2)}
                style={{ height: responseHeight }}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-x-4 gap-y-3">
            <p className="col-span-1 font-bold text-2xl">{t('date')}</p>
            <p className="col-span-2">
              {parseDateWithGmt({
                date: log?.created_at,
                timezone: currentTimezone?.timezone,
                format: FORMAT_DATE.ymdhms,
              })}
            </p>
          </div>
        </div>
        {log?.order_number && (
          <div className="flex items-center w-full mt-10">
            <a
              href={`/orders/${log?.order_number}`}
              title="Infomation invoice"
              without
              rel="noreferrer"
            >
              <button type="button" className="btn-primary rounded-md px-3 py-4">
                {t('view_detail_invoice')}
              </button>
            </a>
          </div>
        )}
      </div>
    );
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center rtl">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {renderHyperpayLogDetail()}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
