import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Slide, toast } from 'react-toastify';
import * as Yup from 'yup';
import { config } from '../../config/Config';
import { useHistory } from 'react-router-dom';

toast.configure();
const NewCompany = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('delivery_company.validate.required.name')),
    phone: Yup.string().required(t('delivery_company.validate.required.phone')),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      cr_number: '',
      contract: '',
      iban: '',
      distance_radius: '',
      company_contract_price: '',
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validateOnChange: false,
  });

  const borderInputError = {
    border: '1px solid #E95F67',
  };

  const handleSelectRegion = (select) => {
    formik.setValues({ ...formik.values, region_id: select.value });
  };

  const handleSelectCities = (select) => {
    formik.setValues({ ...formik.values, cities: select });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const finalizeData = {
      name: formik.values.name,
      phone: formik.values.phone,
      cr_number: formik.values.cr_number,
      contract: formik.values.contract,
      iban: formik.values.iban,
      company_distance_receive: formik.values.distance_radius,
      company_contract_price: formik.values.company_contract_price,
    };

    try {
      const { data } = await axios.post(config.API_URL.DELIVERY_COMPANY.CREATE, finalizeData, {
        headers: config.headers,
      });
      if (data?.success) {
        handleMessage('success');
        return window.history.back();
      }
      setLoading(false);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      if (error?.response?.status) {
        handleMessage('error', errorMessage);
      }

      setLoading(false);
    }
  };

  const handleMessage = (type = 'success', message = '') => {
    if (type === 'success') {
      return toast.success(t('delivery_company.register.success'), {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
      });
    }
    return toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Slide,
    });
  };

  return (
    <div className="add-ops-board">
      <h3 className="title">{t('delivery_company.register.title')}</h3>
      <div className="ops-form">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="name"
                name="name"
                type="text"
                style={formik.errors.name && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.errors.name && (
                <span className="mt-2 text-red-600 rtl error-message">{formik.errors.name}</span>
              )}
            </div>
            <span>{t('delivery_company.name')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="phone"
                name="phone"
                type="text"
                style={formik.errors.phone && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
              {formik.errors.phone && (
                <span className="mt-2 text-red-600 rtl error-message">{formik.errors.phone}</span>
              )}
            </div>
            <span>{t('delivery_company.phone')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="cr_number"
                name="cr_number"
                type="text"
                style={formik.errors.cr_number && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.cr_number}
              />
              {formik.errors.cr_number && (
                <span className="mt-2 text-red-600 rtl error-message">
                  {formik.errors.cr_number}
                </span>
              )}
            </div>
            <span>{t('delivery_company.cr_number')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="contract"
                name="contract"
                type="text"
                style={formik.errors.contract && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.contract}
              />
              {formik.errors.contract && (
                <span className="mt-2 text-red-600 rtl error-message">
                  {formik.errors.contract}
                </span>
              )}
            </div>
            <span>{t('delivery_company.contract')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="iban"
                name="iban"
                type="text"
                style={formik.errors.iban && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.iban}
              />
              {formik.errors.iban && (
                <span className="mt-2 text-red-600 rtl error-message">{formik.errors.iban}</span>
              )}
            </div>
            <span>{t('delivery_company.iban')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="distance_radius"
                name="distance_radius"
                type="text"
                style={formik.errors.distance_radius && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.distance_radius}
              />
              {formik.errors.distance_radius && (
                <span className="mt-2 text-red-600 rtl error-message">
                  {formik.errors.distance_radius}
                </span>
              )}
            </div>
            <span>{t('delivery_company.distance_radius')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="company_contract_price"
                name="company_contract_price"
                type="number"
                style={formik.errors.company_contract_price && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.company_contract_price}
              />
              {formik.errors.company_contract_price && (
                <span className="mt-2 text-red-600 rtl error-message">
                  {formik.errors.company_contract_price}
                </span>
              )}
            </div>
            <span>{t('delivery_company.company_contract_price')}</span>
          </div>

          <div className="">
            <button
              type="submit"
              className={`add-btn ${loading ? 'disabled' : ''}`}
              disabled={loading}
            >
              {t('delivery_company.submit')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewCompany;
