import moment from 'moment';
import { FORMAT_DATE, parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { useDate } from '../../hooks/useDate';

const { useMemo } = require('react');

const renderFields = (text) => {
  if (!text) return '-';
  return text;
};
const useColumn = () => {
  const { currentTimezone } = useDate();
  const columns = useMemo(
    () => [
      {
        name: 'Type',
        selector: 'type',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.type),
      },
      {
        name: 'Is recovery',
        selector: 'is_recovery',
        sortable: true,
        minWidth: '200px',
        cell: (row) => (row?.is_recovery ? 'True' : 'False'),
      },
      {
        name: 'Url',
        selector: 'url',
        sortable: true,
        minWidth: '200px',

        cell: (row) => (
          <a className="text-blue-500 hover:text-blue-600 truncate-text" href={row?.url}>
            {row?.url?.split('/')[row?.url?.split('/').length - 1] || row?.url}
          </a>
        ),
      },
      {
        name: 'Report Url',
        selector: 'url_report',
        sortable: true,
        minWidth: '200px',

        cell: (row) => (
          <a className="text-blue-500 hover:text-blue-600 truncate-text" href={row?.url_report}>
            {row?.url_report?.split('/')[row?.url_report?.split('/').length - 1] || row?.url_report}
          </a>
        ),
      },
      {
        name: 'Create at',
        selector: 'created_at',
        sortable: true,
        minWidth: '200px',
        maxWidth: '400px',

        cell: (row) =>
          parseDateWithGmt({
            date: row.created_at,
            timezone: currentTimezone?.timezone,
            format: FORMAT_DATE.ymdhms,
          }),
      },
    ],
    []
  );
  return { columns };
};

export { useColumn };
