/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config/Config';

const DetailLogCitc = ({ ...props }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [log, setLog] = useState({});
  const getDetailLog = async () => {
    setIsLoading(true);
    try {
      const resp = await axios.get(`${config.API_URL.CITC_LOGS.DETAIL}${id}`, {
        headers: config.headers,
      });
      setLog(resp.data.data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleAction = async () => {
    try {
      const resp = await axios.post(
        `${config.API_URL.CITC_LOGS.REACTION}${id}`,
        {},
        {
          headers: config.headers,
        }
      );
      if (resp.data) {
        window.location.reload();
      }
    } catch (errors) {
      console.log(errors);
    }
  };

  useEffect(() => {
    getDetailLog();
  }, []);

  if (isLoading) return <>{t('loading')}...</>;

  return (
    <>
      <div className="">
        <table id="table">
          <tbody>
            <tr>
              <td>{t('route')}</td>
              <td>{log.url}</td>
            </tr>
            <tr>
              <td>{t('method')}</td>
              <td>{log.method}</td>
            </tr>
            <tr>
              <td>{t('type')} </td>
              <td>{log.type}</td>
            </tr>
            <tr>
              <td>{t('request')}</td>
              <td>
                <pre className="prettyprint">{JSON.stringify(log.request, undefined, 2)}</pre>
              </td>
            </tr>
            <tr>
              <td>{t('status')}</td>
              <td>{log.isSuccess ? t('success') : t('failure')}</td>
            </tr>
            <tr>
              <td>{t('number_of_runs')}</td>
              <td>{log.count || 1}</td>
            </tr>
            <tr>
              <td>{t('response')}</td>
              <td>
                <pre className="prettyprint">{JSON.stringify(log.data, undefined, 2)} </pre>
              </td>
            </tr>
          </tbody>
        </table>
        {!log.isSuccess && (
          <button type="button" onClick={handleAction} className="btn btn-primary mt-20">
            {t('try_again')}
          </button>
        )}
      </div>
    </>
  );
};

export default DetailLogCitc;
