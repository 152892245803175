import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { delay } from 'lodash';
import { useTranslation } from 'react-i18next';
import { config } from '../../config/Config';
import { IconClose, IconSpin } from '../../constants/Icon';
import { FORMAT_DATE, parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { useDate } from '../../hooks/useDate';

export default function TransactionLogDetailsModal({ isVisible, logId, handleCloseModal }) {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();
  const responseTextAreaRef = useRef(null);
  const headersTextAreaRef = useRef(null);

  const [logDetails, setLogDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [responseHeight, setResponseHeight] = useState(0);
  const [headersHeight, setHeadersHeight] = useState(0);

  const getDetailLog = useCallback(async () => {
    setIsLoading(true);

    try {
      if (!logId) return true;

      const { data: response } = await axios.get(
        `${config.API_URL.TRANSACTIONS_BY_PAYFORT.GET_LOG_DETAILS(logId)}`,
        {
          headers: config.headers,
        }
      );

      setLogDetails(response);
    } catch (error) {
      console.log('Error', error);
    } finally {
      setIsLoading(false);
    }
  }, [logId]);

  useEffect(() => {
    if (!isLoading) {
      setResponseHeight(responseTextAreaRef?.current?.scrollHeight);
      setHeadersHeight(headersTextAreaRef?.current?.scrollHeight);
    }
  }, [isLoading]);

  useEffect(() => {
    getDetailLog();
  }, [getDetailLog]);

  useEffect(() => {
    if (!isVisible) {
      delay(() => {
        setResponseHeight(0);
        setHeadersHeight(0);
      }, 200);
    }
  }, [isVisible]);

  const renderFields = (fieldName, fieldData) => {
    if (fieldName)
      return (
        <>
          <p className="font-bold col-span-1">{t(fieldName)}</p>
          <p className="col-span-2">{fieldData ?? '-'}</p>
        </>
      );
  };

  const renderSelectedLogData = () => {
    if (isLoading) {
      return (
        <div className="inline-block w-full max-w-7xl h-[calc(100vh-200px)] overflow-x-hidden overflow-y-auto px-10 py-12 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="flex items-center justify-center h-full w-full gap-x-4">
            <IconSpin />
            <p>{t('loading')}</p>
          </div>
        </div>
      );
    }

    return (
      <div className="inline-block w-full max-w-7xl h-full overflow-x-hidden overflow-y-hidden px-10 py-12 my-8 text-left align-middle transition-all duration-300 transform bg-white shadow-xl rounded-2xl">
        <div className="flex items-center justify-between gap-x-4">
          <div className="flex items-center gap-x-4">
            <span className="text-3xl font-bold">{t('log_id')}</span>
            <span className="text-3xl leading-6">{logId}</span>
          </div>
          <button type="button" onClick={() => handleCloseModal()}>
            <IconClose />
          </button>
        </div>

        <div className="my-10 pl-6 text-right flex flex-col gap-y-10 overflow-y-auto h-[calc(100vh-260px)] transition-all">
          <div className="grid grid-cols-3 gap-x-4 gap-y-3 mb-8">
            {renderFields(
              'created_at',
              parseDateWithGmt({
                date: logDetails?.created_at,
                timezone: currentTimezone?.timezone,
                format: FORMAT_DATE.ymdhms,
              })
            )}
            {renderFields(
              'digital_wallet',
              logDetails?.digital_wallet ?? logDetails?.payment_log?.digital_wallet
            )}
            {renderFields('fort_id', logDetails?.fort_id)}
            {renderFields('from_application', logDetails?.from_application)}
            {renderFields('response_code', logDetails?.response_code)}
            {renderFields('response_message', logDetails?.response_message)}
            {renderFields('sdk_token', logDetails?.sdk_token)}
            {renderFields('service_command', logDetails?.service_command)}
            {renderFields('signature', logDetails?.signature)}
            {renderFields('transaction_id', logDetails?.transaction_id)}
            {renderFields('transaction_status', logDetails?.transaction_status)}
          </div>

          <div className="mb-8">
            <p className="font-bold text-2xl mb-2">{t('response_from_payfort')}</p>
            <div className="text-left">
              <textarea
                className="prettyprint w-full resize-none bg-white"
                disabled
                ref={headersTextAreaRef}
                value={JSON.stringify(logDetails?.payfort_response, undefined, 2)}
                style={{ height: headersHeight }}
              />
            </div>
          </div>

          <div>
            <p className="font-bold text-2xl mb-2">{t('response_from_shukah')}</p>
            <div className="text-left">
              <textarea
                className="prettyprint w-full resize-none bg-white"
                disabled
                value={JSON.stringify(logDetails?.shukah_response?.shukah_response, undefined, 2)}
                style={{
                  height: logDetails?.shukah_response?.shukah_response
                    ? window.innerHeight / 2.5
                    : 0,
                }}
              />
            </div>
          </div>

          <div>
            <p className="font-bold text-2xl mb-2">{t('save_log')}</p>
            <div className="text-left">
              <textarea
                className="prettyprint w-full resize-none bg-white"
                disabled
                ref={responseTextAreaRef}
                value={JSON.stringify(logDetails?.payment_log, undefined, 2)}
                style={{ height: responseHeight }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Transition appear show={isVisible} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={handleCloseModal}>
        <div className="min-h-screen px-4 text-center rtl">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {renderSelectedLogData()}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
