import React from 'react';
import useLineChart from '../../../../hooks/useLineChart';
import useLogicDashBoard from '../../hooks/useLogicDashBoard';
import SelectDate from '../filter/SelectDate';

export default function OrderAnalysis() {
  const { date, selectedOption, onChangeDate, setSelectedOption, data } =
    useLogicDashBoard('order');
  useLineChart({ elementId: 'chart-line', data, date, selectedOption });

  return (
    <div className="w-full max-w-full px-3 mt-0 lg:w-7/12 lg:flex-none z-0">
      <div className="border-black/12.5 shadow-soft-xl relative z-20 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
        <div className="border-black/12.5 mb-0 rounded-t-2xl border-b-0 border-solid bg-white p-6 pb-0">
          <div className="w-full  flex flex-row justify-between">
            <h6 className="text-4xl font-bold ">Order overview</h6>
            <SelectDate
              date={date}
              onChangeDate={onChangeDate}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
          <p className="leading-normal text-sm">
            <i className="fa fa-arrow-up text-lime-500"></i>
          </p>
        </div>
        <div className="flex-auto p-4">
          <div className="px-2 font-bold">Order</div>
          <div>
            <canvas id="chart-line" height="400"></canvas>
          </div>
        </div>
      </div>
    </div>
  );
}
