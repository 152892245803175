import { useTranslation } from 'react-i18next';

const useFilterOptionStores = () => {
  const { t } = useTranslation();

  const filterStatus = [
    {
      value: 'OPEN',
      label: t('open'),
    },
    {
      value: 'CLOSE',
      label: t('close'),
    },
  ];

  return {
    filterStatus,
  };
};
export default useFilterOptionStores;
