const useFilterOption = () => {
  const status = [
    {
      value: 200,
      label: 200,
    },
    {
      value: 201,
      label: 201,
    },
    {
      value: 304,
      label: 304,
    },
    {
      value: 400,
      label: 400,
    },
    {
      value: 422,
      label: 422,
    },
    {
      value: 500,
      label: 500,
    },
    {
      value: 502,
      label: 502,
    },
    {
      value: 504,
      label: 504,
    },
  ];
  const payment_methods = [
    {
      value: 'Cash',
      label: 'Cash',
    },
    {
      value: 'Card',
      label: 'Card',
    },
    {
      value: 'online',
      label: 'Online',
    },
  ];

  const types = [
    {
      value: 'DRIVER_CREATE',
      label: 'DRIVER CREATE',
    },
    {
      value: 'DRIVER_ASSIGN_DRIVER_TO_ORDER',
      label: 'DRIVER ASSIGN DRIVER TO ORDER',
    },
    {
      value: 'DRIVER_ACCEPT_ORDER',
      label: 'DRIVER ACCEPT ORDER',
    },
    {
      value: 'DRIVER_EXECUTE_ORDER',
      label: 'DRIVER EXECUTE ORDER',
    },
    {
      value: 'ORDER_CREATE',
      label: 'ORDER CREATE',
    },
    {
      value: 'ORDER_ASSIGN',
      label: 'ORDER ASSIGN',
    },
    {
      value: 'ORDER_EXECUTE',
      label: 'ORDER EXECUTE',
    },
    {
      value: 'ORDER_CANCEL',
      label: 'ORDER CANCEL',
    },
    {
      value: 'REORDER_MISSING_ITEMS',
      label: 'REORDER_MISSING_ITEMS',
    },
  ];

  return { payment_methods, types, status };
};
export default useFilterOption;
