export const SELECT_TRANSACTION_TYPES = [
  {
    label: 'HOLDING',
    value: 'HOLDING',
  },
  {
    label: 'CASH_BACK',
    value: 'CASH_BACK',
  },
  {
    label: 'FEE',
    value: 'FEE',
  },
  {
    label: 'TOP_UP',
    value: 'TOP_UP',
  },
  {
    label: 'TOP_UP_PAYFORT_REFUND',
    value: 'TOP_UP_PAYFORT_REFUND',
  },
];

export const SELECT_TRANSACTION_STATUSES = [
  {
    label: 'PENDING',
    value: 'PENDING',
  },
  {
    label: 'SUCCESS',
    value: 'SUCCESS',
  },
  {
    label: 'FAILURE',
    value: 'FAILURE',
  },
];

export const SELECT_CREATED_BY_ROLES = [
  {
    label: 'ADMIN',
    value: 'ADMIN',
  },
  {
    label: 'DRIVER',
    value: 'DRIVER',
  },
];

export const conditionalRowStyles = [
  {
    when: (row) => row.driver_id !== null,
    style: {
      backgroundColor: '#FFF',
    },
  },
];

export const TRANSACTION_TYPES = {
  HOLDING: 'HOLDING', // Đặt cọc tiền
  CASH_BACK: 'CASH_BACK', // Trả lại tiền cọc
  FEE: 'FEE', // Phí dịch vụ cho maeda
  TOP_UP: 'TOP_UP', // Nạp thẻ
  TOP_UP_PAYFORT_REFUND: 'TOP_UP_PAYFORT_REFUND', // Nạp thẻ
};

export const BALANCE_TYPES = {
  PREVIOUS: 'PREVIOUS',
  REMAINING: 'REMAINING',
  ADJUST_AMOUNT: 'ADJUST_AMOUNT',
};

export const ROLES = {
  ADMIN: 'ADMIN',
  DRIVER: 'DRIVER',
  SUPER_ADMIN: 'SUPER_ADMIN',
};

export const TRANSACTION_STATUSES = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};
