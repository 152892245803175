import { useRef, useState } from 'react';

const ToolTip = ({ children, text, ...rest }) => {
  const ref = useRef();

  const [isShow, setIsShow] = useState(false);

  const handleShowTooltip = () => setIsShow(true);
  const handleHideTooltip = () => setIsShow(false);

  const handleSetTooltipPosition = (element) => {
    const positions = {
      posX: element.clientX + 12,
      posY: element.clientY + 12,
    };

    ref.current.style.top = positions.posY + 'px';
    ref.current.style.left = positions.posX + 'px';
  };

  return (
    <div
      className="relative"
      onMouseEnter={handleShowTooltip}
      onMouseLeave={handleHideTooltip}
      onMouseMove={handleSetTooltipPosition}
    >
      <div
        ref={ref}
        id="tooltip"
        className={`fixed bg-gray-500 text-white text-center text-base py-1 px-2 rounded z-10 inline-block ${
          isShow ? 'block' : 'hidden'
        }`}
      >
        {text}
      </div>

      <div {...rest}>{children}</div>
    </div>
  );
};

export default ToolTip;
