import { Fragment, useEffect, useState, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { config, SETTING_KEY } from '../../../../config/Config';
import { useDate } from '../../../../hooks/useDate';
import { IconClose, IconSpin } from '../../../../constants/Icon';
import { FORMAT_DATE, parseDateWithGmt } from '../../../../helper/date/parseDateGmt';
import { TRANSACTION_STATUSES, TRANSACTION_TYPES } from '../constant';
import { renderAddedValue } from '../../../../helper/Transaction/renderAddedValue';
import { handleToastMessage } from '../../../../helper/toast';
import LoadingButton from '../../../../commonComponents/LoadingButton';

export default function TransactionDetailsModal({ visible, rowData, closeModal }) {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();

  const [transactionData, setTransactionData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [settingValues, setSettingValues] = useState(true);
  const [isShowButtonRefund, setIsShowButtonRefund] = useState(false);
  const [isRefundLoading, setIsRefundLoading] = useState(false);

  const showButtonRefund = useCallback(() => {
    if (
      transactionData?.status === TRANSACTION_STATUSES.SUCCESS &&
      !transactionData?.isRefunded &&
      transactionData?.canRefund &&
      transactionData?.payfort_information?.status_payfort_response ===
        TRANSACTION_STATUSES.SUCCESS &&
      transactionData?.type === TRANSACTION_TYPES.TOP_UP &&
      transactionData?.payfort_information?.fort_id
    ) {
      return setIsShowButtonRefund(true);
    }
  }, [
    transactionData?.canRefund,
    transactionData?.isRefunded,
    transactionData?.payfort_information?.fort_id,
    transactionData?.payfort_information?.status_payfort_response,
    transactionData?.status,
    transactionData?.type,
  ]);

  const handleRefundTransaction = async () => {
    if (isLoading) return;
    setIsRefundLoading(true);

    try {
      const { data: response } = await axios.post(
        `${config.API_URL.TRANSACTIONS.REFUND(rowData)}`,
        {},
        {
          headers: config.headers,
        }
      );

      if (response?.payfortStatus === 200 && response?.status_payfort_response) {
        handleToastMessage('success', 'Refund success');
        setIsShowButtonRefund(false);
      }
    } catch (error) {
      const message = error?.response?.data?.message;

      handleToastMessage('failed', `Refund failed: ${message}`);
    } finally {
      setIsRefundLoading(false);
    }
  };

  const getSettings = async () => {
    try {
      setIsLoading(true);

      const {
        data: { setting: response },
      } = await axios.get(config.API_URL.SETTING.GET, {
        params: {
          key: SETTING_KEY.FEE_TAX,
        },
        headers: config.headers,
      });

      setSettingValues(response);
    } catch (error) {
      console.log('Error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTransactionDetails = useCallback(async () => {
    try {
      setIsLoading(true);

      if (rowData) {
        const { data: response } = await axios.get(
          `${config.API_URL.TRANSACTIONS.GET_ALL_TRANSACTIONS}${rowData}`,
          {
            headers: config.headers,
          }
        );

        setTransactionData(response);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  }, [rowData]);

  const renderFields = (field) => {
    if (field) return field;

    return '-';
  };

  useEffect(() => {
    if (rowData) {
      showButtonRefund();
      getSettings();
      getTransactionDetails();
    }
  }, [getTransactionDetails, rowData, showButtonRefund]);

  const renderTransactionDetails = () => {
    if (isLoading) {
      return (
        <div className="inline-block w-full max-w-7xl h-[calc(100vh-200px)] overflow-x-hidden overflow-y-auto px-10 py-12 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="flex items-center justify-center h-full w-full gap-x-4">
            <IconSpin />
            <p>{t('loading')}</p>
          </div>
        </div>
      );
    }

    return (
      <div className="inline-block w-full max-w-7xl h-full overflow-x-hidden overflow-y-hidden px-10 py-12 my-8 text-left align-middle transition-all duration-300 transform bg-white shadow-xl rounded-2xl">
        <div className="fixed top-0 w-full pl-14 pt-4">
          <div className="flex items-center justify-between gap-x-4">
            <div className="flex items-center gap-x-4">
              <span className="text-3xl font-bold">Log ID</span>
              <span className="text-3xl leading-6">{rowData}</span>
            </div>

            <button type="button" onClick={closeModal}>
              <IconClose />
            </button>
          </div>
        </div>

        {isShowButtonRefund ? (
          <div className="mt-6">
            <LoadingButton
              label="Refund"
              loading={isRefundLoading}
              onClick={handleRefundTransaction}
            />
          </div>
        ) : (
          <div className="w-[110px] h-[35px] relative justify-center items-center flex focus:outline-none border px-12 py-2.5 mt-6 rounded-md text-black">
            Refunded
          </div>
        )}

        <div className="my-10 text-right flex flex-col gap-y-10  h-[calc(100vh-260px)] transition-all overflow-y-auto">
          {transactionData?.type === TRANSACTION_TYPES.HOLDING ||
            transactionData?.type === TRANSACTION_TYPES.CASH_BACK ||
            (transactionData?.type === TRANSACTION_TYPES.FEE && (
              <div className="grid grid-cols-3 gap-x-4 gap-y-3">
                <p className="col-span-1 font-bold text-2xl">{t('order_number')}</p>
                <p className="col-span-2">{renderFields(transactionData?.order_number)}</p>
              </div>
            ))}

          <div>
            <p className="col-span-1 font-bold text-2xl mb-2">{t('delegate')}</p>

            <div className="grid grid-cols-3 gap-x-4">
              <p className="font-bold col-span-1">{t('driver_id')}</p>
              <p className="col-span-2">{renderFields(transactionData?.driver?._id)}</p>
              <p className="font-bold col-span-1">{t('name')}</p>
              <p className="col-span-2">{renderFields(transactionData?.driver?.name)}</p>
              <p className="font-bold col-span-1">{t('phone')}</p>
              <p className="col-span-2">{renderFields(transactionData?.driver?.phone)}</p>
              <p className="font-bold col-span-1">{t('id_number')}</p>
              <p className="col-span-2">{renderFields(transactionData?.driver?.id_number)}</p>
              <p className="font-bold col-span-1">{t('region')}</p>
              <p className="col-span-2">{renderFields(transactionData?.driver?.region)}</p>
              <p className="font-bold col-span-1">{t('city')}</p>
              <p className="col-span-2">{renderFields(transactionData?.driver?.city)}</p>
              <p className="font-bold col-span-1">{t('car_number')}</p>
              <p className="col-span-2">{renderFields(transactionData?.driver?.car_number)}</p>
              <p className="font-bold col-span-1">{t('type_of_the_vehicle')}</p>
              <p className="col-span-2">{renderFields(transactionData?.driver?.car_type)}</p>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-x-4 gap-y-3">
            <p className="col-span-1 font-bold text-2xl">{t('transaction_type')}</p>
            <p className="col-span-2">{renderFields(transactionData?.type)}</p>
          </div>

          <div className="grid grid-cols-3 gap-x-4 gap-y-3">
            <p className="col-span-1 font-bold text-2xl">{t('amount')}</p>
            <p className="col-span-2 ltr">{renderFields(transactionData?.amount?.toFixed(2))}</p>
          </div>

          {(transactionData?.type === TRANSACTION_TYPES.HOLDING ||
            transactionData?.type === TRANSACTION_TYPES.CASH_BACK ||
            transactionData?.type === TRANSACTION_TYPES.FEE) && (
            <>
              <div className="grid grid-cols-3 gap-x-4 gap-y-3">
                <p className="col-span-1 font-bold text-2xl">{t('gift')}</p>
                <p className="col-span-2">{renderFields(transactionData?.gift?.toFixed(2))}</p>
              </div>

              <div className="grid grid-cols-3 gap-x-4 gap-y-3">
                <p className="col-span-1 font-bold text-2xl">{t('delivery_cost')}</p>
                <p className="col-span-2">
                  {renderFields(transactionData?.delivery_cost?.toFixed(2))}
                </p>
              </div>

              <div className="grid grid-cols-3 gap-x-4 gap-y-3">
                <p className="col-span-1 font-bold text-2xl">{t('fee')}</p>
                <p className="col-span-2">{renderFields(transactionData?.fee?.toFixed(2))}</p>
              </div>

              <div className="grid grid-cols-3 gap-x-4 gap-y-3">
                <p className="col-span-1 font-bold text-2xl">{t('tax')}</p>
                <p className="col-span-2">
                  {renderFields(
                    renderAddedValue(transactionData?.type, transactionData, settingValues.tax)
                  )}
                </p>
              </div>
            </>
          )}

          <div>
            <p className="col-span-1 font-bold text-2xl mb-2">{t('transaction.real_balance')}</p>
            <div className="grid grid-cols-3 gap-x-4">
              <p className="font-bold col-span-1">{t('transaction.balance_id')}</p>
              <p className="col-span-2">
                {renderFields(transactionData?.transactionBalanceMain?.balance_id)}
              </p>
              <p className="font-bold col-span-1">{t('transaction.previous_balance')}</p>
              <p className="col-span-2">
                {renderFields(transactionData?.transactionBalanceMain?.previous_balance.toFixed(2))}
              </p>
              <p className="font-bold col-span-1">{t('transaction.remaining_balance')}</p>
              <p className="col-span-2">
                {renderFields(
                  transactionData?.transactionBalanceMain?.remaining_balance.toFixed(2)
                )}
              </p>
            </div>
          </div>

          <div>
            <p className="col-span-1 font-bold text-2xl mb-2">{t('transaction.gift_balance')}</p>
            <div className="grid grid-cols-3 gap-x-4">
              <p className="font-bold col-span-1">{t('transaction.balance_id')}</p>
              <p className="col-span-2">
                {renderFields(transactionData?.transactionBalanceSub?.balance_id)}
              </p>
              <p className="font-bold col-span-1">{t('transaction.previous_balance')}</p>
              <p className="col-span-2">
                {renderFields(transactionData?.transactionBalanceSub?.previous_balance.toFixed(2))}
              </p>
              <p className="font-bold col-span-1">{t('transaction.remaining_balance')}</p>
              <p className="col-span-2">
                {renderFields(transactionData?.transactionBalanceSub?.remaining_balance.toFixed(2))}
              </p>
            </div>
          </div>

          <div>
            <p className="col-span-1 font-bold text-2xl mb-2">{t('created_by')}</p>
            <div className="grid grid-cols-3 gap-x-4">
              <p className="font-bold col-span-1">{t('account_type')}</p>
              <p className="col-span-2">{renderFields(transactionData?.created_by?.role)}</p>
              <p className="font-bold col-span-1">{t('account_id')}</p>
              <p className="col-span-2">{renderFields(transactionData?.created_by?.id)}</p>
            </div>
          </div>

          {transactionData?.type === TRANSACTION_TYPES.TOP_UP && (
            <div>
              <p className="col-span-1 font-bold text-2xl mb-2">{t('Hyperpay')}</p>
              <div className="grid grid-cols-3 gap-x-4 gap-y-3">
                <p className="col-span-1 font-bold">{t('transactionData.hyper_pay_log_id')}</p>
                <p className="col-span-2">{renderFields(transactionData?.hyper_pay_log_id)}</p>
              </div>
              <div className="grid grid-cols-3 gap-x-4 gap-y-3">
                <p className="col-span-1 font-bold">{t('transactionData.hyper_pay_checkout_id')}</p>
                <p className="col-span-2">{renderFields(transactionData?.hyper_pay_checkout_id)}</p>
              </div>
              <div className="grid grid-cols-3 gap-x-4 gap-y-3">
                <p className="col-span-1 font-bold">{t('plan_id')}</p>
                <p className="col-span-2">{renderFields(transactionData?.plan_id)}</p>
              </div>
            </div>
          )}
          <div className="grid grid-cols-3 gap-x-4 gap-y-3">
            <p className="col-span-1 font-bold text-2xl">{t('date')}</p>
            <p className="col-span-2">
              {parseDateWithGmt({
                date: transactionData?.created_at,
                timezone: currentTimezone?.timezone,
                format: FORMAT_DATE.ymdhms,
              })}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center rtl">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {renderTransactionDetails()}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
