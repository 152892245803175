/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import '../../../css/datatable.css';
import useSchema from './Schema.js';
import { config } from '../../../config/Config';
import { useLocation, useParams } from 'react-router-dom';
import { parseQueryString } from '../../Orders/Orders';
import FilterComponent from './Filters';
import { conditionalRowStyles, TRANSACTION_TYPES } from './constant';
import { buildQueryString } from '../../../helper/utils';
import TransactionDetailsModal from './DetailsModal/DetailsModal';

const DriverTransactions = () => {
  const tableHeight = window.innerHeight - 400;

  const schema = useSchema();
  const { t } = useTranslation();
  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const { id: driverId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [driverTransactions, setDriverTransactions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(queryString.page || 1);
  const [filters, setFilters] = useState({});
  const [limit, setLimit] = useState(queryString.limit || 100);
  const [dateRange, setDateRange] = useState([null, null]);
  const [parsingQuery, setParsingQuery] = useState(true);
  const [driverInfo, setDriverInfo] = useState({
    driverId: '',
    driverName: '',
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModal = (transactionId) => {
    setIsModalVisible(true);
    setSelectedId(transactionId);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedId(null);
  };

  const handleDateFilter = (value) => {
    if (value) {
      setFilters({
        ...filters,
        date: [new Date(value[0]), new Date(value[1])],
      });
      setDateRange([new Date(value[0]), new Date(value[1])]);
    } else {
      setFilters({
        ...filters,
        date: null,
      });
      setDateRange([null, null]);
    }
  };

  async function getTransactions(filterFields = {}) {
    try {
      setIsLoading(true);
      const initialFilters = {
        filters: {
          ...filters,
          date:
            filters?.date?.[0] && filters?.date?.[1]
              ? [
                  new Date(filters?.date?.[0]).toISOString(),
                  new Date(filters?.date?.[1]).toISOString(),
                ]
              : [null, null],
          created_by_role: filters?.created_by_role?.value,
          type: filters?.type?.value,
          status: filters?.status?.value,
        },
      };

      const finalizeFilters = isEmpty(filterFields) ? initialFilters : filterFields;
      const newQueryUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?${buildQueryString({ ...finalizeFilters })}`;

      window.history.pushState({ path: newQueryUrl }, '', newQueryUrl);

      const {
        data: { data: response, driver_name, id_number },
      } = await axios.get(config.API_URL.TRANSACTIONS.GET_DRIVER_TRANSACTIONS(driverId), {
        params: {
          ...finalizeFilters,
          sort: -1,
          limit: limit,
          page: currentPage,
        },
        headers: config.headers,
      });

      setDriverInfo({ driverId: id_number, driverName: driver_name });
      setDriverTransactions(response.results ?? []);
      setTotal(response.total ?? null);
    } catch (error) {
      console.log('Axios error: ', error);
    } finally {
      setIsLoading(false);
    }
  }

  const handlerFilters = (value, key) => {
    setCurrentPage(1);
    setFilters((prevState) => {
      if (key !== TRANSACTION_TYPES.TOP_UP && prevState?.status) {
        delete prevState.status;
      }
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const setFiltersValueByQueryString = () => {
    if (queryString.page) {
      setCurrentPage(+queryString.page);
    }

    if (queryString.filters) {
      const queryFilters = { ...queryString.filters };

      if (queryFilters?.date?.[0] && queryFilters?.date?.[1]) {
        setDateRange([new Date(queryFilters?.date?.[0]), new Date(queryFilters?.date?.[1])]);
      } else {
        setDateRange([null, null]);
      }

      queryFilters.status = queryFilters.status
        ? {
            label: queryFilters.status,
            value: queryFilters.status,
          }
        : null;

      queryFilters.created_by_role = queryFilters.created_by_role
        ? {
            label: queryFilters.created_by_role,
            value: queryFilters.created_by_role,
          }
        : null;

      queryFilters.type = queryFilters.type
        ? {
            label: queryFilters.type,
            value: queryFilters.type,
          }
        : null;

      setFilters(queryFilters);
    }

    if (queryString.limit > 0) {
      setLimit(+queryString.limit);
    }

    setParsingQuery(false);
  };

  useEffect(() => {
    if (!parsingQuery) getTransactions();
  }, [limit, parsingQuery]);

  useEffect(() => {
    setFiltersValueByQueryString();
  }, []);

  return (
    <div>
      <p className="rtl font-medium text-3xl mr-8 mt-8 mb-4">
        {driverInfo.driverName ? driverInfo.driverName : null}
        <span className="font-medium text-3xl mx-2">
          {driverInfo.driverName && driverInfo.driverId && '-'}
        </span>
        {driverInfo.driverId ? driverInfo.driverId : null}
      </p>

      <FilterComponent
        dateRange={dateRange}
        handleDateFilter={handleDateFilter}
        handlerFilters={handlerFilters}
        filters={filters}
        isLoading={isLoading}
        getTransactions={getTransactions}
        selected={selected}
      />

      {isModalVisible && (
        <TransactionDetailsModal
          rowData={selectedId}
          visible={isModalVisible}
          closeModal={handleCloseModal}
        />
      )}

      <div className="rtl px-8 italic text-base">
        red text is main balance value, green text is gift balance value *
      </div>

      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        columns={schema}
        data={driverTransactions}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        onSelectedRowsChange={(row) => setSelected(row.selectedRows)}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(currentRowsPerPage);
          setCurrentPage(1);
        }}
        progressPending={isLoading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationTotalRows={total}
        paginationServer
        onChangePage={handlePageChange}
        onRowClicked={(row) => handleOpenModal(row._id)}
        conditionalRowStyles={conditionalRowStyles}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        selectableRows
        selectableRowSelected={(row) => row.isSelected}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        highlightOnHover
        direction={'rtl'}
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              background: '#F9F9F9',
              justifyContent: 'flex-start',

              padding: 0,
            },
          },
          rows: {
            style: {
              cursor: 'pointer',
              fontSize: 12,
              background: '#FFF',
            },
          },
        }}
      />
    </div>
  );
};

export default DriverTransactions;
