import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { config } from '../../../../config/Config';
import Card from './Card';

export default function ListCard() {
  const [data, setData] = useState({});
  const getData = async () => {
    const response = await axios.get(config.DASHBOARD.ORDER_ANALYSIS_EARN, {
      headers: config.headers,
    });

    setData(response.data);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="flex flex-wrap -mx-3">
      <Card label={`Total Money in year`} value={data?.totalEarnAll} />
      <Card label={`Total Money in month`} value={data?.totalEarnMonth} />
      <Card label={`Total Money in day`} value={data?.totalEarnDay} />
    </div>
  );
}
