import { Dialog, Transition } from '@headlessui/react';

export default function PhotoTransaction({ isOpen, setIsOpen, photo }) {
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Transition appear show={isOpen}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center rtl">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
          </Transition.Child>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-7xl space-y-5 h-full overflow-x-hidden overflow-y-hidden px-10 py-12 my-8 text-left align-middle transition-all duration-300 transform bg-white shadow-xl rounded-2xl">
              <img className="rounded-md" src={photo} alt="photo" />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
