import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import axios from 'axios';
import { uniqueId, isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import LoadingButton from '../../commonComponents/LoadingButton';
import { config } from '../../config/Config';
import { DataContext } from '../../context/DataContext';
import { createDate } from '../../helper/date/createDateWithTimezone';
import { CompareGmtAndCalcTimestamp, GetTimezone } from '../../helper/date/getLocalGmtWithTimezone';
import { buildQueryString, parseQueryString } from '../../helper/utils';
import useEventEnter from '../../hooks/useEventEnter';
import CustomSelectReact from '../CustomSelectReact';

import SearchInput from '../SearchInput';
import { conditionalRowStyles, DigitalWallet, FilterStatuses, FromApplication } from './constants';
import TransactionLogDetailsModal from './LogDetails';
import useColumn from './useColumns';

const TransactionLogs = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { columns } = useColumn();
  const { currentTimezone } = useContext(DataContext);
  const queryString = parseQueryString(search);
  const filterStatuses = FilterStatuses();
  const fromApplication = FromApplication();
  const digitalWallet = DigitalWallet();
  const tableHeight = window.innerHeight - 330;

  const [transactionLogs, setTransactionLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedId, setSelectedId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [parsingQuery, setParsingQuery] = useState(true);
  const [newRender, setNewRender] = useState(uniqueId());

  const handleChangePage = (nextPage) => {
    if (Number(nextPage) === Number(currentPage) || isLoading) return false;

    setCurrentPage(nextPage);
    setNewRender(uniqueId());
  };

  const handleSetFilters = (newFilter) => {
    setCurrentPage(1);
    setFilters(newFilter);
  };

  const handleChangeDateOnFilter = (value) => {
    const newFilter = {
      ...filters,
      from_date: value ? new Date(value[0]).getTime() : null,
      to_date: value ? new Date(value[1]).getTime() : null,
    };

    if (!value) {
      setDateRange([null, null]);
    }

    handleSetFilters(newFilter);
  };

  const handleChangeFilter = (optionValues, type) => {
    const newFilters = {
      ...filters,
      [type]: optionValues,
    };

    handleSetFilters(newFilters);
  };

  const handleOpenModal = (recordId) => {
    setIsModalVisible(true);
    setSelectedLog(recordId);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedLog(null);
  };

  const getTransactionLogsByPayfort = async (_filter = {}) => {
    const { status, from_application, from_date, to_date, transaction_id, digital_wallet, phone } =
      filters;

    const newFilters = {
      'filters[transaction_id]': transaction_id ?? '',
      'filters[phone]': phone ?? '',
      'filters[status]': status?.value ?? '',
      'filters[from_application]': from_application?.value ?? '',
      'filters[digital_wallet]': digital_wallet?.value ?? '',
      'filters[from_date]': from_date ? new Date(from_date).toISOString() : '',
      'filters[to_date]': to_date ? new Date(to_date).toISOString() : '',
      limit: limit,
      page: currentPage,
    };

    const finalizeFilter = isEmpty(_filter) ? newFilters : _filter;

    if (isLoading) return;

    setIsLoading(true);

    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${buildQueryString({
        ...finalizeFilter,
      })}`;

    window.history.pushState({ path: newurl }, '', newurl);

    try {
      const { data } = await axios.get(`${config.API_URL.TRANSACTIONS_BY_PAYFORT.GET_LIST}`, {
        params: {
          ...finalizeFilter,
        },
        headers: config.headers,
      });

      const { data: response } = data;
      const {
        meta: { totalItems },
      } = data;

      setTransactionLogs(response);
      setTotalRecords(totalItems);
    } catch (error) {
      console.log('Error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetTransactionLogsByFilter = async () => {
    setCurrentPage(1);
    const { status, from_application, from_date, to_date, transaction_id, digital_wallet, phone } =
      filters;

    const { settingGmt, localGmt } = await GetTimezone();

    const newFilters = {
      'filters[transaction_id]': transaction_id ?? '',
      'filters[phone]': phone ?? '',
      'filters[status]': status?.value ?? '',
      'filters[from_application]': from_application?.value ?? '',
      'filters[digital_wallet]': digital_wallet?.value ?? '',
      'filters[from_date]': from_date
        ? new Date(
            CompareGmtAndCalcTimestamp(
              settingGmt,
              localGmt,
              new Date(createDate(+from_date, currentTimezone)).getTime()
            )
          ).toISOString()
        : null,
      'filters[to_date]': to_date
        ? new Date(
            CompareGmtAndCalcTimestamp(
              settingGmt,
              localGmt,
              new Date(createDate(+to_date, currentTimezone)).getTime()
            )
          ).toISOString()
        : null,
      limit: limit,
      page: currentPage,
    };

    getTransactionLogsByPayfort(newFilters);
  };

  useEventEnter(handleGetTransactionLogsByFilter);

  useEffect(() => {
    if (!parsingQuery) {
      getTransactionLogsByPayfort();
    }
  }, [limit, newRender, parsingQuery]);

  useEffect(() => {
    const { from_date, to_date } = filters;

    if (from_date && to_date) {
      const dateValue = [new Date(+from_date), new Date(+to_date)];

      setDateRange(dateValue);
    } else {
      setDateRange([null, null]);
    }
  }, [filters]);

  useEffect(() => {
    if (queryString.page) {
      setCurrentPage(+queryString.page);
    }

    if (queryString.filters) {
      let filter = {
        ...queryString.filters,
        // status: { label: queryString.filters.status, value: queryString.filters.status },
        // from_application: {
        //   label: queryString.filters.from_application,
        //   value: queryString.filters.from_application,
        // },
        // digital_wallet: {
        //   label: queryString.filters.digital_wallet,
        //   value: queryString.filters.digital_wallet,
        // },
      };

      if (filter.from_date) {
        filter.from_date = new Date(filter.from_date).getTime();
      }

      if (filter.to_date) {
        filter.to_date = new Date(filter.to_date).getTime();
      }

      setFilters(filter);
    }

    if (queryString.limit > 0) {
      setLimit(+queryString.limit);
    }

    setParsingQuery(false);
  }, []);

  return (
    <div className="rtl">
      <div className="px-8 py-5 flex items-center gap-4 mb-[35px] flex-wrap">
        <div>
          <SearchInput
            disabled={isLoading}
            placeholder={t('transaction_id')}
            handleSearch={(value) => handleChangeFilter(value, 'transaction_id')}
            makeRerender={filters}
            value={filters?.transaction_id}
          />
        </div>

        <div>
          <SearchInput
            disabled={isLoading}
            placeholder={t('phone')}
            handleSearch={(value) => handleChangeFilter(value, 'phone')}
            makeRerender={filters}
            value={filters?.phone}
          />
        </div>

        <div>
          <CustomSelectReact
            isClearable
            isDisabled={isLoading}
            placeholder={t('status')}
            className="checkbox-order w-[200px] filter-select"
            options={filterStatuses}
            value={filters?.status || []}
            onChange={(value) => handleChangeFilter(value, 'status')}
          />
        </div>

        <div>
          <CustomSelectReact
            isClearable
            isDisabled={isLoading}
            placeholder={t('from_application')}
            className="checkbox-order w-[200px] filter-select"
            options={fromApplication}
            value={filters?.from_application || []}
            onChange={(value) => handleChangeFilter(value, 'from_application')}
          />
        </div>

        <div>
          <CustomSelectReact
            isClearable
            isDisabled={isLoading}
            placeholder={t('digital_wallet')}
            className="checkbox-order w-[200px] filter-select"
            options={digitalWallet}
            value={filters?.digital_wallet || []}
            onChange={(value) => handleChangeFilter(value, 'digital_wallet')}
          />
        </div>

        <div className="input-select-wrapper" style={{ direction: 'ltr' }}>
          <DateTimeRangePicker
            disabled={isLoading}
            className="input-select-input-wrapper !border-0 w-[200px]"
            value={dateRange}
            onChange={handleChangeDateOnFilter}
            disableClock
            format={'y-MM-dd'}
          />
        </div>

        <div className="relative w-full mb-4">
          <div className="absolute right-0 ">
            <LoadingButton
              onClick={handleGetTransactionLogsByFilter}
              label={t('Apply Filter')}
              loading={isLoading}
            />
          </div>
        </div>
      </div>

      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        highlightOnHover
        columns={columns}
        data={transactionLogs}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationPerPage={+limit}
        paginationRowsPerPageOptions={[10, 20, 40, 60]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(+currentRowsPerPage);
          setCurrentPage(1);
        }}
        progressPending={isLoading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationTotalRows={totalRecords}
        paginationServer
        onChangePage={handleChangePage}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        selectableRows
        onRowExpandToggled={(toggleState, row) => [
          toggleState
            ? setSelectedId((p) => [...p, row.id])
            : setSelectedId(selectedId.filter((i) => i !== row.id)),
        ]}
        conditionalRowStyles={conditionalRowStyles}
        onRowClicked={(row) => handleOpenModal(row?.id)}
        paginationDefaultPage={currentPage}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              background: '#F9F9F9',
            },
          },
          rows: {
            style: {
              cursor: 'pointer',
              fontSize: 12,
            },
          },
          header: {
            style: {
              minHeight: 0,
            },
          },
        }}
      />

      <TransactionLogDetailsModal
        logId={selectedLog}
        isVisible={isModalVisible}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default TransactionLogs;
