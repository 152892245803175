/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config/Config';

const DetailLog = ({ ...props }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [log, setLog] = useState({});
  const getDetailLog = async () => {
    setIsLoading(true);
    try {
      const resp = await axios.get(`${config.API_URL.HTTP_LOGS.DETAIL}${id}`, {
        headers: config.headers,
      });
      setLog(resp.data.data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDetailLog();
  }, []);

  if (isLoading) return <div>{t('loading')}...</div>;

  return (
    <div>
      <div className="rtl">
        <table id="table">
          <tbody>
            <tr>
              <td>{t('route')}</td>
              <td>{log.url}</td>
            </tr>
            <tr>
              <td>{t('method')}</td>
              <td>{log.method}</td>
            </tr>
            <tr>
              <td>{t('type')} </td>
              <td>{log.type}</td>
            </tr>
            <tr>
              <td>{t('status')} </td>
              <td>{log.status}</td>
            </tr>
            <tr>
              <td>{t('headers')} </td>
              <td>
                <pre className="prettyprint">{JSON.stringify(log.headers, undefined, 2)}</pre>
              </td>
            </tr>
            <tr>
              <td>{t('request')}</td>
              <td>
                <pre className="prettyprint">{JSON.stringify(log.request, undefined, 2)}</pre>
              </td>
            </tr>
            <tr>
              <td>{t('query')}</td>
              <td>
                <pre className="prettyprint">{JSON.stringify(log.query, undefined, 2)} </pre>
              </td>
            </tr>
            <tr>
              <td>{t('message')}</td>
              <td>
                <td>{log.message}</td>
              </td>
            </tr>
            <tr>
              <td>{t('params')}</td>
              <td>
                <pre className="prettyprint">{JSON.stringify(log.params, undefined, 2)} </pre>
              </td>
            </tr>
            <tr>
              <td>{t('number_of_runs')}</td>
              <td>{log.count || 1}</td>
            </tr>
            <tr>
              <td>{t('response')}</td>
              <td>
                <pre className="prettyprint">{JSON.stringify(log.data, undefined, 2)} </pre>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetailLog;
