import { Chart, registerables } from 'chart.js';
import ListCard from './componnets/card/ListCard';
import DriverAnalysis from './componnets/driverAnalysis';
import OrderAnalysis from './componnets/orderAnalysis';
Chart.register(...registerables);
export default function Dashboard() {
  return (
    <div className="w-full m-0 font-sans antialiased font-normal text-base leading-default bg-gray-50 text-slate-500 h-[calc(100vh-80px)]">
      <div className="w-full px-6 py-6 mx-auto">
        <ListCard />
      </div>

      <div className="flex flex-wrap mt-6 px-3">
        <DriverAnalysis />
        <OrderAnalysis />
      </div>
    </div>
  );
}
