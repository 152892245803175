import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSchema from './Schema';
import axios from 'axios';
import { config } from '../../config/Config';
import DataTable from 'react-data-table-component';
import ActionButtons from './ActionButtons';
import { CLOSE, OPEN } from '../../constants/constants';
import { handleMessage } from '../../helper/utils';
import SearchInput from '../SearchInput';
import CustomSelectReact from '../CustomSelectReact';
import LoadingButton from '../../commonComponents/LoadingButton/LoadingButton';
import useEventEnter from '../../hooks/useEventEnter';
import useFilterOptionStores from './FilterOptions';

export default function StoresRegistration() {
  const { t } = useTranslation();
  const schemaStoresRegistration = useSchema();
  const tableHeight = window.innerHeight - 330;
  const { filterStatus } = useFilterOptionStores();

  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dataStoresRegistration, setDataStoresRegistration] = useState([]);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState({});

  const handlePageChange = (nextPage) => {
    setCurrentPage(nextPage);
  };

  const handleSetFilters = (newFilter) => {
    setCurrentPage(1);
    setFilters(newFilter);
  };

  const handleChangeFilter = (optionValues, type) => {
    const newFilters = {
      ...filters,
      [type]: optionValues,
    };

    handleSetFilters(newFilters);
  };

  const getDataListStoresRegistration = async () => {
    const { store_name, registration_status, contact_number, city, number_of_branches } = filters;

    const newFilterOptions = {
      filters: {
        store_name: store_name ?? '',
        contact_number: contact_number ?? '',
        number_of_branches: number_of_branches ?? '',
        city: city ?? '',
        registration_status: registration_status?.value ?? '',
      },
      limit: limit,
      page: currentPage,
    };

    if (isLoading === true) return;
    setIsLoading(true);

    try {
      const { data } = await axios.get(config.API_URL.STORES_REGISTRATION.GET_LIST, {
        params: {
          ...newFilterOptions,
          limit: limit,
          page: currentPage,
        },
        headers: config.headers,
      });
      setDataStoresRegistration(data?.result);
      setTotal(data?.total);
    } catch (e) {
      console.log('Axios error: ', e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataListStoresRegistration();
  }, [currentPage, limit]);

  const handleGetDataByFilter = () => {
    setCurrentPage(1);
    getDataListStoresRegistration();
  };
  useEventEnter(handleGetDataByFilter);

  const handleStatusRegistration = async (dataStores) => {
    const idStores = dataStores?._id;
    const registration_status = dataStores?.registration_status === OPEN ? CLOSE : OPEN;

    try {
      await axios.patch(
        config.API_URL.STORES_REGISTRATION.PATCH_CHANGE_STATUS_REGISTRATION(idStores),
        { registration_status: registration_status },
        { headers: config.headers }
      );
      handleMessage('success', t('message_status_registration'));
      getDataListStoresRegistration();
    } catch (e) {
      console.log('Axios error: ', e);
      handleMessage('error', t('message_status_registration_fail'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="rtl">
      <div className="px-8 py-5 flex items-center gap-4 mb-[35px] flex-wrap">
        <div>
          <SearchInput
            disabled={isLoading}
            placeholder={t('store_name')}
            handleSearch={(value) => handleChangeFilter(value, 'store_name')}
            makeRerender={filters}
            value={filters?.store_name}
          />
        </div>

        <div>
          <SearchInput
            disabled={isLoading}
            placeholder={t('contact_number')}
            handleSearch={(value) => handleChangeFilter(value, 'contact_number')}
            makeRerender={filters}
            value={filters?.contact_number}
          />
        </div>

        <div>
          <SearchInput
            disabled={isLoading}
            placeholder={t('number_of_branches')}
            handleSearch={(value) => handleChangeFilter(value, 'number_of_branches')}
            makeRerender={filters}
            value={filters?.number_of_branches}
          />
        </div>

        <div>
          <SearchInput
            disabled={isLoading}
            placeholder={t('city')}
            handleSearch={(value) => handleChangeFilter(value, 'city')}
            makeRerender={filters}
            value={filters?.city}
          />
        </div>

        <div className="flex">
          <CustomSelectReact
            isClearable
            isDisabled={isLoading}
            placeholder={t('registration_status')}
            onChange={(value) => handleChangeFilter(value, 'registration_status')}
            className="filter-select min-w-[200px]"
            options={filterStatus}
            value={filters.registration_status}
          />
        </div>

        <div className="relative w-full mb-4">
          <div className="absolute right-0">
            <LoadingButton
              onClick={handleGetDataByFilter}
              label={t('Apply Filter')}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        highlightOnHover
        columns={schemaStoresRegistration}
        data={dataStoresRegistration}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        paginationDefaultPage={currentPage}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationPerPage={+limit}
        paginationRowsPerPageOptions={[10, 20, 40, 60]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(+currentRowsPerPage);
          setCurrentPage(1);
        }}
        progressPending={isLoading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationTotalRows={total}
        paginationServer
        subHeader
        onChangePage={handlePageChange}
        conditionalRowStyles={conditionalRowStyles}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        customStyles={customStyles}
        expandableRows
        expandableRowsComponent={
          <ActionButtons handleStatusRegistration={handleStatusRegistration} />
        }
        expandableRowsHideExpander
        expandOnRowClicked
      />
    </div>
  );
}

const conditionalRowStyles = [
  {
    when: (row) => row.registration_status === CLOSE,
    style: {
      backgroundColor: '#F7DDDE',
    },
  },
  {
    when: (row) => row.registration_status === OPEN,
    style: {
      backgroundColor: '#F8EECC',
    },
  },
];

const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: 12,
      background: '#F9F9F9',
    },
  },
  rows: {
    style: {
      cursor: 'pointer',
      fontSize: 12,
    },
  },
  header: {
    style: {
      minHeight: 0,
    },
  },
};
